import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import ParentContainer from "../../components/ParentContainer";
import { Waypoint } from "react-waypoint";
import "animate.css";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const Advantage = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleEnter = () => {
    setIsVisible(true);
  };

  const main = useRef();

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const boxes = gsap.utils.toArray(".box");
      boxes.forEach((box) => {
        gsap.fromTo(
          box,
          { x: 200, opacity: 0 },
          {
            opacity: 1,
            x: 0,
            scrollTrigger: {
              trigger: box,
              start: "bottom 60%",
              end: "top 60%",
              scrub: 1,
              // markers: true,
            },
          }
        );
      });

      const circles = gsap.utils.toArray(".circle2");
      circles.forEach((circle) => {
        gsap.fromTo(
          circle,
          { x: 150, y: 200, opacity: 0 },
          {
            x: 0,
            y: 0,
            opacity: 1,
            scrollTrigger: {
              trigger: circle,
              start: "bottom 100%",
              end: "top 70%",
              scrub: 4,
              //  markers: true,
            },
          }
        );
      });

      const box2s = gsap.utils.toArray(".box2");
      box2s.forEach((boxs2) => {
        gsap.fromTo(
          boxs2,
          { x: 200, opacity: 0 },
          {
            x: 0,
            opacity: 1,
            scrollTrigger: {
              trigger: boxs2,
              start: "bottom 100%",
              end: "top 70%",
              scrub: 4,
              //  markers: true,
            },
          }
        );
      });
    }, main);

    return () => ctx.revert();
  }, []);

  return (
    <ParentContainer
      containerStyle={"flex items-start max-md:flex-wrap max-md:gap-5"}
      containerHeight={"py-60"}
    >
      <div
        id="sticky-heading"
        className="sticky top-2/4 max-md:w-full max-md:relative"
      >
        <h2 className="text-5xl font-bold text-left pb-2">
          CellSave{" "}
          <span class="bg-gradient-to-r from-custom-primary to-custom-primary text-transparent bg-clip-text">
            Advantage
          </span>
        </h2>
        <p className="text-left text-xl opacity-80 w-4/5">
          Lorem ipsum dolor sit amet consectetur. Dui ridiculus tristique
          blandit venenatis. Sit sed volutpat.
        </p>
      </div>

      <div
        ref={main}
        className={`  !last:mb-0 max-md:w-full ${
          isVisible ? "animate__animated animate__fadeInRight" : ""
        }`}
      >
        <div className="flex items-center gap-2 border-[#EAECF0] border-b pb-5 mb-12 box">
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="40"
              height="40"
              rx="20"
              fill="url(#paint0_linear_590_4080)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_590_4080"
                x1="-3.7931"
                y1="4.44444"
                x2="46.9698"
                y2="8.0283"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#8884D5" />
                <stop offset="1" stop-color="#CF0072" />
              </linearGradient>
            </defs>
          </svg>
          <p className="text-xl font-semibold">
            State-of-the-art laboratories offering the highest quality care and
            protection.
          </p>
        </div>
        <div className="flex items-center gap-2 border-[#EAECF0] border-b pb-5 mb-12 box">
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="40"
              height="40"
              rx="20"
              fill="url(#paint0_linear_590_4080)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_590_4080"
                x1="-3.7931"
                y1="4.44444"
                x2="46.9698"
                y2="8.0283"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#8884D5" />
                <stop offset="1" stop-color="#CF0072" />
              </linearGradient>
            </defs>
          </svg>
          <p className="text-xl font-semibold">
            State-of-the-art laboratories offering the highest quality care and
            protection.
          </p>
        </div>
        <div className="flex items-center gap-2 border-[#EAECF0] border-b pb-5 box">
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="40"
              height="40"
              rx="20"
              fill="url(#paint0_linear_590_4080)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_590_4080"
                x1="-3.7931"
                y1="4.44444"
                x2="46.9698"
                y2="8.0283"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#8884D5" />
                <stop offset="1" stop-color="#CF0072" />
              </linearGradient>
            </defs>
          </svg>
          <p className="text-xl font-semibold">
            State-of-the-art laboratories offering the highest quality care and
            protection.
          </p>
        </div>
      </div>
    </ParentContainer>
  );
};

export default Advantage;
