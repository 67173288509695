import React from "react";

const Order = () => {
  return (
    <div className="flex flex-wrap p-8 bg-white">
      <div className=" border rounded-lg  p-8 w-full">
        <div className="header-order flex font-semibold p-4">
          <div className="w-1/6">
            <h3>No</h3>
          </div>
          <div className="w-2/6">
            <h3>Product Name</h3>
          </div>
          <div className="w-1/6">
            <h3>Price</h3>
          </div>
          <div className="w-1/6">
            <h3>Total Price</h3>
          </div>
          <div className="w-1/6">
            <h3>Status</h3>
          </div>
        </div>
        <div className="order flex p-4 py-8 rounded-lg my-4 bg-[#F7F6FF]">
          <div className="w-1/6">
            <h3>#15245663</h3>
          </div>
          <div className="w-2/6">
            <h3>CryoInfinite | 1 Baby Full Payment</h3>
          </div>
          <div className="w-1/6">
            <h3> AED37,500.00</h3>
          </div>
          <div className="w-1/6">
            <h3> AED37,500.00</h3>
          </div>
          <div className="w-1/6 text-green-600">
            <h3>Paid</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Order;
