import React from "react";
import HeroSection from "./AboutPage/HeroSection";
import VideoSection from "./AboutPage/VideoSection";
import Timeline from "./AboutPage/Timeline";
import CellSaveNumbers from "./AboutPage/CellSaveNumbers";
import WhySelect from "./AboutPage/WhySelect";
import Acceridation from "./AboutPage/Acceridation";
import TranslplantNumbers from "../components/TranslplantNumbers";
import assets from "../assets/assets";

const AboutPage = () => {
  return (
    <div className="bg-[#FBF7F5]">
      <HeroSection />
      <Timeline />
      <CellSaveNumbers />
      <WhySelect />
      <TranslplantNumbers backgroundImage={assets.TransplantNumbers} />
      <Acceridation />
    </div>
  );
};

export default AboutPage;
