import React, { useState } from "react";
import ParentContainer from "../../components/ParentContainer";
import { MapContainer, Marker, TileLayer, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

// Fix for the default icon not displaying correctly
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png",
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
});

const ChangeView = ({ center }) => {
  const map = useMap();
  map.setView(center);
  return null;
};

const HeroSection = () => {
  const [markerPosition, setMarkerPosition] = useState([37.7749, -144.4194]);

  const DubaiLocation = () => {
    setMarkerPosition([37.7859, -122.4364]); // new marker position
  };

  const abuDhabiLocation = () => {
    setMarkerPosition([37.7859, -100.4364]); // new marker position
  };

  const locationIcon = (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 14.625C12.1066 14.625 14.625 12.1066 14.625 9C14.625 5.8934 12.1066 3.375 9 3.375C5.8934 3.375 3.375 5.8934 3.375 9C3.375 12.1066 5.8934 14.625 9 14.625Z"
        stroke="url(#paint0_linear_590_10514)"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9 11.25C10.2426 11.25 11.25 10.2426 11.25 9C11.25 7.75736 10.2426 6.75 9 6.75C7.75736 6.75 6.75 7.75736 6.75 9C6.75 10.2426 7.75736 11.25 9 11.25Z"
        stroke="url(#paint1_linear_590_10514)"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9 3V1.5"
        stroke="url(#paint2_linear_590_10514)"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3 9H1.5"
        stroke="url(#paint3_linear_590_10514)"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9 15V16.5"
        stroke="url(#paint4_linear_590_10514)"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15 9H16.5"
        stroke="url(#paint5_linear_590_10514)"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_590_10514"
          x1="9"
          y1="3.375"
          x2="9"
          y2="14.625"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8884D5" />
          <stop offset="1" stop-color="#8884D5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_590_10514"
          x1="9"
          y1="6.75"
          x2="9"
          y2="11.25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8884D5" />
          <stop offset="1" stop-color="#8884D5" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_590_10514"
          x1="9.5"
          y1="1.5"
          x2="9.5"
          y2="3"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8884D5" />
          <stop offset="1" stop-color="#8884D5" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_590_10514"
          x1="2.25"
          y1="9"
          x2="2.25"
          y2="10"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8884D5" />
          <stop offset="1" stop-color="#8884D5" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_590_10514"
          x1="9.5"
          y1="15"
          x2="9.5"
          y2="16.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8884D5" />
          <stop offset="1" stop-color="#8884D5" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_590_10514"
          x1="15.75"
          y1="9"
          x2="15.75"
          y2="10"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8884D5" />
          <stop offset="1" stop-color="#8884D5" />
        </linearGradient>
      </defs>
    </svg>
  );

  const PhoneIcon = (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.4775 13.7475C16.4775 14.0175 16.4175 14.295 16.29 14.565C16.1625 14.835 15.9975 15.09 15.78 15.33C15.4125 15.735 15.0075 16.0275 14.55 16.215C14.1 16.4025 13.6125 16.5 13.0875 16.5C12.3225 16.5 11.505 16.32 10.6425 15.9525C9.78 15.585 8.9175 15.09 8.0625 14.4675C7.2 13.8375 6.3825 13.14 5.6025 12.3675C4.83 11.5875 4.1325 10.77 3.51 9.915C2.895 9.06 2.4 8.205 2.04 7.3575C1.68 6.5025 1.5 5.685 1.5 4.905C1.5 4.395 1.59 3.9075 1.77 3.4575C1.95 3 2.235 2.58 2.6325 2.205C3.1125 1.7325 3.6375 1.5 4.1925 1.5C4.4025 1.5 4.6125 1.545 4.8 1.635C4.995 1.725 5.1675 1.86 5.3025 2.055L7.0425 4.5075C7.1775 4.695 7.275 4.8675 7.3425 5.0325C7.41 5.19 7.4475 5.3475 7.4475 5.49C7.4475 5.67 7.395 5.85 7.29 6.0225C7.1925 6.195 7.05 6.375 6.87 6.555L6.3 7.1475C6.2175 7.23 6.18 7.3275 6.18 7.4475C6.18 7.5075 6.1875 7.56 6.2025 7.62C6.225 7.68 6.2475 7.725 6.2625 7.77C6.3975 8.0175 6.63 8.34 6.96 8.73C7.2975 9.12 7.6575 9.5175 8.0475 9.915C8.4525 10.3125 8.8425 10.68 9.24 11.0175C9.63 11.3475 9.9525 11.5725 10.2075 11.7075C10.245 11.7225 10.29 11.745 10.3425 11.7675C10.4025 11.79 10.4625 11.7975 10.53 11.7975C10.6575 11.7975 10.755 11.7525 10.8375 11.67L11.4075 11.1075C11.595 10.92 11.775 10.7775 11.9475 10.6875C12.12 10.5825 12.2925 10.53 12.48 10.53C12.6225 10.53 12.7725 10.56 12.9375 10.6275C13.1025 10.695 13.275 10.7925 13.4625 10.92L15.945 12.6825C16.14 12.8175 16.275 12.975 16.3575 13.1625C16.4325 13.35 16.4775 13.5375 16.4775 13.7475Z"
        stroke="#8884D5"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
    </svg>
  );
  return (
    <ParentContainer containerStyle={"flex z-[1] relative"} className="">
      <div className="w-2/5 pl-4 bg-[#37424a12] flex justify-between rounded-lg ">
        <div className=" p-4 rounded-lg flex flex-col justify-between ">
          <h2 className="text-2xl font-bold border-b border-[#00000036] pb-5">
            All locations
          </h2>
          <div
            onClick={DubaiLocation}
            className="pt-3 pb-5 border-b border-[#00000036] flex flex-col gap-2"
          >
            <h4 className="font-semibold">Dubai Laboratory</h4>
            <div className="flex items-center gap-2">
              <div>{locationIcon}</div>
              <p> Lorem ipsum dolor sit amet consectetur</p>
            </div>
            <div className="flex items-center gap-2">
              <div>{PhoneIcon}</div>
              <p> Lorem ipsum dolor sit amet consectetur</p>
            </div>
          </div>
          <div
            onClick={abuDhabiLocation}
            className="pt-3 pb-5 border-b border-[#00000036] flex flex-col gap-2"
          >
            <h4 className="font-semibold">Dubai Laboratory</h4>
            <div className="flex items-center gap-2">
              <div>{locationIcon}</div>
              <p> Lorem ipsum dolor sit amet consectetur</p>
            </div>
            <div className="flex items-center gap-2">
              <div>{PhoneIcon}</div>
              <p> Lorem ipsum dolor sit amet consectetur</p>
            </div>
          </div>
          <div className="pt-3 pb-5  flex flex-col gap-2">
            <h4 className="font-semibold">Dubai Laboratory</h4>
            <div className="flex items-center gap-2">
              <div>{locationIcon}</div>
              <p> Lorem ipsum dolor sit amet consectetur</p>
            </div>
            <div className="flex items-center gap-2">
              <div>{PhoneIcon}</div>
              <p> Lorem ipsum dolor sit amet consectetur</p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-3/5 ml-5">
        <div>
          <MapContainer
            center={markerPosition}
            zoom={12}
            style={{ height: "500px", width: "100%" }}
          >
            <ChangeView center={markerPosition} />
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={markerPosition} />
          </MapContainer>
        </div>
      </div>
    </ParentContainer>
  );
};

export default HeroSection;
