import React, { useEffect, useState } from "react";
import assets from "../assets/assets";
import { Link } from "react-router-dom";

const ProfileLink = ({ LinkText, Linkto }) => {
  const [url, setUrl] = useState("");
  const [activeClass, setActiveClass] = useState("");

  useEffect(() => {
    // Get the current URL
    const currentUrl = window.location.href;
    setUrl(currentUrl);

    if (currentUrl.includes("maternal-history")) {
      setActiveClass("Maternal Health History");
    } else if (currentUrl.includes("order-history")) {
      setActiveClass("Order History");
    } else if (currentUrl.includes("documents")) {
      setActiveClass("Documents");
    } else if (currentUrl.includes("refer")) {
      setActiveClass("Referrals");
    } else if (currentUrl.includes("pass")) {
      setActiveClass("Acount Settings");
    } else {
      setActiveClass("");
    }
  }, []);

  return (
    <Link
      to={Linkto}
      className={`${
        activeClass == LinkText
          ? "bg-gradient-to-r from-custom-primary to-custom-secondary text-white"
          : ""
      }  hover:bg-gradient-to-r from-custom-primary to-custom-secondary hover:text-white pl-3 py-2 rounded-l-lg  font-semibold`}
    >
      {LinkText}
    </Link>
  );
};

const ProfileSideBar = () => {
  return (
    <div className="">
      <div className="flex gap-2 py-8 justify-center items-center border-b">
        <div className="w-1/2 flex justify-center">
          <img src={assets.profilepic} alt="" className="w-3/4" />
        </div>
        <h1 className="text-2xl w-1/2 font-bold">Kinna Stanton</h1>
      </div>
      <div className="flex flex-col py-8 pl-4 gap-4">
        <ProfileLink
          LinkText={"Maternal Health History"}
          Linkto="/profile/maternal-history"
        />
        <ProfileLink
          LinkText={"Order History"}
          Linkto="/profile/order-history"
        />
        <ProfileLink LinkText={"Documents"} Linkto="/profile/documents" />
        <ProfileLink LinkText={"Referrals"} Linkto="/profile/refer" />
        <ProfileLink LinkText={"Acount Settings"} Linkto="/profile/pass" />
        <ProfileLink LinkText={"Logot"} />
      </div>
    </div>
  );
};

export default ProfileSideBar;
