import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import ParentContainer from "./ParentContainer";
import CountUp from "react-countup";
import { Waypoint } from "react-waypoint";
import "animate.css";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const TranslplantNumbers = ({ backgroundImage }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isCounting, setIsCounting] = useState(false);
  const handleEnter = () => {
    setIsVisible(true);
  };

  const parentRef = useRef(null);
  const main = useRef(null);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const boxes = gsap.utils.toArray(".box");
      boxes.forEach((box) => {
        gsap.fromTo(
          box,
          { x: 200, scale: 0 },
          {
            x: 0,
            scale: 1,

            scrollTrigger: {
              trigger: box,
              start: "bottom 100%",
              end: "top 100%",
              scrub: 4,
              //  markers: true,
            },
          }
        );
      });

      const circles = gsap.utils.toArray(".circle");
      circles.forEach((circle) => {
        gsap.fromTo(
          circle,
          { y: 50, scale: 0 },
          {
            y: 0,
            scale: 1,
            scrollTrigger: {
              trigger: circle,
              start: "bottom 85%",
              end: "top 50%",
              scrub: 4,
              //  markers: true,
            },
          }
        );
      });

      const images = gsap.utils.toArray(".imgs");
      gsap.from(images, {
        opacity: 0,
        y: 50,
        stagger: 0.2,
        scrollTrigger: {
          trigger: parentRef.current,
          start: "top 60%",
          end: "bottom center",
          scrub: 1,
          toggleActions: "play none none none",
          onEnter: () => {
            setIsCounting(true);
          },
          // markers: true,
        },
      });
    }, main);

    return () => ctx.revert();
  }, []);

  return (
    <div ref={main}>
      <ParentContainer
        containerStyle={"flex flex-col bg-center bg-no-repeat bg-cover"}
        containerHeight={"py-28"}
        containerCSS={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <p className="w-full text-center text-2xl text-white pb-20 font-thin circle">
          Stem cell transplantation's <br />
          rising numbers affirm its status
        </p>
        <div className="flex gap-5" ref={parentRef}>
          <div className="w-1/3 flex flex-wrap justify-center gap-2 imgs">
            <h4 className="w-full leading-10 text-center text-5xl font-bold  text-white">
              {isCounting && <CountUp start={0} end={75000} duration={7} />}
            </h4>
            <p className="w-4/5 text-center text-lg text-center text-lg text-white pb-12 font-thin ">
              Stem cell units collected
            </p>
          </div>
          <div className="w-1/3 flex flex-wrap justify-center gap-2 imgs">
            <h4 className="w-full leading-10 text-center text-5xl font-bold  text-white">
              {isCounting && <CountUp start={0} end={80} duration={7} />}
            </h4>
            <p className="w-4/5 text-center text-lg  text-center text-lg text-white pb-12 font-thin">
              Transplant units released in 2023
            </p>
          </div>
          <div className="w-1/3 flex flex-wrap justify-center gap-2 imgs">
            <h4 className="w-full leading-10 text-center text-5xl font-bold  text-white">
              {isCounting && <CountUp start={0} end={665} duration={7} />}
            </h4>
            <p className="w-4/5 text-center text-lg  text-center text-lg text-white pb-12 font-thin">
              Stem cell units released
            </p>
          </div>
        </div>
      </ParentContainer>
    </div>
  );
};

export default TranslplantNumbers;
