import React from "react";
import HeroSection from "./RegenerativeMedicine/HeroSection";
import Uses from "./RegenerativeMedicine/Uses";
import Value from "./RegenerativeMedicine/Value";
import Power from "./RegenerativeMedicine/Power";
import CryoInfinite from "./RegenerativeMedicine/CryoInfinite";
import Why from "./RegenerativeMedicine/Why";
import Limitless from "./RegenerativeMedicine/Limitless";
import MasterStem from "./RegenerativeMedicine/MasterStem";
import ArabiaCell from "./RegenerativeMedicine/ArabiaCell";
import TranslplantNumbers from "../components/TranslplantNumbers";
import assets from "../assets/assets";
import MainHeroSection from "../components/MainHeroSection";
import MainValuable from "../components/MainValuable";

const RegenerativeMedicine = () => {
  return (
    <div>
      <MainHeroSection
        HeadingBlack={"What are Mesenchymal"}
        HeadingBlue={"Stem Cells (MSCs)?"}
        HeadingPara={
          "Mesenchymal stem cells (MSCs) possess remarkable versatility, as they can differentiate into nearly any cell type within the body. MSCs can form bone, cartilage, and tissue cells and are predominantly found in the cord tissue."
        }
        IconOne={
          "Unlock the full potential of ready-to-use MSCs with CellSave."
        }
        IconTwo={"These cells are primed for immediate application."}
        IconThree={
          "Experience the convenience and efficiency of having readily available mesenchymal stem cells."
        }
        BannerImage={assets.regenmed}
        BannerText={"assets.regenmed"}
      />

      <Uses />
      <MainValuable
        HeadingBlack={"Why Is It So "}
        HeadingBlue={"Valuable?"}
        HeadingPara={
          "Lorem ipsum dolor sit amet consectetur. Convallis ut elementum magna amet risus commodo."
        }
        isButton={false}
        ValueList={[
          "Mesenchymal stem cells are used extensively in the exciting area of regenerative medicine research. ",
          "They are also used in numerous clinical trials for wound care, spinal cord injuries, and joint and bone diseases. ",
          "In certain studies, cord tissue stem cells have shown the ability to enhance cord blood treatments and transplants. ",
          "MSCs are being referred to as “smart cells” that head straight to the site of the injured cells to stimulate new cell growth.",
        ]}
      />

      <Power />
      <TranslplantNumbers backgroundImage={assets.Regnumbers} />

      <CryoInfinite />
      <MainValuable
        HeadingBlack={"Why "}
        HeadingBlue={"CryoInfinite?"}
        HeadingPara={
          "Lorem ipsum dolor sit amet consectetur. Convallis ut elementum magna amet risus commodo."
        }
        isButton={false}
        ValueList={[
          "Clinical Grade",
          "100% Pure Stem Cells",
          "Ready-to-Use Stem cells",
          "Guaranteed Service",
          "No HLA Matching Required",
        ]}
      />
      <Limitless />
      <MasterStem />
      <ArabiaCell />
    </div>
  );
};

export default RegenerativeMedicine;
