import React, { useState } from "react";
import assets from "../../assets/assets";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const ThirdStep = ({ handleClickfrom }) => {
  const [phone, setPhone] = useState("");

  const handleChange = (item) => {
    handleClickfrom(item);
  };
  return (
    <div className="bg-gradient-to-r from-custom-primary to-custom-secondary rounded-lg p-[1px]">
      <div className="p-8 bg-white rounded-lg flex flex-wrap w-full">
        <div className="w-4/6">
          <div className="flex flex-col gap-4 w-full border-b pb-8">
            <div className="flex items-center gap-2">
              <div>
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="18" cy="18" r="18" fill="#8884D5" />
                  <path
                    d="M19.08 13.64H13.58V11H23.04V13.32L19.5 16.7C19.98 16.78 20.4467 16.9067 20.9 17.08C21.3533 17.2533 21.7533 17.5 22.1 17.82C22.4467 18.1267 22.7267 18.5133 22.94 18.98C23.1533 19.4333 23.26 19.9867 23.26 20.64C23.26 21.3067 23.14 21.92 22.9 22.48C22.6733 23.04 22.34 23.5267 21.9 23.94C21.4733 24.3533 20.9467 24.6733 20.32 24.9C19.6933 25.1267 18.9933 25.24 18.22 25.24C16.9533 25.24 15.8733 25.02 14.98 24.58C14.1 24.1267 13.36 23.54 12.76 22.82L14.88 20.8C15.36 21.3467 15.86 21.7667 16.38 22.06C16.9133 22.34 17.54 22.48 18.26 22.48C18.8467 22.48 19.32 22.3267 19.68 22.02C20.0533 21.7133 20.24 21.2933 20.24 20.76C20.24 20.1733 20 19.7267 19.52 19.42C19.0533 19.1133 18.38 18.96 17.5 18.96H16.22L15.74 17L19.08 13.64Z"
                    fill="white"
                  />
                </svg>
              </div>
              <p className="text-lg font-semibold capitalize">
                more detailed info
              </p>
            </div>
            <h4 className="text-lg font-semibold mb-2 capitalize">
              Step 1: Your Information
            </h4>
            <div className="pt-2  py-4 grid grid-cols-4 gap-4 w-full">
              <div className=" col-span-4 grid grid-cols-2 gap-4">
                <div className=" ">
                  <label htmlFor="first-Name" className="block mb-1">
                    First Name
                  </label>
                  <input
                    type="text"
                    name="first-Name"
                    id="first-Name"
                    className="border border-gray-300 rounded-md px-3 py-2 w-full"
                  />
                </div>
                <div className="">
                  <label htmlFor="last-Name" className="block mb-1">
                    Last-Name
                  </label>
                  <input
                    type="text"
                    name="last-Name"
                    id="last-Name"
                    className="border border-gray-300 rounded-md px-3 py-2 w-full"
                  />
                </div>
              </div>
              <div className=" col-span-2">
                <label htmlFor="phone-number" className="block mb-1">
                  Phone Number
                </label>
                <PhoneInput
                  country={"ae"}
                  value={phone}
                  onChange={(phone) => setPhone(phone)}
                  enableSearch={true}
                  disableSearchIcon={true}
                />
              </div>
              <div className=" col-span-2">
                <label htmlFor="email" className="block mb-1">
                  Email Address
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                />
              </div>
              <div className=" col-span-4">
                <label htmlFor="address" className="block mb-1">
                  Address
                </label>
                <input
                  type="text"
                  name="address"
                  id="address"
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                />
              </div>
            </div>
            <div className=" col-span-4 grid grid-cols-3 gap-4">
              <div className=" ">
                <label htmlFor="city" className="block mb-1">
                  City
                </label>
                <input
                  type="text"
                  name="city"
                  id="city"
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                />
              </div>
              <div className=" ">
                <label htmlFor="zip-code" className="block mb-1">
                  Zip Code
                </label>
                <input
                  type="text"
                  name="zip-code"
                  id="zip-code"
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                />
              </div>
              <div className="">
                <label htmlFor="country" className="block mb-1">
                  Country
                </label>
                <input
                  type="text"
                  name="country"
                  id="country"
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                />
              </div>
            </div>
            <div className="pt-2  py-4 grid grid-cols-4 gap-4 w-full">
              <div className=" col-span-4 grid grid-cols-2 gap-4">
                <div className=" ">
                  <label htmlFor="first-Name" className="block mb-1">
                    Birth Mother’s DOB
                  </label>
                  <input
                    type="date"
                    name="first-Name"
                    id="first-Name"
                    className="border border-gray-300 rounded-md px-3 py-2 w-full"
                  />
                </div>
                <div className="">
                  <label htmlFor="last-Name" className="block mb-1">
                    Expected Due Date
                  </label>
                  <input
                    type="date"
                    name="last-Name"
                    id="last-Name"
                    className="border border-gray-300 rounded-md px-3 py-2 w-full"
                  />
                </div>
              </div>
              <div className=" col-span-4">
                <label htmlFor="address" className="block mb-1">
                  How did you hear about us? *
                </label>
                <input
                  type="text"
                  name="address"
                  id="address"
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4 w-full pt-8">
            <h4 className="text-lg font-semibold mb-2 capitalize">
              Step 2: Address And Hospital Info
            </h4>
            <div className="pt-2 grid grid-cols-4 gap-4 w-full">
              <div className=" col-span-4 grid grid-cols-2 gap-4">
                <div className="col-span-2 flex flex-wrap gap-4">
                  <p className="text-base col-span-2 w-full">
                    Where should we ship your collection kit(s)? Shipping is
                    free!
                  </p>
                  <div className="flex items-center gap-2">
                    <input
                      type="radio"
                      name="address-info"
                      id="first-Name"
                      className="border border-gray-300 rounded-md px-3 py-2 "
                    />
                    <label htmlFor="first-Name" className="block ">
                      Same address as contact
                    </label>
                  </div>
                  <div className="flex items-center gap-2">
                    <input
                      type="radio"
                      name="address-info"
                      id="first-Name"
                      className="border border-gray-300 rounded-md px-3 py-2 "
                    />
                    <label htmlFor="first-Name" className="block ">
                      Enter new address
                    </label>
                  </div>
                </div>
                <div className="col-span-4">
                  <label htmlFor="last-Name" className="block mb-1">
                    Name
                  </label>
                  <input
                    type="text"
                    name="last-Name"
                    id="last-Name"
                    className="border border-gray-300 rounded-md px-3 py-2 w-full"
                  />
                </div>
              </div>
            </div>
            <div className=" col-span-4 grid grid-cols-3 gap-4">
              <div className=" ">
                <label htmlFor="city" className="block mb-1">
                  City
                </label>
                <input
                  type="text"
                  name="city"
                  id="city"
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                />
              </div>
              <div className=" ">
                <label htmlFor="zip-code" className="block mb-1">
                  Zip Code
                </label>
                <input
                  type="text"
                  name="zip-code"
                  id="zip-code"
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                />
              </div>
              <div className="">
                <label htmlFor="country" className="block mb-1">
                  Country
                </label>
                <input
                  type="text"
                  name="country"
                  id="country"
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4 w-full pt-8 border-b pb-8">
            <h4 className="text-lg font-semibold mb-2 capitalize">
              Step 3: Payment
            </h4>
            <div className="pt-2 grid grid-cols-4 gap-4 w-full">
              <div className=" col-span-4 grid grid-cols-2 gap-4">
                <div className="col-span-2 flex flex-wrap gap-4">
                  <p className="text-base col-span-2 w-full">Billing Address</p>
                  <div className="flex items-center gap-2">
                    <input
                      type="radio"
                      name="address-info"
                      id="first-Name"
                      className="border border-gray-300 rounded-md px-3 py-2 "
                    />
                    <label htmlFor="first-Name" className="block ">
                      Same address as contact
                    </label>
                  </div>
                  <div className="flex items-center gap-2">
                    <input
                      type="radio"
                      name="address-info"
                      id="first-Name"
                      className="border border-gray-300 rounded-md px-3 py-2 "
                    />
                    <label htmlFor="first-Name" className="block ">
                      Enter new address
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p className="text-base pt-8 w-full">
            By clicking Proceed to Pay, I agree to CellSave’s refund policy.
          </p>
          <div className="flex gap-5 pt-8">
            <div className=" bg-gradient-to-r from-custom-primary to-custom-secondary transition-all text-white text-base font-semibold px-8 py-2 rounded-3xl">
              Checkout
            </div>
            <div
              onClick={() => handleChange("secondStep")}
              className="bg-gradient-to-r from-custom-primary to-custom-secondary transition-all p-[1px] group hover:text-white text-base  rounded-3xl "
            >
              <div className="bg-white group-hover:bg-transparent px-8 py-2 rounded-3xl ">
                <span class="bg-gradient-to-r from-custom-primary to-custom-secondary text-transparent bg-clip-text group-hover:text-white transition-all font-semibold">
                  Previous
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="w-2/6 pl-12">
          <div className="border rounded-lg py-8 px-4">
            <h3 className="text-custom-primary text-xl font-semibold capitalize">
              order summary
            </h3>
            <div className="flex flex-wrap border-b py-8 items-center justify-between">
              <img src={assets.checkoutProduct} alt="" className=" w-6/12" />
              <h3 className="w-5/12 text-xl font-semibold">CryoAdvanced</h3>
            </div>
            <div className="flex flex-col gap-2 py-4">
              <div className="flex flex-wrap  items-center justify-between">
                <h4 className="text-base">Total Price</h4>
                <h4 className="text-base font-semibold">37,595 AED</h4>{" "}
              </div>
              <div className="flex flex-wrap  items-center justify-between">
                <h4 className="text-base">Due Now</h4>
                <h4 className="text-base font-semibold">14,095 AED</h4>{" "}
              </div>
              <div className="flex flex-wrap  items-center justify-between">
                <h4 className="text-base">Due Monthly</h4>
                <h4 className="text-base font-semibold">1958.33 AED</h4>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThirdStep;
