import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import ParentContainer from "../../components/ParentContainer";
import assets from "../../assets/assets";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Navigation } from "swiper/modules";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Timeline = () => {
  const Height = "150px";

  const main = useRef();

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const boxes = gsap.utils.toArray(".box");
      boxes.forEach((box) => {
        gsap.fromTo(
          box,
          { y: 200, scale: 0 },
          {
            y: 0,
            scale: 1,

            scrollTrigger: {
              trigger: box,
              start: "bottom 100%",
              end: "top 100%",
              scrub: 4,
              //  markers: true,
            },
          }
        );
      });

      const circles = gsap.utils.toArray(".circle");
      circles.forEach((circle) => {
        gsap.fromTo(
          circle,
          { x: 500, scale: 0 },
          {
            x: 0,
            scale: 1,
            scrollTrigger: {
              trigger: circle,
              start: "bottom 85%",
              end: "top 50%",
              scrub: 4,
              //  markers: true,
            },
          }
        );
      });

      const images = gsap.utils.toArray(".imgs");
      images.forEach((imgs) => {
        gsap.fromTo(
          imgs,
          { scale: 0.5, opacity: 0, x: -300 },
          {
            scale: 1,
            opacity: 1,
            x: 0,
            scrollTrigger: {
              trigger: imgs,
              start: "bottom 85%",
              end: "top 50%",
              scrub: 4,
              //    markers: true,
            },
          }
        );
      });

      const bgssss = gsap.utils.toArray(".bgs");
      bgssss.forEach((bgs) => {
        gsap.fromTo(
          bgs,
          { background: "linear-gradient(to right, white, white)" },
          {
            background: "linear-gradient(to right, #37424A, black)",
            scrollTrigger: {
              trigger: bgs,
              start: "bottom 50%",
              end: "top 0%",
              scrub: true,
              // markers: true,
            },
          }
        );
      });
    }, main);

    return () => ctx.revert();
  }, []);

  return (
    <div ref={main}>
      <ParentContainer>
        <div className="flex justify-center flex-wrap py-20 box">
          <h2 className="text-5xl font-bold text-left  w-1/2 ">
            CellSave <span className="text-custom-primary">Story</span>
          </h2>
          <p className="w-1/2">
            Your journey with CellSave begins with a magical moment but lasts a
            lifetime, which is why our commitment to world-class quality and
            cutting-edge technology is matched with personalized care and
            helpful support every step of the way. CellSave offers families
            across the region the most advanced and comprehensive stem cell
            banking services in the world.
          </p>
        </div>
        <div className="flex  items-center box">
          <div className="swiper-button-prev">
            <svg
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="25"
                cy="25"
                r="25"
                transform="matrix(0 -1 -1 0 50 50)"
                fill="#37424A"
              />
              <path
                d="M23.7246 21.4225L20.1348 24.9618L23.7246 28.501"
                fill="white"
              />
              <path
                d="M23.7246 21.4225L20.1348 24.9618L23.7246 28.501"
                stroke="white"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M28.709 25.0039L20.4294 25.0039"
                stroke="white"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <Swiper
            spaceBetween={0}
            slidesPerView={5}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
            modules={[Navigation, Autoplay]}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            className="w-4/5"
          >
            <SwiperSlide>
              <div id="first-row " className="w-full">
                <div className={`h-[${Height}] h-[150px] flex items-center`}>
                  <div className="p-[1px] rounded-xl bg-gradient-to-r from-custom-primary to-custom-secondary ">
                    <div className="bg-[#FBF7F5] rounded-xl p-2 text-center">
                      <h4 className="font-semibold">Jul 2020</h4>
                      <p>Lorem ipsum dolor sit amet consectetur.</p>
                    </div>
                  </div>
                </div>
                <div>
                  <img src={assets.timelineOne} alt="" className="w-full" />
                </div>
                <div
                  className={`h-[${Height}] h-[150px] flex items-center`}
                ></div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div id="second-row" className="w-full">
                <div
                  className={`h-[${Height}] h-[150px] flex items-center`}
                ></div>
                <div>
                  <img src={assets.timelineTwo} alt="" className="w-full" />
                </div>
                <div className={`h-[${Height}] h-[150px] flex items-center`}>
                  <div className="p-[1px] rounded-xl bg-gradient-to-r from-custom-primary to-custom-secondary ">
                    <div className="bg-[#FBF7F5] rounded-xl p-2 text-center">
                      <h4 className="font-semibold">Jul 2020</h4>
                      <p>Lorem ipsum dolor sit amet consectetur.</p>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div id="first-row " className="w-full">
                <div className={`h-[${Height}] h-[150px] flex items-center`}>
                  <div className="p-[1px] rounded-xl bg-gradient-to-r from-custom-primary to-custom-secondary ">
                    <div className="bg-[#FBF7F5] rounded-xl p-2 text-center">
                      <h4 className="font-semibold">Jul 2020</h4>
                      <p>Lorem ipsum dolor sit amet consectetur.</p>
                    </div>
                  </div>
                </div>
                <div>
                  <img src={assets.timelineOne} alt="" className="w-full" />
                </div>
                <div
                  className={`h-[${Height}] h-[150px] flex items-center`}
                ></div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div id="second-row" className="w-full">
                <div
                  className={`h-[${Height}] h-[150px] flex items-center`}
                ></div>
                <div>
                  <img src={assets.timelineTwo} alt="" className="w-full" />
                </div>
                <div className={`h-[${Height}] h-[150px] flex items-center`}>
                  <div className="p-[1px] rounded-xl bg-gradient-to-r from-custom-primary to-custom-secondary ">
                    <div className="bg-[#FBF7F5] rounded-xl p-2 text-center">
                      <h4 className="font-semibold">Jul 2020</h4>
                      <p>Lorem ipsum dolor sit amet consectetur.</p>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div id="first-row " className="w-full">
                <div className={`h-[${Height}] h-[150px] flex items-center`}>
                  <div className="p-[1px] rounded-xl bg-gradient-to-r from-custom-primary to-custom-secondary ">
                    <div className="bg-[#FBF7F5] rounded-xl p-2 text-center">
                      <h4 className="font-semibold">Jul 2020</h4>
                      <p>Lorem ipsum dolor sit amet consectetur.</p>
                    </div>
                  </div>
                </div>
                <div>
                  <img src={assets.timelineOne} alt="" className="w-full" />
                </div>
                <div
                  className={`h-[${Height}] h-[150px] flex items-center`}
                ></div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div id="second-row" className="w-full">
                <div
                  className={`h-[${Height}] h-[150px] flex items-center`}
                ></div>
                <div>
                  <img src={assets.timelineTwo} alt="" className="w-full" />
                </div>
                <div className={`h-[${Height}] h-[150px] flex items-center`}>
                  <div className="p-[1px] rounded-xl bg-gradient-to-r from-custom-primary to-custom-secondary ">
                    <div className="bg-[#FBF7F5] rounded-xl p-2 text-center">
                      <h4 className="font-semibold">Jul 2020</h4>
                      <p>Lorem ipsum dolor sit amet consectetur.</p>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          <div className="swiper-button-next">
            <svg
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="25"
                cy="25"
                r="25"
                transform="rotate(-90 25 25)"
                fill="#37424A"
              />
              <path
                d="M26.2764 20.6282L29.8662 24.3605L26.2764 28.0928"
                fill="white"
              />
              <path
                d="M26.2764 20.6282L29.8662 24.3605L26.2764 28.0928"
                stroke="white"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M21.291 24.4033L29.5706 24.4033"
                stroke="white"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_590_4369"
                  x1="-4.74138"
                  y1="5.55556"
                  x2="58.7123"
                  y2="10.0354"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#8884D5" />
                  <stop offset="1" stop-color="#CF0072" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
      </ParentContainer>
    </div>
  );
};

export default Timeline;
