import React, { useState } from "react";
import assets from "../../assets/assets";

const Form = () => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    console.log("Selected Option:", option);
  };

  const iconcolor = "black";
  return (
    <div className="flex flex-wrap p-8 bg-white">
      <div className=" border rounded-lg p-8 pt-2 w-full ">
        <form className="">
          <div className="pt-2 border-b pb-8 grid grid-cols-4 gap-4">
            <h4 className="text-lg font-semibold mb-2 col-span-4">
              About you*
            </h4>
            <div className=" col-span-4 grid grid-cols-2 gap-4">
              <div className=" ">
                <label htmlFor="first-Name" className="block mb-1">
                  First Name
                </label>
                <input
                  type="text"
                  name="first-Name"
                  id="first-Name"
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                />
              </div>

              <div className="">
                <label htmlFor="last-Name" className="block mb-1">
                  Last-Name
                </label>
                <input
                  type="text"
                  name="last-Name"
                  id="last-Name"
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                />
              </div>
            </div>

            <div className=" col-span-2">
              <label htmlFor="phone-number" className="block mb-1">
                Phone Number
              </label>
              <input
                type="tel"
                name="phone-number"
                id="phone-number"
                className="border border-gray-300 rounded-md px-3 py-2 w-full"
              />
            </div>
            <div className=" col-span-2">
              <label htmlFor="email" className="block mb-1">
                Email Address
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className="border border-gray-300 rounded-md px-3 py-2 w-full"
              />
            </div>
            <div className=" col-span-4">
              <label htmlFor="address" className="block mb-1">
                Address
              </label>
              <input
                type="text"
                name="address"
                id="address"
                className="border border-gray-300 rounded-md px-3 py-2 w-full"
              />
            </div>
          </div>
          <div className="pt-10 border-b pb-8 grid grid-cols-4 gap-4">
            <h4 className="text-lg font-semibold mb-2 col-span-4 capitalize">
              About your friend*
            </h4>
            <div className=" col-span-4 grid grid-cols-2 gap-4">
              <div className=" ">
                <label htmlFor="first-Name" className="block mb-1">
                  First Name
                </label>
                <input
                  type="text"
                  name="first-Name"
                  id="first-Name"
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                />
              </div>

              <div className="">
                <label htmlFor="last-Name" className="block mb-1">
                  Last-Name
                </label>
                <input
                  type="text"
                  name="last-Name"
                  id="last-Name"
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                />
              </div>
            </div>

            <div className=" col-span-2">
              <label htmlFor="phone-number" className="block mb-1">
                Phone Number
              </label>
              <input
                type="tel"
                name="phone-number"
                id="phone-number"
                className="border border-gray-300 rounded-md px-3 py-2 w-full"
              />
            </div>
            <div className=" col-span-2">
              <label htmlFor="email" className="block mb-1">
                Email Address
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className="border border-gray-300 rounded-md px-3 py-2 w-full"
              />
            </div>
            <div className=" col-span-2">
              <label htmlFor="friend-date" className="block mb-1">
                Approximate Due Date*
              </label>
              <input
                type="date"
                name="friend-date"
                id="friend-date"
                className="border border-gray-300 rounded-md px-3 py-2 w-full"
              />
            </div>
          </div>
          <div className="pt-10 border-b pb-8 grid grid-cols-4 gap-4">
            <h4 className="text-lg font-semibold mb-2 col-span-4 capitalize">
              About your friend*
            </h4>

            <div className=" col-span-4">
              <label htmlFor="message" className="block mb-1">
                Message
              </label>
              <textarea
                type=""
                rows={5}
                name="message"
                id="message"
                className="border border-gray-300 rounded-md px-3 py-2 w-full"
              />
            </div>
          </div>

          <button
            type="submit"
            className=" bg-gradient-to-r from-custom-primary to-custom-secondary hover:scale-110 transition-all text-white text-base px-8 py-2 rounded-3xl mt-6"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Form;
