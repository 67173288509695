import React from "react";
import MainHeroSection from "../components/MainHeroSection";
import assets from "../assets/assets";
import MainAdvantagesSection from "../components/MainAdvantagesSection";
import MainValuable from "../components/MainValuable";
import TranslplantNumbers from "../components/TranslplantNumbers";
import LifeTimeBanner from "../components/LifeTimeBanner";

const CordBlood = () => {
  const lineBreak = false;
  const AdvantagePara = false;
  return (
    <div>
      <MainHeroSection
        HeadingBlack={"What is"}
        HeadingBreak={lineBreak}
        HeadingBlue={"Cord Blood"}
        HeadingPara={
          "Cord blood is a rich source of stem cells that remain in the placenta and umbilical cord after birth. These stem cells are known as hematopoietic stem cells (HSCs), the building blocks of our blood and the foundation of our immune system."
        }
        IconOne={"Your child is a 100% genetic match to their own stem cells."}
        IconTwo={
          "Their cells have the potential to treat certain non-genetic diseases and cancers. "
        }
        IconThree={
          "Siblings and family members may also be a potential match. Protect your family today."
        }
        BannerImage={assets.cordBanner}
        BannerText={"The Perfect Match"}
      />
      <MainAdvantagesSection
        HeadingBlack={"Cord Blood"}
        HeadingBlue={"Advantages"}
        Image={assets.cordAdvantage}
        isPara={AdvantagePara}
        TwoImages={true}
        Advantages={[
          "cancers",
          "Metabolic Disorders",
          "Blood Disorders",
          "Immune Disordes",
        ]}
      />
      <MainValuable
        HeadingBlack={"Why Is It So "}
        HeadingBlue={"Valuable?"}
        HeadingPara={
          "Cord blood stem cells are incredibly powerful and unique. They can boost the immune system and repopulate new blood cells after being damaged by disease or illness."
        }
        isButton={false}
        ValueList={[
          "They can reproduce into red blood cells, which carry oxygen throughout our bodies.",
          "Metabolic Disorders",
          "They can reproduce into Platelets, which create clots preventing us from bleeding excessively. ",
        ]}
      />
      <TranslplantNumbers backgroundImage={assets.templateCord} />
      <LifeTimeBanner />
    </div>
  );
};

export default CordBlood;
