import React from "react";
import MainHeroSection from "../components/MainHeroSection";
import assets from "../assets/assets";
import MainAdvantagesSection from "../components/MainAdvantagesSection";
import MainValuable from "../components/MainValuable";
import TranslplantNumbers from "../components/TranslplantNumbers";
import LifeTimeBanner from "../components/LifeTimeBanner";

const PlacentaTissue = () => {
  const lineBreak = false;
  const AdvantagePara = true;
  return (
    <div>
      <MainHeroSection
        HeadingBlack={"What is"}
        HeadingBreak={lineBreak}
        HeadingBlue={"Placenta Tissue?"}
        HeadingPara={
          "The placenta is an integral component of your baby’s life support system. It is responsible for transferring oxygen and essential nutrients between the mother and the baby. Stem cells from the placental tissue have tremendous potential in regenerative medicine."
        }
        q
        IconOne={
          "Lorem ipsum dolor sit amet consectetur. At lobortis vel lectus sapien consectetur."
        }
        IconTwo={
          "Lorem ipsum dolor sit amet consectetur. At lobortis vel lectus sapien consectetur."
        }
        IconThree={
          "Lorem ipsum dolor sit amet consectetur. At lobortis vel lectus sapien consectetur."
        }
        BannerImage={assets.placentaTissueBanner}
        BannerText={"The Perfect Match"}
      />
      <MainAdvantagesSection
        HeadingBlack={"Placenta Tissue"}
        HeadingBlue={"Advantages"}
        TwoImages={false}
        Image={assets.cordTissueAdvantage}
        isPara={AdvantagePara}
        Para={
          "There are unlimited possibilities when it comes to the future of placental tissue stem cells and its uses in regenerative medicine. These particular stem cells are currently being used in more than 100 regenerative medicine clinical trials worldwide for treatments such as:"
        }
        Advantages={[
          "Heart Disease",
          "Ischaemic Stroke",
          "Osteoarthritis",
          "Crohn’s Disease",
          "Type 2 Diabetes",
        ]}
      />
      <MainValuable
        HeadingBlack={"The Power of the  "}
        HeadingBlue={"Placenta"}
        HeadingPara={
          "Lorem ipsum dolor sit amet consectetur. Convallis ut elementum magna amet risus commodo.  "
        }
        isButton={true}
        ValueList={[
          "The placenta is a rich source of mesenchymal stem cells (MSCs) and mesenchymal-like stem cells, which are multipotent in nature. ",
          "This means they have the ability to differentiate into specialized cells with specific functions.",
          "Scientists have successfully repaired bones, cartilage and fat tissues using MSCs.",
        ]}
      />
      <TranslplantNumbers backgroundImage={assets.TransplantNumbers} />
      <LifeTimeBanner />
    </div>
  );
};
export default PlacentaTissue;
