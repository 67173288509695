import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import ParentContainer from "../../components/ParentContainer";
import { Waypoint } from "react-waypoint";
import "animate.css";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Button from "../../components/Button";
gsap.registerPlugin(ScrollTrigger);

const Safe = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleEnter = () => {
    setIsVisible(true);
  };

  const main = useRef();

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const boxes = gsap.utils.toArray(".box");
      boxes.forEach((box) => {
        gsap.fromTo(
          box,
          { y: 300, opacity: 0 },
          {
            y: 0,
            opacity: 1,
            scrollTrigger: {
              trigger: box,
              start: "bottom 85%",
              end: "top 80%",
              scrub: 4,
              // markers: true,
            },
          }
        );
      });
    }, main);

    return () => ctx.revert();
  }, []);
  return (
    <div ref={main}>
      <ParentContainer
        containerStyle={"flex justify-center flex-wrap gap-5 py-40"}
      >
        <Button
          Linkto={"stem-cells-sceince"}
          titleof={
            "             Newborn Stem Cells Introducing                "
          }
          CustomStyle={"!justify-center !px-0 box"}
        />

        <h2 className="text-5xl font-bold text-center  w-2/3 mx-5 max-md:w-full box">
          Safeguard your family’s future in 3 simple steps
        </h2>
        <p className="w-1/2 text-center text-xl opacity-80 max-md:w-full box">
          Once you make the choice to preserve your newborn’s stem cells, we
          will be there every step of the way to ensure the process is simple
          and seamless.
        </p>
      </ParentContainer>
    </div>
  );
};

export default Safe;
