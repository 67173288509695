import React, { useState } from "react";
import ParentContainer from "../../components/ParentContainer";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Form = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [phone, setPhone] = useState("");

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    console.log("Selected Option:", option);
  };
  return (
    <ParentContainer containerStyle={"flex flex-wrap "}>
      <div className="w-1/3">
        <h3 className="text-4xl font-bold pb-2">
          {" "}
          Send a gift <span className=" text-custom-primary">Now</span>
        </h3>
        <p className="text-base">A gift with life‑saving possibilities</p>
      </div>
      <form className="w-2/3 ">
        <div className=" border-b pb-8">
          <h4 className="text-lg font-semibold mb-2">Gift Amount</h4>
          <div className="grid grid-cols-4 gap-4 w-full">
            {["5000", "10,000", "15,000", "20,000"].map((option) => (
              <div key={option} className="cursor-pointer">
                <input
                  type="radio"
                  id={`option${option}`}
                  name="options"
                  className="sr-only"
                  checked={selectedOption === option}
                  onChange={() => handleOptionChange(option)}
                />
                <label htmlFor={`option${option}`}>
                  <div
                    className={`border-2 border-gray-300 rounded-md text-center py-4 p-2 transition-colors ${
                      selectedOption === option
                        ? "border-custom-primary text-custom-primary"
                        : "hover:border-custom-primary hover:text-custom-primary"
                    }`}
                  >
                    {option} <br />
                    AED
                  </div>
                </label>
              </div>
            ))}
          </div>
        </div>
        <div className="pt-8 border-b pb-8 grid grid-cols-4 gap-4">
          <h4 className="text-lg font-semibold mb-2 col-span-4">
            Your Information
          </h4>

          <div className=" col-span-2 w-full">
            <label htmlFor="first-name" className="block mb-1">
              First Name
            </label>
            <input
              type="text"
              name="first-name"
              id="first-name"
              className="border border-gray-300 rounded-md px-3 py-2 w-full"
            />
          </div>

          <div className="col-span-2 ">
            <label htmlFor="last-name" className="block mb-1">
              Last Name
            </label>
            <input
              type="text"
              name="last-name"
              id="last-name"
              className="border border-gray-300 rounded-md px-3 py-2 w-full"
            />
          </div>

          <div className=" col-span-2">
            <label htmlFor="phone-number" className="block mb-1">
              Phone Number
            </label>
            <PhoneInput
              country={"ae"}
              value={phone}
              onChange={(phone) => setPhone(phone)}
              enableSearch={true}
              disableSearchIcon={true}
            />
          </div>

          <div className=" col-span-2">
            <label htmlFor="email" className="block mb-1">
              Email Address
            </label>
            <input
              type="email"
              name="email"
              id="email"
              className="border border-gray-300 rounded-md px-3 py-2 w-full"
            />
          </div>

          <div className=" col-span-4 grid grid-cols-3 gap-4">
            <div className=" ">
              <label htmlFor="city" className="block mb-1">
                City
              </label>
              <input
                type="text"
                name="city"
                id="city"
                className="border border-gray-300 rounded-md px-3 py-2 w-full"
              />
            </div>
            <div className=" ">
              <label htmlFor="zip-code" className="block mb-1">
                Zip Code
              </label>
              <input
                type="text"
                name="zip-code"
                id="zip-code"
                className="border border-gray-300 rounded-md px-3 py-2 w-full"
              />
            </div>
            <div className="">
              <label htmlFor="country" className="block mb-1">
                Country
              </label>
              <input
                type="text"
                name="country"
                id="country"
                className="border border-gray-300 rounded-md px-3 py-2 w-full"
              />
            </div>
          </div>
        </div>
        <div className="pt-8 border-b pb-8 grid grid-cols-4 gap-4">
          <h4 className="text-lg font-semibold mb-2 col-span-4">
            Recipient Information
          </h4>

          <div className="col-span-2 w-full">
            <label htmlFor="recipient-first-name" className="block mb-1">
              Recipient's First Name
            </label>
            <input
              type="text"
              name="recipient-first-name"
              id="recipient-first-name"
              className="border border-gray-300 rounded-md px-3 py-2 w-full"
            />
          </div>

          <div className="col-span-2 ">
            <label htmlFor="recipient-last-name" className="block mb-1">
              Recipient's Last Name
            </label>
            <input
              type="text"
              name="recipient-last-name"
              id="recipient-last-name"
              className="border border-gray-300 rounded-md px-3 py-2 w-full"
            />
          </div>

          <div className=" col-span-2">
            <label htmlFor="recipient-phone-number" className="block mb-1">
              Recipient's Phone Number
            </label>
            <input
              type="tel"
              name="recipient-phone-number"
              id="recipient-phone-number"
              className="border border-gray-300 rounded-md px-3 py-2 w-full"
            />
          </div>

          <div className=" col-span-2">
            <label htmlFor="recipient-email" className="block mb-1">
              Recipient's Email Address
            </label>
            <input
              type="email"
              name="recipient-email"
              id="recipient-email"
              className="border border-gray-300 rounded-md px-3 py-2 w-full"
            />
          </div>

          <div className=" col-span-4 grid grid-cols-3 gap-4">
            <div className=" ">
              <label htmlFor="recipient-city" className="block mb-1">
                Recipient's City
              </label>
              <input
                type="text"
                name="recipient-city"
                id="recipient-city"
                className="border border-gray-300 rounded-md px-3 py-2 w-full"
              />
            </div>
            <div className=" ">
              <label htmlFor="recipient-zip-code" className="block mb-1">
                Recipient's Zip Code
              </label>
              <input
                type="text"
                name="recipient-zip-code"
                id="recipient-zip-code"
                className="border border-gray-300 rounded-md px-3 py-2 w-full"
              />
            </div>
            <div className="">
              <label htmlFor="recipient-country" className="block mb-1">
                Recipient's Country
              </label>
              <input
                type="text"
                name="recipient-country"
                id="recipient-country"
                className="border border-gray-300 rounded-md px-3 py-2 w-full"
              />
            </div>
          </div>
        </div>
        <div className="pt-8 border-b pb-8 ">
          <h4 className="text-lg font-semibold mb-6 col-span-4">
            Billing Address
          </h4>
          <div class=" text-center flex flex-wrap justify-start gap-5">
            <div class="flex items-center ">
              <input
                id="radio1"
                type="radio"
                name="Address"
                class="hidden"
                checked
              />
              <label for="radio1" class="flex items-center cursor-pointer">
                <span class="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
                Same Address
              </label>
            </div>

            <div class="flex items-center ">
              <input id="radio2" type="radio" name="Address" class="hidden" />
              <label for="radio2" class="flex items-center cursor-pointer">
                <span class="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
                New Address
              </label>
            </div>
          </div>
        </div>
        <div className="pt-8 border-b pb-8 ">
          <h4 className="text-lg font-semibold mb-6 col-span-4">
            How do you want to gift it?{" "}
          </h4>
          <div class=" text-center flex flex-wrap justify-start gap-5">
            <div class="flex items-center ">
              <input
                id="giftrecipient"
                type="radio"
                name="gift-card"
                class="hidden"
                checked
              />
              <label
                for="giftrecipient"
                class="flex items-center cursor-pointer"
              >
                <span class="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
                I want CellSave to send the Gift Card to the recipient via
                email.
              </label>
            </div>

            <div class="flex items-center ">
              <input
                id="giftclient"
                type="radio"
                name="gift-card"
                class="hidden"
              />
              <label for="giftclient" class="flex items-center cursor-pointer">
                <span class="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
                I want CellSave to send the Gift Card to me via email.
              </label>
            </div>

            <div class="flex items-center ">
              <input
                id="giftphysical"
                type="radio"
                name="gift-card"
                class="hidden"
              />
              <label
                for="giftphysical"
                class="flex items-center cursor-pointer"
              >
                <span class="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
                I want CellSave to deliver a physical gift card to the recipient
                address.{" "}
              </label>
            </div>
          </div>
        </div>
        <button
          type="submit"
          className=" bg-gradient-to-r from-custom-primary to-custom-secondary hover:scale-110 transition-all text-white text-base px-8 py-2 rounded-3xl mt-6"
        >
          Checkout
        </button>
      </form>
    </ParentContainer>
  );
};

export default Form;
