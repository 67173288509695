import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import assets from "../../assets/assets";
import ParentContainer from "../../components/ParentContainer";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Featured = () => {
  const main = useRef();

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const boxes = gsap.utils.toArray(".box");
      boxes.forEach((box) => {
        gsap.fromTo(
          box,
          { y: 200, opacity: 0 },
          {
            y: 0,
            opacity: 1,
            scrollTrigger: {
              trigger: box,
              start: "bottom 100%",
              end: "top 70%",
              scrub: 4,
              // markers: true,
            },
          }
        );
      });

      const circles = gsap.utils.toArray(".circle2");
      circles.forEach((circle) => {
        gsap.fromTo(
          circle,
          { x: 150, y: 200, opacity: 0 },
          {
            x: 0,
            y: 0,
            opacity: 1,
            scrollTrigger: {
              trigger: circle,
              start: "bottom 100%",
              end: "top 70%",
              scrub: 4,
              //  markers: true,
            },
          }
        );
      });

      const box2s = gsap.utils.toArray(".box2");
      box2s.forEach((boxs2) => {
        gsap.fromTo(
          boxs2,
          { x: 200, opacity: 0 },
          {
            x: 0,
            opacity: 1,
            scrollTrigger: {
              trigger: boxs2,
              start: "bottom 100%",
              end: "top 70%",
              scrub: 4,
              //  markers: true,
            },
          }
        );
      });
    }, main);

    return () => ctx.revert();
  }, []);

  return (
    <div ref={main}>
      <ParentContainer
        containerStyle={
          " flex flex-wrap items-start bg-gradient-to-r from-custom-gray to-black justify-center"
        }
        containerHeight={"py-36"}
      >
        <h2 className="text-5xl font-bold text-center text-white box mx-5">
          CellSave{" "}
          <span class="bg-gradient-to-br from-custom-primary to-custom-secondary text-transparent bg-clip-text ">
            Featured In
          </span>
        </h2>
        <div className="w-full grid grid-cols-5 gap-5 pt-24 max-md:grid-cols-1 circle2">
          <div className="group bg-gradient-to-br from-custom-primary to-custom-secondary rounded-xl p-[1px] ">
            <div className="bg-custom-gray group-hover:bg-transparent w-full p-16 flex justify-center rounded-xl">
              <img src={assets.Featuredin} alt="" />
            </div>
          </div>
          <div className="group bg-gradient-to-br from-custom-primary to-custom-secondary rounded-xl p-[1px]">
            <div className="bg-custom-gray group-hover:bg-transparent w-full p-16 flex justify-center rounded-xl">
              <img src={assets.Featuredin} alt="" />
            </div>
          </div>
          <div className="group bg-gradient-to-br from-custom-primary to-custom-secondary rounded-xl p-[1px]">
            <div className="bg-custom-gray group-hover:bg-transparent w-full p-16 flex justify-center rounded-xl">
              <img src={assets.Featuredin} alt="" />
            </div>
          </div>
          <div className="group bg-gradient-to-br from-custom-primary to-custom-secondary rounded-xl p-[1px]">
            <div className="bg-custom-gray group-hover:bg-transparent w-full p-16 flex justify-center rounded-xl">
              <img src={assets.Featuredin} alt="" />
            </div>
          </div>
          <div className="group bg-gradient-to-br from-custom-primary to-custom-secondary rounded-xl p-[1px]">
            <div className="bg-custom-gray group-hover:bg-transparent w-full p-16 flex justify-center rounded-xl">
              <img src={assets.Featuredin} alt="" />
            </div>
          </div>
        </div>
      </ParentContainer>
    </div>
  );
};

export default Featured;
