import React from "react";
import ParentContainer from "../components/ParentContainer";
import ProfileSideBar from "../components/ProfileSideBar";
import HeroSection from "./ProfileHealthHistory/HeroSection";
import Attention from "./ProfileHealthHistory/Attention";
import Form from "./ProfileHealthHistory/Form";

const ChangePass = () => {
  return (
    <ParentContainer
      containerHeight={"py-0"}
      containerStyle="bg-[#FBF7F5] flex justify-stretch "
    >
      <div className="w-1/5 bg-white border-r">
        <ProfileSideBar />
      </div>
      <div className="w-4/5 bg-white">
        <HeroSection />
        <div className="flex flex-wrap p-8 bg-white">
          <div className=" border rounded-lg p-8 pt-2 w-full ">
            <form action="">
              <div className="pt-4  pb-4 grid grid-cols-4 gap-4">
                <h4 className="text-lg font-semibold mb-2 col-span-4">
                  Change your password
                </h4>
                <div className=" col-span-4 grid grid-cols-3 gap-4">
                  <div className=" ">
                    <label htmlFor="first-Name" className="block mb-1">
                      Old Password
                    </label>
                    <input
                      type="text"
                      name="first-Name"
                      id="first-Name"
                      className="border border-gray-300 rounded-md px-3 py-2 w-full"
                    />
                  </div>
                  <div className=" ">
                    <label htmlFor="middle-name" className="block mb-1">
                      New Password
                    </label>
                    <input
                      type="text"
                      name="middle-name"
                      id="middle-name"
                      className="border border-gray-300 rounded-md px-3 py-2 w-full"
                    />
                  </div>
                  <div className="">
                    <label htmlFor="last-Name" className="block mb-1">
                      Confirm Password
                    </label>
                    <input
                      type="text"
                      name="last-Name"
                      id="last-Name"
                      className="border border-gray-300 rounded-md px-3 py-2 w-full"
                    />
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className=" bg-gradient-to-r from-custom-primary to-custom-secondary hover:scale-110 transition-all text-white text-base px-8 py-2 rounded-3xl mt-6"
              >
                Next
              </button>
            </form>
          </div>
        </div>
      </div>
    </ParentContainer>
  );
};

export default ChangePass;
