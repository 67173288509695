import React from "react";
import HeroSection from "./Contact/HeroSection";
import Support from "./Contact/Support";
import Form from "./Contact/Form";

const Contact = () => {
  return (
    <div>
      <HeroSection />
      <Support />
      <Form />
    </div>
  );
};

export default Contact;
