import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const NewText = () => {
  const parentRef = useRef(null);
  const childRef = useRef(null);
  const textRef = useRef(null);
  const [text, setText] = useState("1 The Power of ");
  const [secondText, setSecondText] = useState("Newborn Stem Cells");

  useEffect(() => {
    const parentElement = parentRef.current;
    const childElement = childRef.current;
    const textElement = textRef.current;

    const updateText = (newText, newSecondText) => {
      gsap.to(textElement, {
        opacity: 0,
        duration: 0.5,
        onComplete: () => {
          setText(newText);
          setSecondText(newSecondText);
          gsap.to(textElement, { opacity: 1, duration: 0.5 });
        },
      });
    };

    const createTrigger = (start, end, color, newText, newSecondText) => {
      ScrollTrigger.create({
        trigger: parentElement,
        start: `${start}% center`,
        end: `${end}% center`,
        onEnter: () => {
          gsap.to(childElement, { background: color, duration: 0.5 });
          updateText(newText, newSecondText);
        },
        onEnterBack: () => {
          gsap.to(childElement, { background: color, duration: 0.5 });
          updateText(newText, newSecondText);
        },
      });
    };

    createTrigger(0, 33, "#ffffff00", "1 The Power of ", "Newborn Stem Cells");
    createTrigger(
      33,
      66,
      "linear-gradient(to right, #8884D5, #CF0072)",
      "2 Different Text for Blue ",
      "Newborn Stem Cells"
    );
    createTrigger(
      66,
      100,
      "white",
      "3 Final Text for Yellow ",
      "Newborn Stem Cells"
    );
  }, []);

  return (
    <div className="h-[300vh] w-full" ref={parentRef}>
      <div
        className="h-screen w-full sticky top-0 flex items-center justify-center"
        ref={childRef}
      >
        <h1
          className={` ${
            text === "2 Different Text for Blue " ? "text-white" : ""
          } text-5xl font-bold text-center w-full mx-5 max-md:mx-0`}
          ref={textRef}
        >
          {text}{" "}
          <span
            className={` ${
              text === "2 Different Text for Blue "
                ? "text-white"
                : "bg-gradient-to-r from-custom-primary to-custom-secondary text-transparent bg-clip-text"
            } `}
          >
            {secondText}
          </span>
        </h1>
      </div>
    </div>
  );
};

export default NewText;
