import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import assets from "../../assets/assets";
import AccordionItem from "./AccordionSetm";
import ParentContainer from "../../components/ParentContainer";
import { Waypoint } from "react-waypoint";
import "animate.css";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const RedMed = () => {
  const [openIndex, setOpenIndex] = useState(null); // Initialize to keep the first item open by default

  const handleToggle = (index) => {
    if (openIndex !== index) {
      setOpenIndex(index);
    } else setOpenIndex(null);
  };

  const [isVisible, setIsVisible] = useState(false);

  const handleEnter = () => {
    setIsVisible(true);
  };

  const main = useRef();
  const parentRef = useRef();

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const boxes = gsap.utils.toArray(".box");
      boxes.forEach((box) => {
        gsap.fromTo(
          box,
          { x: -200, opacity: 0 },
          {
            x: 0,
            opacity: 1,

            scrollTrigger: {
              trigger: box,
              start: "bottom 100%",
              end: "top 100%",
              scrub: 1,
              //  markers: true,
            },
          }
        );
      });

      const circles = gsap.utils.toArray(".circle");
      circles.forEach((circle) => {
        gsap.fromTo(
          circle,
          { x: 500, scale: 0 },
          {
            x: 0,
            scale: 1,
            scrollTrigger: {
              trigger: circle,
              start: "bottom 80%",
              end: "top 80%",
              scrub: 1,
              // markers: true,
            },
          }
        );
      });

      const images = gsap.utils.toArray(".imgs");
      gsap.from(images, {
        opacity: 0,
        y: 50,
        stagger: 0.2,
        scrollTrigger: {
          trigger: parentRef.current,
          start: "top 70%",
          end: "bottom 70%",
          scrub: 1,
          toggleActions: "play none none none",
        },
      });
    }, main);

    return () => ctx.revert();
  }, []);
  return (
    <ParentContainer className="px-36 py-20 ">
      <div className="flex flex-wrap items-center" ref={main}>
        <div
          className={`w-2/5 box${
            isVisible ? "animate__animated animate__slideInLeft " : ""
          }`}
        >
          <img src={assets.whysave} alt="" className="w-4/5" />
        </div>
        <div
          className={`w-3/5 flex flex-col gap-5  ${
            isVisible ? "animate__animated animate__slideInRight " : ""
          }`}
        >
          <h2 className="text-5xl font-bold text-left w-full mb-2 circle">
            Regenerative{" "}
            <span className="text-custom-primary">
              Medicine <br />
            </span>
          </h2>
          <p className="text-base circle">
            As cord blood stem cell research advances, so does the potential for
            future uses. Experimental therapies that may not have seemed
            possible 20 years ago are being explored today in FDA regulated
            clinical trials. Regenerative medicine is revolutionary field of
            medicine that aims to replace, repair, and regenerate human cells,
            tissues, or organs lost or injured due to age, disease, or
            congenital defects to restore or establish normal function{" "}
          </p>
          <p className="text-base font-semibold circle">
            in areas of research within regenerative medicine:
          </p>

          <div className="grid grid-cols-2 gap-2 circle">
            <AccordionItem
              title="Cardiovascular
              "
              isOpen={openIndex === 0}
              onClick={() => handleToggle(0)}
            >
              <p>Lorem ipsum</p>
            </AccordionItem>
            <AccordionItem
              title="Autoimmune"
              isOpen={openIndex === 1}
              onClick={() => handleToggle(1)}
            >
              <p>This is the content for section 2.</p>
            </AccordionItem>
            <AccordionItem
              title="Tissue or organ damage
              "
              isOpen={openIndex === 2}
              onClick={() => handleToggle(2)}
            >
              <p>This is the content for section 3.</p>
            </AccordionItem>
          </div>
        </div>
      </div>
    </ParentContainer>
  );
};

export default RedMed;
