import React from "react";
import MainHeroSection from "../components/MainHeroSection";
import Sources from "./SourceOfStem/Sources";
import assets from "../assets/assets";
import Power from "./SourceOfStem/Power";
import TranslplantNumbers from "../components/TranslplantNumbers";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const SourcesOfStem = () => {
  const lineBreak = false;
  return (
    <div>
      <MainHeroSection
        HeadingBlack={"Sources of"}
        HeadingBreak={lineBreak}
        HeadingBlue={"Stem Cells"}
        HeadingPara={
          "Stem cells have been used in bone marrow transplants since the 1970s and today they are approved by the FDA to treat serious medical conditions."
        }
        IconOne={
          "They are currently used to treat over 80 conditions, including certain types of cancers, blood disorders, and metabolic disorders."
        }
        IconTwo={
          "Cord blood has been used in more 50,000+ stem cell transplants worldwide to help rebuild health blood and immune systems."
        }
        IconThree={
          "Estimated that 1 in 3 individuals could benefit from regenerative medicine over their lifetime"
        }
        BannerImage={assets.sourcesBanner}
        BannerText={"Preserve With Confidence"}
      />
      <Sources />
      <Power />
      <TranslplantNumbers backgroundImage={assets.TransplantNumbers} />
    </div>
  );
};

export default SourcesOfStem;
