import React, { useEffect, useState } from "react";
import ParentContainer from "../components/ParentContainer";
import FirstStep from "./Checkout/FirstStep";
import SecondStep from "./Checkout/SecondStep";
import ThirdStep from "./Checkout/ThirdStep";

const OrderNow = () => {
  const [activeClass, setActiveClass] = useState("firstStep");
  const handleClick = (item) => {
    setActiveClass(item);
    console.log("active class", item);
  };
  useEffect(() => {}, []);

  return (
    <ParentContainer
      containerHeight={"py-0"}
      containerStyle="bg-[#FBF7F5] flex justify-stretch flex-wrap"
    >
      <div className="bg-white w-full p-8">
        <h1 className="text-3xl font-bold pb-4">Order Now</h1>
        <form className="flex flex-col gap-8">
          <div
            onClick={() => handleClick("firstStep")}
            className={`flex items-center gap-2 p-8 border rounded-lg bg-[#F7F6FF] ${
              activeClass == "firstStep" ? "hidden" : ""
            }`}
          >
            <div>
              <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="18" cy="18" r="18" fill="#8884D5" />
                <path
                  d="M14.48 12.1L18.5 10.9H20.6V25H17.56V14.02L15.12 14.62L14.48 12.1Z"
                  fill="white"
                />
              </svg>
            </div>
            <p className="text-lg font-semibold">Let’s Get Started</p>
          </div>
          <div className={`${activeClass == "firstStep" ? "" : "hidden"}`}>
            <FirstStep handleClickfrom={handleClick} />
          </div>
          <div
            onClick={() => handleClick("secondStep")}
            className={`flex items-center gap-2 p-8 border rounded-lg bg-[#F7F6FF] ${
              activeClass == "secondStep" ? "hidden" : ""
            }`}
          >
            <div>
              <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="18" cy="18" r="18" fill="#8884D5" />
                <path
                  d="M18.18 13.56C17.6333 13.56 17.1467 13.7133 16.72 14.02C16.3067 14.3267 15.84 14.8 15.32 15.44L13.16 13.7C13.5067 13.2333 13.8533 12.82 14.2 12.46C14.56 12.1 14.94 11.8 15.34 11.56C15.7533 11.3067 16.2067 11.12 16.7 11C17.1933 10.8667 17.7533 10.8 18.38 10.8C19.1267 10.8 19.7933 10.9067 20.38 11.12C20.98 11.32 21.4867 11.6133 21.9 12C22.3267 12.3733 22.6467 12.8267 22.86 13.36C23.0867 13.8933 23.2 14.4867 23.2 15.14C23.2 15.7267 23.12 16.2533 22.96 16.72C22.8133 17.1733 22.5867 17.6067 22.28 18.02C21.9867 18.4333 21.6067 18.8467 21.14 19.26C20.6733 19.66 20.1333 20.1 19.52 20.58L17.24 22.34H23.34V25H12.98V22.56L17.64 18.74C18.08 18.38 18.4533 18.06 18.76 17.78C19.0667 17.4867 19.3133 17.2133 19.5 16.96C19.7 16.6933 19.84 16.44 19.92 16.2C20.0133 15.9467 20.06 15.6733 20.06 15.38C20.06 14.78 19.8867 14.3267 19.54 14.02C19.1933 13.7133 18.74 13.56 18.18 13.56Z"
                  fill="white"
                />
              </svg>
            </div>
            <p className="text-lg font-semibold">You Kit Info</p>
          </div>
          <div className={`${activeClass == "secondStep" ? "" : "hidden"}`}>
            <SecondStep handleClickfrom={handleClick} />
          </div>
          <div
            onClick={() => handleClick("thirdStep")}
            className={`flex items-center gap-2 p-8 border rounded-lg bg-[#F7F6FF] ${
              activeClass == "thirdStep" ? "hidden" : ""
            }`}
          >
            <div>
              <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="18" cy="18" r="18" fill="#8884D5" />
                <path
                  d="M19.08 13.64H13.58V11H23.04V13.32L19.5 16.7C19.98 16.78 20.4467 16.9067 20.9 17.08C21.3533 17.2533 21.7533 17.5 22.1 17.82C22.4467 18.1267 22.7267 18.5133 22.94 18.98C23.1533 19.4333 23.26 19.9867 23.26 20.64C23.26 21.3067 23.14 21.92 22.9 22.48C22.6733 23.04 22.34 23.5267 21.9 23.94C21.4733 24.3533 20.9467 24.6733 20.32 24.9C19.6933 25.1267 18.9933 25.24 18.22 25.24C16.9533 25.24 15.8733 25.02 14.98 24.58C14.1 24.1267 13.36 23.54 12.76 22.82L14.88 20.8C15.36 21.3467 15.86 21.7667 16.38 22.06C16.9133 22.34 17.54 22.48 18.26 22.48C18.8467 22.48 19.32 22.3267 19.68 22.02C20.0533 21.7133 20.24 21.2933 20.24 20.76C20.24 20.1733 20 19.7267 19.52 19.42C19.0533 19.1133 18.38 18.96 17.5 18.96H16.22L15.74 17L19.08 13.64Z"
                  fill="white"
                />
              </svg>
            </div>
            <p className="text-lg font-semibold capitalize">
              more detailed info
            </p>
          </div>
          <div className={`${activeClass == "thirdStep" ? "" : "hidden"}`}>
            <ThirdStep handleClickfrom={handleClick} />
          </div>
        </form>
      </div>
    </ParentContainer>
  );
};

export default OrderNow;
