import React, { useState } from "react";
import ParentContainer from "../../components/ParentContainer";
import assets from "../../assets/assets";
import { Link } from "react-router-dom";

const HeroSection = () => {
  const arrowIcon = (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="19"
        cy="19"
        r="19"
        transform="rotate(-90 19 19)"
        fill="url(#paint0_linear_590_9838)"
      />
      <path d="M19.9707 16.1187L22.6989 18.847L19.9707 21.5752" fill="white" />
      <path
        d="M19.9707 16.1187L22.6989 18.847L19.9707 21.5752"
        stroke="white"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.1816 18.8779L22.4741 18.8779"
        stroke="white"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_590_9838"
          x1="-3.60345"
          y1="4.22222"
          x2="44.6213"
          y2="7.62688"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8884D5" />
          <stop offset="1" stop-color="#CF0072" />
        </linearGradient>
      </defs>
    </svg>
  );

  const divMom = (
    <Link
      to="/featured-moms-single"
      className="flex flex-col gap-2 shadow-lg p-5 rounded-lg"
    >
      <div className="">
        <img src={assets.featuredMomIndex} alt="" className="rounded-lg" />
      </div>
      <h3 className="text-xl font-semibold">Mariana Miyaki</h3>
      <p className="text-base ">
        As a loving mother, Mariana Miyaki cherishes her two children, Peitro
        and Matteo....
      </p>
      {arrowIcon}
    </Link>
  );

  const itemsPerPage = 9;
  const [currentPage, setCurrentPage] = useState(1);

  const items = [
    divMom,
    divMom,
    divMom,
    divMom,
    divMom,
    divMom,
    divMom,
    divMom,
    divMom,
    divMom,
    divMom,
    divMom,
    divMom,
    divMom,
    divMom,
    divMom,
    divMom,
    divMom,
    divMom,
    divMom,
    divMom,
    divMom,
    divMom,
    divMom,
  ];

  const totalPages = Math.ceil(items.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentItems = items.slice(startIndex, startIndex + itemsPerPage);

  const goToNextPage = () =>
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  const goToPreviousPage = () =>
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  const goToPage = (page) => setCurrentPage(page);

  return (
    <div>
      <ParentContainer containerStyle={"grid grid-cols-3 gap-8"}>
        {currentItems}
      </ParentContainer>
      <ParentContainer containerStyle={"flex justify-center gap-2"}>
        {totalPages > 1 && (
          <div className="pagination-controls flex justify-center gap-2 items-center">
            <button onClick={goToPreviousPage} disabled={currentPage === 1}>
              &laquo;
            </button>
            {[...Array(totalPages)].map((_, index) => (
              <div
                className={`${
                  currentPage === index + 1
                    ? "bg-gradient-to-r from-custom-primary to-custom-secondary rounded-full   w-10 h-10 text-white relative"
                    : ""
                }`}
              >
                <a
                  href="#"
                  key={index}
                  onClick={() => goToPage(index + 1)}
                  disabled={currentPage === index + 1}
                  className={`${
                    currentPage === index + 1
                      ? " text-base absolute -translate-y-2/4 -translate-x-2/4 top-1/2 left-1/2 "
                      : ""
                  }`}
                >
                  {index + 1}
                </a>
              </div>
            ))}
            <button
              onClick={goToNextPage}
              disabled={currentPage === totalPages}
            >
              &raquo;
            </button>
          </div>
        )}
      </ParentContainer>
    </div>
  );
};

export default HeroSection;
