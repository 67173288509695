import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import assets from "../../assets/assets";
import ParentContainer from "../../components/ParentContainer";
import { Autoplay } from "swiper/modules";

const VideoOverlay = ({ isOpen, onClose, videoUrl }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div style={styles.overlay}>
      <div style={styles.videoContainer}>
        <button style={styles.closeButton} onClick={onClose}>
          ×
        </button>
        <iframe
          src={`https://www.youtube.com/embed/${videoUrl}?autoplay=1&controls=1`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={styles.iframe}
        ></iframe>
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  videoContainer: {
    position: "relative",
    width: "80%",
    maxWidth: "800px",
    aspectRatio: "16/9",
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    background: "transparent",
    border: "none",
    fontSize: "2rem",
    color: "#fff",
    cursor: "pointer",
  },
  iframe: {
    width: "100%",
    height: "100%",
  },
};

const Families = () => {
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [currentVideoUrl, setCurrentVideoUrl] = useState("");

  const openVideo = (url) => {
    setCurrentVideoUrl(url);
    setIsVideoOpen(true);
    document.body.style.overflow = "hidden"; // Disable scroll
  };

  const closeVideo = () => {
    setIsVideoOpen(false);
    document.body.style.overflow = "auto"; // Enable scroll
  };

  return (
    <ParentContainer
      containerStyle={"flex justify-center flex-wrap gap-5"}
      containerHeight={"py-40"}
    >
      <h2 className="text-5xl font-bold text-center w-1/2 mx-5 mb-10">
        From CellSave{" "}
        <span className="bg-gradient-to-r from-custom-primary to-custom-primary text-transparent bg-clip-text">
          Families
        </span>
      </h2>

      <Swiper
        spaceBetween={32}
        slidesPerView={1}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
        modules={[Autoplay]}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        speed={1000} // Transition speed in milliseconds
      >
        <SwiperSlide>
          <div className="w-full grid grid-cols-4 grid-rows-2 gap-8">
            <div className="w-full rounded-lg">
              <img
                src={assets.homeFamilies}
                alt=""
                onClick={() => openVideo("TQC6eXPtZXo")}
                style={{ cursor: "pointer" }}
                className="w-full"
              />
            </div>

            <div className="bg-black col-span-2 row-span-2 rounded-lg">
              <img
                src={assets.homeFamilies}
                alt=""
                onClick={() => openVideo("TQC6eXPtZXo")}
                style={{ cursor: "pointer" }}
                className="w-full"
              />
            </div>
            <div className="bg-[#37424A] w-full rounded-lg flex flex-col justify-center">
              <h4 className="w-full text-[#FF7F45] leading-10 text-center text-3xl font-bold bg-clip-text">
                350,000
              </h4>
              <p className="text-xl text-center text-white font-semibold">
                Families
              </p>
            </div>
            <div className="bg-[#37424A] w-full rounded-lg flex flex-col justify-center">
              <h4 className="w-full text-[#CF0072] leading-10 text-center text-3xl font-bold bg-clip-text">
                350,000
              </h4>
              <p className="text-xl text-center font-semibold text-white">
                families
              </p>
            </div>
            <div className="bg-custom-secondary w-full rounded-lg">
              <img
                src={assets.homeFamilies}
                alt=""
                onClick={() => openVideo("TQC6eXPtZXo")}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="w-full grid grid-cols-4 grid-rows-2 gap-8">
            <div className="w-full rounded-lg">
              <img
                src={assets.homeFamilies}
                alt=""
                onClick={() => openVideo("TQC6eXPtZXo")}
                style={{ cursor: "pointer" }}
              />
            </div>

            <div className="bg-black col-span-2 row-span-2 rounded-lg">
              <img
                src={assets.homeFamilies}
                alt=""
                onClick={() => openVideo("TQC6eXPtZXo")}
                className="w-full"
                style={{ cursor: "pointer" }}
              />
            </div>
            <div className="bg-[#37424A] w-full rounded-lg flex flex-col justify-center">
              <h4 className="w-full text-[#FF7F45] leading-10 text-center text-3xl font-bold bg-clip-text">
                350,000
              </h4>
              <p className="text-xl text-center text-white font-semibold">
                Families
              </p>
            </div>
            <div className="bg-[#37424A] w-full rounded-lg flex flex-col justify-center">
              <h4 className="w-full text-[#CF0072] leading-10 text-center text-3xl font-bold bg-clip-text">
                350,000
              </h4>
              <p className="text-xl text-center font-semibold text-white">
                families
              </p>
            </div>
            <div className="bg-custom-secondary w-full rounded-lg">
              <img
                src={assets.homeFamilies}
                alt=""
                onClick={() => openVideo("TQC6eXPtZXo")}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
        </SwiperSlide>
      </Swiper>

      <VideoOverlay
        isOpen={isVideoOpen}
        onClose={closeVideo}
        videoUrl={currentVideoUrl}
      />
    </ParentContainer>
  );
};

export default Families;
