import React from "react";
import ParentContainer from "../components/ParentContainer";
import ProfileSideBar from "../components/ProfileSideBar";
import HeroSection from "./ProfileHealthHistory/HeroSection";
import Attention from "./ProfileHealthHistory/Attention";
import Form from "./ProfileRefer/Form";
import Voucher from "./ProfileRefer/Voucher";

const ProfileReferrals = () => {
  return (
    <ParentContainer
      containerHeight={"py-0"}
      containerStyle="bg-[#FBF7F5] flex justify-stretch "
    >
      <div className="w-1/5 bg-white border-r">
        <ProfileSideBar />
      </div>
      <div className="w-4/5">
        <HeroSection />
        <Voucher />

        <Form />
      </div>
    </ParentContainer>
  );
};

export default ProfileReferrals;
