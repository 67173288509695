import React, { useState } from "react";
import ParentContainer from "../components/ParentContainer";
import assets from "../assets/assets";
import MainAdvantagesSection from "../components/MainAdvantagesSection";
import MainValuable from "../components/MainValuable";

const AccordionSetm = ({ title, children, isOpen, onClick }) => {
  return (
    <div className=" rounded-lg w-full">
      <button
        onClick={onClick}
        className="w-full flex justify-between items-center p-8 text-left"
      >
        <div className="flex gap-2 w-full items-center">
          {" "}
          <span className="text-lg font-medium w-11/12">{title}</span>
          <div className="w-1/12 flex justify-end">
            {isOpen ? (
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.33301 11H14.6663"
                  stroke="url(#paint0_linear_590_5766)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.24967 20.1668H13.7497C18.333 20.1668 20.1663 18.3335 20.1663 13.7502V8.25016C20.1663 3.66683 18.333 1.8335 13.7497 1.8335H8.24967C3.66634 1.8335 1.83301 3.66683 1.83301 8.25016V13.7502C1.83301 18.3335 3.66634 20.1668 8.24967 20.1668Z"
                  stroke="url(#paint1_linear_590_5766)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_590_5766"
                    x1="6.63761"
                    y1="11.1111"
                    x2="14.0135"
                    y2="14.9298"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#8884D5" />
                    <stop offset="1" stop-color="#CF0072" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_590_5766"
                    x1="0.0945021"
                    y1="3.87053"
                    x2="23.3609"
                    y2="5.51313"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#8884D5" />
                    <stop offset="1" stop-color="#CF0072" />
                  </linearGradient>
                </defs>
              </svg>
            ) : (
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.33301 11H14.6663"
                  stroke="url(#paint0_linear_590_5758)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11 14.6666V7.33325"
                  stroke="url(#paint1_linear_590_5758)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.24967 20.1666H13.7497C18.333 20.1666 20.1663 18.3333 20.1663 13.7499V8.24992C20.1663 3.66659 18.333 1.83325 13.7497 1.83325H8.24967C3.66634 1.83325 1.83301 3.66659 1.83301 8.24992V13.7499C1.83301 18.3333 3.66634 20.1666 8.24967 20.1666Z"
                  stroke="url(#paint2_linear_590_5758)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_590_5758"
                    x1="6.63761"
                    y1="11.1111"
                    x2="14.0135"
                    y2="14.9298"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#8884D5" />
                    <stop offset="1" stop-color="#CF0072" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_590_5758"
                    x1="10.9052"
                    y1="8.14807"
                    x2="12.1805"
                    y2="8.16034"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#8884D5" />
                    <stop offset="1" stop-color="#CF0072" />
                  </linearGradient>
                  <linearGradient
                    id="paint2_linear_590_5758"
                    x1="0.0945021"
                    y1="3.87029"
                    x2="23.3609"
                    y2="5.51289"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#8884D5" />
                    <stop offset="1" stop-color="#CF0072" />
                  </linearGradient>
                </defs>
              </svg>
            )}
          </div>
        </div>
      </button>
      {isOpen && <div className="px-8 py-4">{children}</div>}
    </div>
  );
};

const ProductPage = () => {
  const [openIndex, setOpenIndex] = useState(0); // Initialize to keep the first item open by default

  const handleToggle = (index) => {
    if (openIndex !== index) {
      setOpenIndex(index);
    }
  };
  const BulletIcon = (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="30"
        height="30"
        rx="15"
        fill="url(#paint0_linear_590_6015)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_590_6015"
          x1="-2.84483"
          y1="3.33333"
          x2="35.2274"
          y2="6.02122"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8884D5" />
          <stop offset="1" stop-color="#CF0072" />
        </linearGradient>
      </defs>
    </svg>
  );
  return (
    <>
      <ParentContainer
        containerHeight={"py-0"}
        containerStyle="bg-[#FBF7F5] flex justify-stretch flex-wrap"
      >
        <div className="w-full bg-white px-8 py-6 flex">
          <div className=" w-1/2">
            <div className="flex justify-stretch h-[345px]">
              <div className="flex flex-col flex-wrap justify-between  w-1/4 h-full overflow-hidden">
                <div className="w-full h-1/5 flex items-center justify-center overflow-hidden ">
                  <img
                    className="max-w-full max-h-full object-contain"
                    src={assets.ProductImageOne}
                    alt=""
                  />
                </div>
                <div className="w-full h-1/5 flex items-center justify-center overflow-hidden ">
                  <img
                    className="max-w-full max-h-full object-contain"
                    src={assets.ProductImageOne}
                    alt=""
                  />
                </div>
                <div className="w-full h-1/5 flex items-center justify-center overflow-hidden ">
                  <img
                    className="max-w-full max-h-full object-contain"
                    src={assets.ProductImageOne}
                    alt=""
                  />
                </div>
                <div className="w-full h-1/5 flex items-center justify-center overflow-hidden ">
                  <img
                    className="max-w-full max-h-full object-contain"
                    src={assets.ProductImageOne}
                    alt=""
                  />
                </div>
              </div>
              <div className="w-3/4 h-full overflow-hidden flex items-center justify-center">
                <img
                  className="max-w-full max-h-full object-contain"
                  src={assets.ProductImageOne}
                  alt=""
                />
              </div>
            </div>

            <div className="bg-[#F7F6FF] flex mt-4 items-center justify-between py-4 px-4 rounded-lg">
              <div className="flex items-center gap-2">
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 55 55"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M30.077 30.01H23.917V27.09H30.077V30.01Z"
                    fill="#37424A"
                  />
                  <circle cx="27.5" cy="27.5" r="27" stroke="#37424A" />
                </svg>

                <p className="font-bold text-xl ">1</p>
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 55 55"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M25.605 26.33V22.73H28.445V26.33H32.065V29.09H28.445V32.69H25.605V29.09H21.985V26.33H25.605Z"
                    fill="#37424A"
                  />
                  <circle cx="27.5" cy="27.5" r="27" stroke="#37424A" />
                </svg>
              </div>
              <p className="font-bold text-xl ">Total: 37,500.00</p>
            </div>
            <button className=" bg-gradient-to-r from-custom-primary to-custom-secondary hover:scale-110 transition-all text-white text-base px-8 py-2 rounded-3xl mt-6">
              Get Started
            </button>
          </div>
          <div className="w-1/2 pl-6">
            <div className=" border-b pb-6">
              <h1 className="text-xl font-bold">CryoInfinite </h1>
              <p className="text-base">Lorem ipsum dolor sit</p>
            </div>
            <div className=" border-b pb-6">
              <h3 className="text-xl font-bold text-custom-primary pt-4">
                AED37,500.00
              </h3>
              <p className="text-base">
                Lorem ipsum dolor sit amet consectetur. Ultricies magna at
                faucibus id nibh risus auctor. Eu massa amet accumsan
                consectetur odio pellentesque. Vitae et tortor vitae id blandit
                pellentesque arcu. Et nunc et at purus quisque ullamcorper. Dui
                luctus quis mollis lacus sed erat. Integer etiam vivamus
                senectus elementum eget sed a leo. Lectus est.
              </p>
            </div>
            <div className=" border-b pb-6">
              <h3 className="text-base font-bold  pt-4">
                This package includes:
              </h3>
              <div className="text-base">
                <ul className="list-disc pl-5">
                  <li>
                    Lorem ipsum dolor sit amet consectetur, ultricies magna at
                  </li>
                  <li>
                    Lorem ipsum dolor sit amet consectetur, ultricies magna at
                  </li>
                  <li>
                    Lorem ipsum dolor sit amet consectetur, ultricies magna at
                  </li>
                  <li>
                    Lorem ipsum dolor sit amet consectetur, ultricies magna at
                  </li>
                  <li>
                    Lorem ipsum dolor sit amet consectetur, ultricies magna at
                  </li>
                </ul>
              </div>
            </div>
            <div className=" border-b py-6 flex ">
              <div className="w-1/3 flex items-center">
                <div className="pr-1">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.1875 20.625V23.25"
                      stroke="#8884D5"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.93774 27.5H21.4377V26.25C21.4377 24.875 20.3127 23.75 18.9377 23.75H11.4377C10.0627 23.75 8.93774 24.875 8.93774 26.25V27.5V27.5Z"
                      stroke="#8884D5"
                      stroke-width="2"
                      stroke-miterlimit="10"
                    />
                    <path
                      d="M7.6875 27.5H22.6875"
                      stroke="#8884D5"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14.9998 20C10.1623 20 6.24976 16.0875 6.24976 11.25V7.5C6.24976 4.7375 8.48726 2.5 11.2498 2.5H18.7498C21.5123 2.5 23.7498 4.7375 23.7498 7.5V11.25C23.7498 16.0875 19.8373 20 14.9998 20Z"
                      stroke="#8884D5"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.83852 14.5625C5.90102 14.2625 5.07603 13.7125 4.42603 13.0625C3.30103 11.8125 2.55103 10.3125 2.55103 8.5625C2.55103 6.8125 3.92603 5.4375 5.67603 5.4375H6.48853C6.23853 6.0125 6.11352 6.65 6.11352 7.3125V11.0625C6.11352 12.3125 6.37602 13.4875 6.83852 14.5625Z"
                      stroke="#8884D5"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M23.1621 14.5625C24.0996 14.2625 24.9246 13.7125 25.5746 13.0625C26.6996 11.8125 27.4496 10.3125 27.4496 8.5625C27.4496 6.8125 26.0746 5.4375 24.3246 5.4375H23.5121C23.7621 6.0125 23.8871 6.65 23.8871 7.3125V11.0625C23.8871 12.3125 23.6246 13.4875 23.1621 14.5625Z"
                      stroke="#8884D5"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <p className="text-base text-custom-primary font-semibold">
                  Free shipping
                </p>
              </div>
              <div className="w-1/3 flex items-center">
                <div className="pr-1">
                  <svg
                    width="31"
                    height="30"
                    viewBox="0 0 31 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.4987 17.5H16.7487C18.1237 17.5 19.2487 16.375 19.2487 15V2.5H7.99872C6.12372 2.5 4.48623 3.53748 3.63623 5.06248"
                      stroke="#8884D5"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3.00049 21.25C3.00049 23.325 4.67549 25 6.75049 25H8.00049C8.00049 23.625 9.12549 22.5 10.5005 22.5C11.8755 22.5 13.0005 23.625 13.0005 25H18.0005C18.0005 23.625 19.1255 22.5 20.5005 22.5C21.8755 22.5 23.0005 23.625 23.0005 25H24.2505C26.3255 25 28.0005 23.325 28.0005 21.25V17.5H24.2505C23.563 17.5 23.0005 16.9375 23.0005 16.25V12.5C23.0005 11.8125 23.563 11.25 24.2505 11.25H25.863L23.7255 7.51251C23.2755 6.73751 22.4505 6.25 21.5505 6.25H19.2505V15C19.2505 16.375 18.1255 17.5 16.7505 17.5H15.5005"
                      stroke="#8884D5"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.5 27.5C11.8807 27.5 13 26.3807 13 25C13 23.6193 11.8807 22.5 10.5 22.5C9.11929 22.5 8 23.6193 8 25C8 26.3807 9.11929 27.5 10.5 27.5Z"
                      stroke="#8884D5"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M20.5005 27.5C21.8812 27.5 23.0005 26.3807 23.0005 25C23.0005 23.6193 21.8812 22.5 20.5005 22.5C19.1198 22.5 18.0005 23.6193 18.0005 25C18.0005 26.3807 19.1198 27.5 20.5005 27.5Z"
                      stroke="#8884D5"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M28 15V17.5H24.25C23.5625 17.5 23 16.9375 23 16.25V12.5C23 11.8125 23.5625 11.25 24.25 11.25H25.8625L28 15Z"
                      stroke="#8884D5"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3.00049 10H10.5005"
                      stroke="#8884D5"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3.00049 13.75H8.00049"
                      stroke="#8884D5"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3.00049 17.5H5.50049"
                      stroke="#8884D5"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <p className="text-base text-custom-primary font-semibold">
                  CLIA-certified lab
                </p>
              </div>
              <div className="w-1/3 flex items-center">
                <div className="pr-1">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.55 16.9375C22.025 17.45 21.725 18.1875 21.8 18.975C21.9125 20.325 23.15 21.3125 24.5 21.3125H26.875V22.8C26.875 25.3875 24.7625 27.5 22.175 27.5H7.825C5.2375 27.5 3.125 25.3875 3.125 22.8V14.3875C3.125 11.8 5.2375 9.6875 7.825 9.6875H22.175C24.7625 9.6875 26.875 11.8 26.875 14.3875V16.1875H24.35C23.65 16.1875 23.0125 16.4625 22.55 16.9375Z"
                      stroke="#8884D5"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3.125 15.5121V9.7997C3.125 8.3122 4.0375 6.98715 5.425 6.46215L15.35 2.71215C16.9 2.12465 18.5625 3.27468 18.5625 4.93718V9.68717"
                      stroke="#8884D5"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M28.1985 17.4624V20.0375C28.1985 20.725 27.6485 21.2875 26.9485 21.3125H24.4985C23.1485 21.3125 21.911 20.325 21.7985 18.975C21.7235 18.1875 22.0235 17.45 22.5485 16.9375C23.011 16.4625 23.6485 16.1875 24.3485 16.1875H26.9485C27.6485 16.2125 28.1985 16.7749 28.1985 17.4624Z"
                      stroke="#8884D5"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.75 15H17.5"
                      stroke="#8884D5"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <p className="text-base text-custom-primary font-semibold">
                  AABB accredited lab
                </p>
              </div>
            </div>
            <div className=" border-b pb-6">
              <h3 className="text-base font-bold  pt-4">Add-ons:</h3>
              <div className="text-base flex items-center gap-2">
                <input type="checkbox" name=" " id="" />
                <label htmlFor="">Chamber Storage Bag</label>
              </div>
              <div className="text-base flex items-center gap-2">
                <input type="checkbox" name=" " id="" />
                <label htmlFor="">Chamber Storage Bag</label>
              </div>
            </div>
            <div className=" border-b pb-6">
              <h3 className="text-base font-bold  pt-4">
                Additional information
              </h3>
              <div className="text-base">
                <ul className="list-disc pl-5">
                  <li>Enrollment: 2,000 AED</li>
                  <li>Processing Fee: 2,000 AED</li>
                  <li>Storage fee: 2,000 AED</li>
                  <li>Total: 37500</li>
                  <li>Processing Discount: 1,973 AED</li>
                  <li>Storage Discount: 5,921 AED</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full bg-white">
          <div className=" bg-gradient-to-r from-custom-primary to-custom-secondary rounded-lg justify-between  mx-8 flex items-baseline py-8">
            <div className="w-1/5 flex flex-wrap justify-center gap-1">
              <h4 className="w-full leading-10 text-center text-2xl font-bold  text-white">
                20+
              </h4>
              <p className="w-4/5 text-center text-lg font-semibold text-white  ">
                years of business
              </p>
            </div>
            <div className="w-1/5 flex flex-wrap justify-center gap-1">
              <h4 className="w-full leading-10 text-center text-2xl font-bold  text-white">
                750,000+
              </h4>
              <p className="w-5/5 text-center text-lg font-semibold text-white  ">
                units collected powered by CSG.BIO
              </p>
            </div>
            <div className="w-1/5 flex flex-wrap justify-center gap-1">
              <h4 className="w-full leading-10 text-center text-2xl font-bold  text-white">
                100%
              </h4>
              <p className="w-4/5 text-center text-lg font-semibold text-white  ">
                sample success rate
              </p>
            </div>
            <div className="w-1/5 flex flex-wrap justify-center gap-1">
              <h4 className="w-full leading-10 text-center text-2xl font-bold  text-white">
                $85,000
              </h4>
              <p className="w-4/5 text-center text-lg font-semibold text-white  ">
                quality guarantee
              </p>
            </div>
          </div>
        </div>

        <div className="bg-white px-8 py-20 flex flex-wrap w-full">
          <div className="w-1/2 sticky top-0 pr-10">
            <h2 className="text-3xl font-bold text-left pb-4">
              The incredible powers{" "}
              <span className="text-custom-primary">of stem cells</span>
            </h2>
            <p className=" text-base">
              Lorem ipsum dolor sit amet consectetur. Dui ridiculus tristique
              blandit venenatis. Sit sed volutpat
            </p>
          </div>
          <div className="w-1/2">
            <div className="p-5 pb-6 flex items-center border-b border-[#EAECF0]">
              <div id="icon" className="w-1/12">
                {BulletIcon}
              </div>
              <p className="text-lg font-semibold w-11/12 ">
                Stem Cells are Nature’s Bio-Repair Kit
              </p>
            </div>
            <div className="p-5 pb-6 flex items-center border-b border-[#EAECF0]">
              <div id="icon" className="w-1/12">
                {BulletIcon}
              </div>
              <p className="text-lg font-semibold w-11/12 ">
                Over 30 Years of Lifesaving Miracles
              </p>
            </div>
            <div className="p-5 pb-6 flex items-center border-b border-[#EAECF0]">
              <div id="icon" className="w-1/12">
                {BulletIcon}
              </div>
              <p className="text-lg font-semibold w-11/12 ">
                A Lifelong Resource for your entire Family
              </p>
            </div>
            <div className="p-5 pb-6 flex items-center border-b border-[#EAECF0]">
              <div id="icon" className="w-1/12">
                {BulletIcon}
              </div>
              <p className="text-lg font-semibold w-11/12 ">
                80 Conditions Treated Today
              </p>
            </div>
            <div className="p-5 pb-6 flex items-center border-b border-[#EAECF0]">
              <div id="icon" className="w-1/12">
                {BulletIcon}
              </div>
              <p className="text-lg font-semibold w-11/12 ">
                A Bright Future for Regenerative Medicine
              </p>
            </div>
            <div className="p-5 pb-6 flex items-center border-b border-[#EAECF0]">
              <div id="icon" className="w-1/12">
                {BulletIcon}
              </div>
              <p className="text-lg font-semibold w-11/12 ">
                Perfect genetic match for your baby
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white p-8">
          <h2 className="text-4xl  font-bold pb-5">
            What to <span className=" text-custom-primary ">Expect</span>
          </h2>

          <div className="flex flex-wrap py-10 ">
            <div className="w-1/2  border-l flex flex-col gap-5 ">
              <div className="mr-5">
                <div class="flex items-center relative group hover:bg-gradient-to-r from-custom-primary to-custom-secondary rounded-lg w-full ml-1">
                  <div class="absolute -left-[4px] h-4/5 border-l-4 border-current hidden group-hover:block"></div>
                  <div class="p-7 group-hover:text-white font-semibold pl-10 w-full">
                    Order your kit delivery.
                    <p className="text-base font-normal">
                      Your kit will ship out same day if it is ordered before
                      2pm PST on a Your kit will ship out same day if it is
                      ordered before 2pm PST on a ordered during the weekend.
                    </p>
                  </div>
                </div>
                <div class="flex items-center relative group hover:bg-gradient-to-r from-custom-primary to-custom-secondary rounded-lg w-full ml-1">
                  <div class="absolute -left-[4px] h-4/5 border-l-4 border-current hidden group-hover:block"></div>
                  <div class="p-7 group-hover:text-white font-semibold pl-10 w-full">
                    Bring your kit to the hospital.
                    <p className="text-base font-normal">
                      Keep your kit by your hospital bag. Bring it to the
                      hospital or your birth location for the provider to
                      collect your stem cells.
                    </p>
                  </div>
                </div>
                <div class="flex items-center relative group hover:bg-gradient-to-r from-custom-primary to-custom-secondary rounded-lg w-full ml-1">
                  <div class="absolute -left-[4px] h-4/5 border-l-4 border-current hidden group-hover:block"></div>
                  <div class="p-7 group-hover:text-white font-semibold pl-10 w-full">
                    Return your kit.
                    <p className="text-base font-normal">
                      Follow the shipping instructions and the kit will be sent
                      to our laboratory for testing, processing, and storage.
                    </p>
                  </div>
                </div>
                <div class="flex items-center relative group hover:bg-gradient-to-r from-custom-primary to-custom-secondary rounded-lg w-full ml-1">
                  <div class="absolute -left-[4px] h-4/5 border-l-4 border-current hidden group-hover:block"></div>
                  <div class="p-7 group-hover:text-white font-semibold pl-10 w-full">
                    Safe, protected long-term storage.
                    <p className="text-base font-normal">
                      You’ll have peace of mind knowing that your baby’s stem
                      cells will be available should your family ever need to
                      use them.
                    </p>
                  </div>
                </div>
                <div class="flex items-center relative group hover:bg-gradient-to-r from-custom-primary to-custom-secondary rounded-lg w-full ml-1">
                  <div class="absolute -left-[4px] h-4/5 border-l-4 border-current hidden group-hover:block"></div>
                  <div class="p-7 group-hover:text-white font-semibold pl-10 w-full">
                    Sample release.
                    <p className="text-base font-normal">
                      If you need to release your baby’s stem cells in the
                      future, simply contact us and we’ll work with your medical
                      provider to get them to you.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-1/2">
              <div class=" h-full bg-white">
                <iframe
                  src="https://wallycle.sirv.com/Cell%20Save%2002/Cell%20Save%2002.spin%0A#sirv-viewer-1329217373685"
                  class="w-full h-full border-0"
                ></iframe>
              </div>
            </div>
          </div>
        </div>

        <div className="p-8 flex flex-wrap justify-center bg-white w-full">
          <h3 className=" text-4xl w-1/2  font-semibold  pb-10  capitalize">
            Frequently Asked Question
          </h3>
          <div className="flex w-1/2 gap-5 justify-end pb-10">
            <div className=" border-black border text-black flex rounded-3xl py-1 px-4 font-semibold">
              <p className="text-base self-center">All FAQs</p>
            </div>
            <svg
              width="39"
              height="38"
              viewBox="0 0 39 38"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="19.5"
                cy="19"
                r="19"
                transform="rotate(-90 19.5 19)"
                fill="url(#paint0_linear_590_3775)"
              />
              <path
                d="M20.4707 16.1187L23.1989 18.847L20.4707 21.5752"
                fill="white"
              />
              <path
                d="M20.4707 16.1187L23.1989 18.847L20.4707 21.5752"
                stroke="white"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.6816 18.8779L22.9741 18.8779"
                stroke="white"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_590_3775"
                  x1="-3.10345"
                  y1="4.22222"
                  x2="45.1213"
                  y2="7.62688"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#8884D5" />
                  <stop offset="1" stop-color="#CF0072" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div className="flex flex-col w-full border border-[#EAECF0] rounded-lg ">
            <AccordionSetm
              title="What are stem cells? "
              isOpen={openIndex === 0}
              onClick={() => handleToggle(0)}
            >
              <p>
                Stem cells are the body’s “master cells”. They are the building
                blocks of all organs, tissues, blood, and the immune system. In
                many tissues they serve as an internal repair system,
                regenerating to replace lost or damaged cells for the life of
                the person. Stem cells are considered the building blocks our
                body uses everyday in tissue repair and normal cell
                replenishment. Doctors were initially using stem cells derived
                from the bone marrow in patients who had received chemotherapy
                for cancer, to regenerate their blood and immune cells. In the
                late 1980s, they started using cord blood stem cells to treat
                diseases that had previously been treated with bone marrow
                transplantation. Stem cells are today successfully being used to
                save lives. They also are being researched in an revolutionary
                new area of medicine called regenerative medicine, where
                scientists are studying the use of stem cells in experimental
                treatments for conditions like brain injury, diabetes and
                acquired hearing loss.
              </p>
            </AccordionSetm>
            <AccordionSetm
              title="What is cord blood?"
              isOpen={openIndex === 1}
              onClick={() => handleToggle(1)}
            >
              <p>This is the content for section 2.</p>
            </AccordionSetm>
            <AccordionSetm
              title="What is cord tissue?
     "
              isOpen={openIndex === 2}
              onClick={() => handleToggle(2)}
            >
              <p>This is the content for section 3.</p>
            </AccordionSetm>
          </div>
        </div>
      </ParentContainer>
    </>
  );
};

export default ProductPage;
