import React from "react";
import ParentContainer from "../../components/ParentContainer";
import assets from "../../assets/assets";

const BlogMainSection = () => {
  return (
    <ParentContainer>
      <h1 className=" text-3xl text-custom-primary font-bold">
        10 Pregnancy Essentials: A Vital Checklist for Expecting Parents
      </h1>
      <div className="pt-5 flex items-center gap-10 pb-10">
        <p className="text-base bg-custom-primary py-2 px-3 text-white font-semibold rounded-lg text-center inline ">
          Pregnancy
        </p>
        <div className="flex gap-4">
          <a href="">
            <svg
              width="23"
              height="22"
              viewBox="0 0 23 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.97331 2.75C5.73482 2.74982 5.49862 2.79663 5.27822 2.88775C5.05781 2.97887 4.85752 3.11253 4.68878 3.28107C4.52004 3.44962 4.38616 3.64976 4.29479 3.87006C4.20342 4.09036 4.15634 4.3265 4.15625 4.565C4.15625 5.56875 4.96956 6.40063 5.97125 6.40063C6.97431 6.40063 7.78831 5.56875 7.78831 4.56638C7.78849 4.32791 7.74168 4.09175 7.65055 3.87138C7.55942 3.65102 7.42575 3.45077 7.2572 3.28209C7.08864 3.1134 6.8885 2.97959 6.6682 2.88829C6.44791 2.79699 6.21178 2.75 5.97331 2.75ZM14.8366 7.5625C13.311 7.5625 12.4386 8.36 12.0192 9.15338H11.9752V7.77631H8.96875V17.875H12.1017V12.8762C12.1017 11.5589 12.2007 10.2857 13.8308 10.2857C15.4374 10.2857 15.4608 11.7879 15.4608 12.9594V17.875H18.5938V12.3282C18.5938 9.614 18.0101 7.5625 14.8366 7.5625ZM4.40581 7.77563V17.875H7.54081V7.77563H4.40581Z"
                fill="#8884D5"
              />
            </svg>
          </a>
          <a href="">
            <svg
              width="19"
              height="18"
              viewBox="0 0 19 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.2744 10.125L13.7743 6.86742H10.6486V4.75348C10.6486 3.86227 11.0852 2.99355 12.4852 2.99355H13.9062V0.220078C13.9062 0.220078 12.6166 0 11.3837 0C8.80957 0 7.12699 1.56023 7.12699 4.38469V6.86742H4.26562V10.125H7.12699V18H10.6486V10.125H13.2744Z"
                fill="#8884D5"
              />
            </svg>
          </a>
          <a href="">
            <svg
              width="23"
              height="22"
              viewBox="0 0 23 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.0749 3.14258H7.58285C5.17209 3.14258 3.21777 5.09689 3.21777 7.50766V14.4918C3.21777 16.9025 5.17209 18.8569 7.58285 18.8569H14.567C16.9777 18.8569 18.9321 16.9025 18.9321 14.4918V7.50766C18.9321 5.09689 16.9777 3.14258 14.567 3.14258H11.0749Z"
                stroke="#8884D5"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.0741 7.50781C13.0027 7.50781 14.5662 9.07126 14.5662 10.9999C14.5662 12.9285 13.0027 14.4919 11.0741 14.4919C9.14548 14.4919 7.58203 12.9285 7.58203 10.9999C7.58203 9.07126 9.14548 7.50781 11.0741 7.50781Z"
                stroke="#8884D5"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M15.6123 7.79199C16.3717 7.79199 16.9873 7.17638 16.9873 6.41699C16.9873 5.6576 16.3717 5.04199 15.6123 5.04199C14.8529 5.04199 14.2373 5.6576 14.2373 6.41699C14.2373 7.17638 14.8529 7.79199 15.6123 7.79199Z"
                fill="#8884D5"
              />
            </svg>
          </a>
          <a href="">
            <svg
              width="23"
              height="22"
              viewBox="0 0 23 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.0278 7.03224C18.0398 7.19266 18.0398 7.35216 18.0398 7.51166C18.0398 12.3929 14.3245 18.0176 7.53476 18.0176C5.44292 18.0176 3.49959 17.4117 1.86426 16.3593C2.16126 16.3932 2.44726 16.4052 2.75617 16.4052C4.41808 16.4092 6.03293 15.8535 7.34042 14.8276C6.56989 14.8136 5.82295 14.5594 5.20389 14.1004C4.58483 13.6414 4.12456 13.0006 3.88734 12.2673C4.11559 12.3012 4.34476 12.3242 4.58492 12.3242C4.91584 12.3242 5.24859 12.2783 5.55751 12.1986C4.72129 12.0297 3.96936 11.5765 3.42957 10.9159C2.88979 10.2553 2.59548 9.42809 2.59667 8.57499V8.52916C3.08892 8.80324 3.66001 8.97466 4.26501 8.99758C3.75817 8.66078 3.34258 8.20376 3.05532 7.6673C2.76805 7.13084 2.61804 6.53161 2.61867 5.92308C2.61867 5.23741 2.80109 4.60858 3.12101 4.06041C4.0488 5.20168 5.20597 6.13534 6.51757 6.80093C7.82916 7.46652 9.26594 7.84919 10.7348 7.92416C10.678 7.64916 10.6432 7.36408 10.6432 7.07808C10.6429 6.59312 10.7383 6.11288 10.9237 5.6648C11.1092 5.21671 11.3812 4.80958 11.7241 4.46666C12.067 4.12375 12.4741 3.85179 12.9222 3.66631C13.3703 3.48084 13.8506 3.3855 14.3355 3.38574C15.3988 3.38574 16.3586 3.83124 17.0333 4.55174C17.8599 4.39189 18.6526 4.09013 19.3763 3.65983C19.1007 4.51307 18.5235 5.23659 17.7528 5.69483C18.486 5.61121 19.2025 5.41841 19.8786 5.12283C19.3736 5.859 18.7479 6.50456 18.0278 7.03224Z"
                fill="#8884D5"
              />
            </svg>
          </a>
        </div>
      </div>
      <img
        src={assets.blogsinglebanner}
        alt=""
        className="h-[450px] w-full object-cover rounded-lg pb-10"
      />
      <p className="text-base ">
        Pregnancy, a remarkable time filled with anticipation, excitement, and a
        touch of nervousness. As you prepare to welcome your little bundle of
        joy into the world, there’s a whirlwind of tasks to tackle and decisions
        to make. To help ease the overwhelm, we’ve created a comprehensive
        checklist of 10 pregnancy essentials that every expecting parent should
        know. And nestled within this list is a vital component for your baby’s
        future health and well-being:  Prenatal Vitamins: From the moment you
        see those two pink lines, it’s essential to start nourishing your body
        and your growing baby with the right blend of vitamins and minerals.
        Prenatal vitamins provide crucial nutrients like folic acid, iron, and
        calcium, supporting your baby’s development and reducing the risk of
        birth defects. Healthy Diet: As the saying goes,’you’re eating for two
        now’ Embrace a balanced diet rich in fruits, vegetables, lean proteins,
        and whole grains to fuel your body and support your baby’s growth. And
        don’t forget to stay hydrated—it’s vital for both you and your little
        one. Regular Prenatal Checkups: Your OB/GYN appointments are more than
        just routine checkups—they’re an opportunity to monitor your baby’s
        progress, address any concerns, and ensure a healthy pregnancy. Be sure
        to attend all scheduled visits and communicate openly with your
        healthcare provider. Exercise: While pregnancy may limit some of your
        physical activities, staying active is still important for your overall
        health and well-being. Gentle exercises like walking, swimming, and
        prenatal yoga can help alleviate discomfort, improve circulation, and
        prepare your body for childbirth. Birth Plan: Whether you envision a
        natural birth or have specific preferences for pain management and
        interventions, it’s essential to create a birth plan that reflects your
        desires and priorities. Discuss your options with your healthcare
        provider and advocate for the birthing experience you envision. Baby
        Registry: As you prepare to welcome your little one, creating a baby
        registry can help ensure you have all the essentials on hand when they
        arrive. From diapers and onesies to cribs and car seats, take stock of
        what you’ll need and let friends and family lend a helping hand.
        Childbirth Education Classes: Knowledge is power, especially when it
        comes to childbirth and newborn care. Consider enrolling in childbirth
        education classes to learn about labor stages, pain management
        techniques, breastfeeding, and infant CPR. Empower yourself with the
        information you need to navigate the journey ahead confidently. Hospital
        Bag: As your due date approaches, it’s time to pack your hospital bag
        with essentials for labor, delivery, and postpartum recovery. Include
        comfortable clothing, toiletries, snacks, and any items that will bring
        you comfort during your hospital stay. Support System: Pregnancy and
        childbirth can be an emotional rollercoaster, so lean on your support
        system for guidance, encouragement, and reassurance. Whether it’s your
        partner, family members, or close friends, surround yourself with people
        who lift you up and provide the love and support you need. Newborn Stem
        Cell Preservation: Amidst the excitement of preparing for your baby’s
        arrival, don’t overlook the opportunity to secure their future health
        with newborn stem cell preservation. Cord blood and tissue contain
        valuable stem cells with the potential to treat a variety of diseases
        and conditions, from leukemia to cerebral palsy. By banking your baby’s
        stem cells with CellSave, you’re investing in their future health and
        well-being, providing a lifeline for potential medical treatments and
        regenerative therapies. As you gear up for the arrival of your newest
        family member, it’s crucial to ensure you’re equipped with everything
        you need. From monitoring your baby’s health to nurturing your own
        well-being, each of the ten essentials outlined here plays a vital role
        in preparing for parenthood. Remember, whether it’s creating a birth
        plan or packing your hospital bag, thorough preparation can alleviate
        stress and make the transition smoother. As you embrace these
        essentials, you’re laying the foundation for a strong start on your
        journey into parenthood. Contact one of our specialists today to learn
        more about newborn stem cell preservation with CellSave. Together, let’s
        pave the way for a healthier and brighter future for your precious
        little one.   Disclaimer: It’s important to consult with your healthcare
        provider regarding any medical decisions, including newborn stem cell
        preservation, to determine what is best for you and your baby’s health.
      </p>
    </ParentContainer>
  );
};

export default BlogMainSection;
