import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import assets from "../../assets/assets";
import { Waypoint } from "react-waypoint";
import "animate.css";
import ParentContainer from "../../components/ParentContainer";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const MasterStem = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleEnter = () => {
    setIsVisible(true);
  };

  const main = useRef();
  const parentRef = useRef();

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const boxes = gsap.utils.toArray(".box");
      boxes.forEach((box) => {
        gsap.fromTo(
          box,
          { x: -200, opacity: 0 },
          {
            x: 0,
            opacity: 1,

            scrollTrigger: {
              trigger: box,
              start: "bottom 100%",
              end: "top 100%",
              scrub: 1,
              //  markers: true,
            },
          }
        );
      });

      const circles = gsap.utils.toArray(".circle");
      circles.forEach((circle) => {
        gsap.fromTo(
          circle,
          { x: 500, scale: 0 },
          {
            x: 0,
            scale: 1,
            scrollTrigger: {
              trigger: circle,
              start: "bottom 80%",
              end: "top 80%",
              scrub: 1,
              // markers: true,
            },
          }
        );
      });

      const images = gsap.utils.toArray(".imgs");
      gsap.from(images, {
        opacity: 0,
        y: 50,
        stagger: 0.2,
        scrollTrigger: {
          trigger: parentRef.current,
          start: "top 70%",
          end: "bottom 70%",
          scrub: 1,
          toggleActions: "play none none none",
        },
      });
    }, main);

    return () => ctx.revert();
  }, []);

  return (
    <div ref={main}>
      <ParentContainer containerStyle="  bg-white">
        <div className="flex justify-center flex-wrap py-10 pb-14 items-center box">
          <h2 className="text-5xl font-bold text-left w-full">
            Your Family's{" "}
            <span className="text-custom-primary">
              Master Stem Cell Bank <br />
            </span>
          </h2>
        </div>
        <div className="flex flex-wrap ">
          <div
            className={`w-3/5 flex flex-wrap justify-stretch  gap-5 ${
              isVisible ? "animate__animated animate__fadeInLeft" : ""
            }`}
          >
            <div className="bg-[#F7F6FF] p-4 rounded-lg w-full flex items-center box">
              <p className="text-base">
                CellSave Newborn Stem Cell Banking is our proprietary method of
                banking your child’s pure mesenchymal stem cells (MSCs) for use
                in the future. We start by extracting MSCs from the perinatal
                tissues and then growing them to create a Master Stem Cell Bank
                for your child and family.
              </p>
            </div>
            <div className="bg-[#F7F6FF] p-4 rounded-lg w-full flex items-center box">
              <p className="text-base">
                This Master Stem Cell Bank is designed to generate practically
                unlimited doses of your newborn’s own MSCs for use in the future
                and is capable of providing a lifetime supply of therapeutic
                doses.
              </p>
            </div>
          </div>
          <div
            className={`w-2/5 flex justify-end ${
              isVisible ? "animate__animated animate__fadeInRight" : ""
            }`}
          >
            <img src={assets.power} alt="" className="w-4/5 circle" />
          </div>
        </div>
      </ParentContainer>
    </div>
  );
};

export default MasterStem;
