import React from "react";
import MainHeroSection from "../components/MainHeroSection";
import assets from "../assets/assets";
import MainAdvantagesSection from "../components/MainAdvantagesSection";
import MainValuable from "../components/MainValuable";
import TranslplantNumbers from "../components/TranslplantNumbers";
import LifeTimeBanner from "../components/LifeTimeBanner";

const CordTissue = () => {
  const lineBreak = false;
  const AdvantagePara = true;
  return (
    <div>
      <MainHeroSection
        HeadingBlack={"What is"}
        HeadingBreak={lineBreak}
        HeadingBlue={"Cord Tissue?"}
        HeadingPara={
          "Cord tissue refers to the tissue inside the umbilical cord. Wharton’s Jelly, is a substance found within the umbilical cord and is an abundant source of valuable stem cells. It contains powerful and unique stem cells, including endothelial cells, epithelial cells and mesenchymal stem cells, which have the potential to repair and heal the body in different ways than cord blood stem cells do."
        }
        q
        IconOne={
          "The study of mesenchymal stem cells falls under the category of regenerative medicine."
        }
        IconTwo={
          "As these cord tissue stem cells assist the body in repairing itself. "
        }
        IconThree={
          "As science and modern medicine continue to advance, so do the possibilities for future treatment possibilities."
        }
        BannerImage={assets.cordTissueBannerer}
        BannerText={"The Future of Regenerative Medicine"}
      />
      <MainAdvantagesSection
        HeadingBlack={"Cord Tissue"}
        HeadingBlue={"Advantages"}
        TwoImages={false}
        Image={assets.cordTissueAdvantage}
        isPara={AdvantagePara}
        Para={
          "Mesenchymal stem cells have the potential to evolve into many types of cells, including organ, muscle, skin, bone, cartilage, and fat cells. They have the potential to reduce inflammation, treat wounds, burns, and vascular damage. They also have the potential to modulate the immune system to help improve autoimmune disorders. Cord tissue is currently being investigated for the the treatment of the following condition categories:"
        }
        Advantages={[
          "Skeletal diseases and injuries",
          "Spinal cord injuries",
          "Inflammatory diseases",
          "Certain types of cancer",
          "Heart diseases",
        ]}
      />
      <MainValuable
        HeadingBlack={"Why Is It So "}
        HeadingBlue={"Valuable?"}
        HeadingPara={
          "Clinical trials have shown cord tissue to be more useful than cord blood in certain therapies. "
        }
        isButton={true}
        ValueList={[
          "The stem cells found in cord tissue offer great promise in regenerative medicine and connective tissue disorders. ",
          "They can also reduce harmful immune processes, possess anti-inflammatory potential ",
          "Produce a range of proteins that can promote healing of damaged tissues at the site of injury.",
        ]}
      />
      <TranslplantNumbers backgroundImage={assets.TransplantNumbers} />
      <LifeTimeBanner />
    </div>
  );
};

export default CordTissue;
