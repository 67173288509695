import React, { useState } from "react";
import { Link } from "react-router-dom";
import assets from "../../assets/assets";

const SecondStep = ({ handleClickfrom }) => {
  const handleChange = (item) => {
    handleClickfrom(item);
  };
  const options = [
    { name: "CryoAdvanced", link: "/product/page" },
    { name: "CryoUltimate", link: "/product/page" },
    { name: "CryoInfinite", link: "/product/page" },
  ];

  const payments = [
    { name: "Full-Payment" },
    { name: "3-Months-Financing" },
    { name: "6-Months-Financing" },
    { name: "9-Months-Financing" },
    { name: "12-Months-Financing" },
  ];

  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    console.log("Selected Option:", option);
  };

  const [selectedPayment, setSelectedPayment] = useState(null);
  const handlePaymentChange = (payment) => {
    setSelectedPayment(payment);
    console.log("selected Payment:", payment);
  };

  return (
    <div className="bg-gradient-to-r from-custom-primary to-custom-secondary rounded-lg p-[1px]">
      <div className="p-8 bg-white rounded-lg flex flex-wrap">
        <div className="w-4/6">
          <div className="flex items-center gap-2">
            <div>
              <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="18" cy="18" r="18" fill="#8884D5" />
                <path
                  d="M18.18 13.56C17.6333 13.56 17.1467 13.7133 16.72 14.02C16.3067 14.3267 15.84 14.8 15.32 15.44L13.16 13.7C13.5067 13.2333 13.8533 12.82 14.2 12.46C14.56 12.1 14.94 11.8 15.34 11.56C15.7533 11.3067 16.2067 11.12 16.7 11C17.1933 10.8667 17.7533 10.8 18.38 10.8C19.1267 10.8 19.7933 10.9067 20.38 11.12C20.98 11.32 21.4867 11.6133 21.9 12C22.3267 12.3733 22.6467 12.8267 22.86 13.36C23.0867 13.8933 23.2 14.4867 23.2 15.14C23.2 15.7267 23.12 16.2533 22.96 16.72C22.8133 17.1733 22.5867 17.6067 22.28 18.02C21.9867 18.4333 21.6067 18.8467 21.14 19.26C20.6733 19.66 20.1333 20.1 19.52 20.58L17.24 22.34H23.34V25H12.98V22.56L17.64 18.74C18.08 18.38 18.4533 18.06 18.76 17.78C19.0667 17.4867 19.3133 17.2133 19.5 16.96C19.7 16.6933 19.84 16.44 19.92 16.2C20.0133 15.9467 20.06 15.6733 20.06 15.38C20.06 14.78 19.8867 14.3267 19.54 14.02C19.1933 13.7133 18.74 13.56 18.18 13.56Z"
                  fill="white"
                />
              </svg>
            </div>
            <p className="text-lg font-semibold">You Kit Info</p>
          </div>
          <p className="text-base pt-2">
            To get started, please complete the form below.
          </p>
          <div className=" border-b pt-8 pb-8">
            <h4 className="text-lg font-semibold mb-2">Select your kit:</h4>
            <div className="grid grid-cols-3 gap-4 w-full">
              {options.map((option) => (
                <div key={option.name} className="cursor-pointer">
                  <input
                    type="radio"
                    id={`option${option.name}`}
                    name="options"
                    className="sr-only"
                    checked={selectedOption === option.name}
                    onChange={() => handleOptionChange(option.name)}
                  />
                  <label htmlFor={`option${option.name}`}>
                    <div
                      className={`border-2 border-gray-300 rounded-md text-center py-4 p-2 transition-colors ${
                        selectedOption === option.name
                          ? "border-custom-primary  bg-gradient-to-r from-custom-primary to-custom-secondary text-white font-semibold"
                          : "hover:border-custom-primary hover:text-custom-primary"
                      }`}
                    >
                      {option.name} <br />
                      {option.link && (
                        <Link to={option.link} className={`font-normal`}>
                          Read More
                        </Link>
                      )}
                    </div>
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className=" flex mt-4 items-center justify-between py-4  rounded-lg">
            <div className="font-bold text-xl ">
              <h4 className="text-lg font-semibold mb-2">Quantity</h4>
              <p className=" font-normal text-base">
                Number of babies you’re storing for: 50% off for the second
                child
              </p>
            </div>
            <div className="flex items-center gap-2">
              <svg
                width="40"
                height="40"
                viewBox="0 0 55 55"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M30.077 30.01H23.917V27.09H30.077V30.01Z"
                  fill="#37424A"
                />
                <circle cx="27.5" cy="27.5" r="27" stroke="#37424A" />
              </svg>
              <p className="font-bold text-xl ">1</p>
              <svg
                width="40"
                height="40"
                viewBox="0 0 55 55"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M25.605 26.33V22.73H28.445V26.33H32.065V29.09H28.445V32.69H25.605V29.09H21.985V26.33H25.605Z"
                  fill="#37424A"
                />
                <circle cx="27.5" cy="27.5" r="27" stroke="#37424A" />
              </svg>
            </div>
          </div>
          <div className=" border-b pt-8 pb-8">
            <h4 className="text-lg font-semibold mb-2">Payment:</h4>
            <div className="grid grid-cols-5 gap-4 w-full">
              {payments.map((payment) => (
                <div key={payment.name} className="cursor-pointer">
                  <input
                    type="radio"
                    id={`payment${payment.name}`}
                    name="payment"
                    className="sr-only"
                    checked={selectedPayment === payment.name}
                    onChange={() => handlePaymentChange(payment.name)}
                  />
                  <label htmlFor={`payment${payment.name}`}>
                    <div
                      className={`border-2 border-gray-300 rounded-md text-center py-4 p-2 transition-colors ${
                        selectedPayment === payment.name
                          ? "border-custom-primary text-custom-primary"
                          : "hover:border-custom-primary hover:text-custom-primary"
                      }`}
                    >
                      {payment.name} <br />
                      {payment.link && <Link to={payment.link}>Read More</Link>}
                    </div>
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className=" border-b pt-8 pb-8">
            <h4 className="text-lg font-semibold mb-2">Chamber Storage Bag:</h4>
            <div className="border rounded-lg p-8 grid grid-cols-8">
              <p className="text-lg font-semibold col-span-6">
                Add a 5 chamber Storage Bag – at AED1,000?
              </p>
              <div className="col-span-1 flex gap-2">
                <input type="radio" name="chmber" id="chmber-yes" />
                <label htmlFor="chmber-yes">Yes</label>
              </div>
              <div className=" col-span-1 flex gap-2">
                <input type="radio" name="chmber" id="chmber-no" />
                <label htmlFor="chmber-no">No</label>
              </div>
              <p className="text-base col-span-8">
                Add the premium 5 chamber storage bag for an additional
                AED1,000. Five separate chambers allows family members to have
                up to 5 different stem cell treatments
              </p>
            </div>
          </div>
        </div>
        <div className="w-2/6 pl-12">
          <div className="border rounded-lg py-8 px-4">
            <h3 className="text-custom-primary text-xl font-semibold capitalize">
              order summary
            </h3>
            <div className="flex flex-wrap border-b py-8 items-center justify-between">
              <img src={assets.checkoutProduct} alt="" className=" w-6/12" />
              <h3 className="w-5/12 text-xl font-semibold">CryoAdvanced</h3>
            </div>
            <div className="flex flex-col gap-2 py-4">
              <div className="flex flex-wrap  items-center justify-between">
                <h4 className="text-base">Enrollement</h4>
                <h4 className="text-base font-semibold">2000 AED</h4>{" "}
              </div>
              <div className="flex flex-wrap  items-center justify-between">
                <h4 className="text-base">Processing Fee</h4>
                <h4 className="text-base font-semibold">13,974 AED</h4>{" "}
              </div>
              <div className="flex flex-wrap  items-center justify-between">
                <h4 className="text-base">Storage fee 30 years</h4>
                <h4 className="text-base font-semibold">29,421 AED</h4>{" "}
              </div>
              <div className="flex flex-wrap  items-center justify-between w-full py-4 text-base">
                <input
                  type="text"
                  className="w-full border rounded-lg py-2 px-2"
                  placeholder="Apply Code"
                />
              </div>
              <div className="flex flex-wrap  items-center justify-between">
                <h4 className="text-base">Total Price</h4>
                <h4 className="text-base font-semibold">37,595 AED</h4>{" "}
              </div>
              <div className="flex flex-wrap  items-center justify-between">
                <h4 className="text-base">Due Now</h4>
                <h4 className="text-base font-semibold">14,095 AED</h4>{" "}
              </div>
              <div className="flex flex-wrap  items-center justify-between">
                <h4 className="text-base">Due Monthly</h4>
                <h4 className="text-base font-semibold">1958.33 AED</h4>{" "}
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-5 pt-8">
          <div
            onClick={() => handleChange("thirdStep")}
            className=" bg-gradient-to-r from-custom-primary to-custom-secondary transition-all text-white text-base font-semibold px-8 py-2 rounded-3xl"
          >
            Next
          </div>
          <div
            onClick={() => handleChange("firstStep")}
            className="bg-gradient-to-r from-custom-primary to-custom-secondary transition-all p-[1px] group hover:text-white text-base  rounded-3xl "
          >
            <div className="bg-white group-hover:bg-transparent px-8 py-2 rounded-3xl ">
              <span class="bg-gradient-to-r from-custom-primary to-custom-secondary text-transparent bg-clip-text group-hover:text-white transition-all font-semibold">
                Previous
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondStep;
