import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Waypoint } from "react-waypoint";
import "animate.css";
import Button from "../../components/Button";
import ParentContainer from "../../components/ParentContainer";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const AboutSection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isbutton, setIsButton] = useState(false);

  const handleEnter = () => {
    setIsVisible(true);
  };

  const main = useRef();

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const boxes = gsap.utils.toArray(".box");
      boxes.forEach((box) => {
        gsap.fromTo(
          box,
          { y: 300, opacity: 0 },
          {
            y: 0,
            opacity: 1,
            scrollTrigger: {
              trigger: box,
              start: "bottom 85%",
              end: "top 80%",
              scrub: 4,
              // markers: true,
            },
          }
        );
      });
    }, main);

    return () => ctx.revert();
  }, []);

  return (
    <ParentContainer
      containerStyle={`flex justify-center flex-wrap gap-5 py-20 ${
        isVisible ? "animate__animated animate__fadeInUp" : ""
      }`}
    >
      <div className="w-full flex justify-center flex-wrap gap-5" ref={main}>
        <Button
          Linkto={"/stem-cells-sceince"}
          titleof={" Newborn Stem Cells Introducing"}
          CustomStyle={" max-md:order-1 box"}
        />
        <h2 className="text-5xl font-bold text-center   w-1/2 max-md:w-full mx-5 max-md:mx-0 max-md:text-3xl animate__ani box">
          The Power of{" "}
          <span class="bg-gradient-to-r from-custom-primary to-custom-primary text-transparent bg-clip-text ">
            Newborn Stem Cells
          </span>
        </h2>
        <p className="w-1/2 text-center text-xl max-md:w-full max-md:text-base box">
          Preserve your newborn's future by saving stem cells from cord blood,``
          cord tissue, and placental tissue—a crucial choice for potential
          family treatments in the future.
        </p>
      </div>
    </ParentContainer>
  );
};

export default AboutSection;
