import React from "react";
import ParentContainer from "./ParentContainer";
import assets from "../assets/assets";
import { Link } from "react-router-dom";

const BlogListing = () => {
  return (
    <ParentContainer containerStyle={"flex flex-wrap"}>
      <Link
        to="/blog-single"
        className="w-1/2 flex flex-col gap-3 justify-between"
      >
        <img src={assets.blogdemoBanner} alt="" />
        <p
          className="text-xs text-gray-500
          "
        >
          PREGNANCY
        </p>
        <h2 className="text-2xl font-semibold underline">
          10 Pregnancy Essentials: A Vital Checklist for Expecting Parents
        </h2>
      </Link>
      <div className="w-1/2 pl-5 flex flex-col justify-between gap-2">
        <div className="border-b border-custom-primary pb-2">
          <h3 className="text-2xl text-custom-primary font-semibold">
            Top Reads Blogs
          </h3>
        </div>
        <div className="flex flex-col">
          <div className="py-5">
            <p
              className="text-xs text-gray-500
             "
            >
              PREGNANCY
            </p>
            <h2 className="text-xl font-semibold underline">
              10 Pregnancy Essentials: A Vital Checklist for Expecting Parents
            </h2>
          </div>
          <div className="py-5">
            <p
              className="text-xs text-gray-500
             "
            >
              PREGNANCY
            </p>
            <h2 className="text-xl font-semibold underline">
              10 Pregnancy Essentials: A Vital Checklist for Expecting Parents
            </h2>
          </div>
          <div className="py-5">
            <p
              className="text-xs text-gray-500
             "
            >
              PREGNANCY
            </p>
            <h2 className="text-xl font-semibold underline">
              10 Pregnancy Essentials: A Vital Checklist for Expecting Parents
            </h2>
          </div>
          <div className="py-5">
            <p
              className="text-xs text-gray-500
             "
            >
              PREGNANCY
            </p>
            <h2 className="text-xl font-semibold underline">
              10 Pregnancy Essentials: A Vital Checklist for Expecting Parents
            </h2>
          </div>
        </div>
      </div>
    </ParentContainer>
  );
};

export default BlogListing;
