import logo from "./logo.png";
import herovideo from "./herovideo.mp4";
import kit from "./kit.png";
import introduce from "./introduce.png";
import signup from "./signup.png";
import Featuredin from "./featuredin.png";
import a1video from "./43vid.mp4";
import logofooter from "./logoFooter.png";
import popupimg from "./popup.png";
import loginpopup from "./loginpopup.png";
import line from "./Line.png";
import VideoSection from "./videoSection.mp4";
import whysave from "./whysave.png";
import TransplantNumbers from "./transplant-number.png";
import ReasonOne from "./reason-one.png";
import ReasonOneTwo from "./reason-one2.png";
import ReasonTwo from "./reason-twopng.png";
import medone from "./medone.png";
import medtwo from "./medtwo.png";
import medthree from "./medthree.png";
import regenmed from "./regenmed.png";
import reguses from "./uses.png";
import power from "./power.png";
import Regnumbers from "./Regnumbers.png";
import sourcesBanner from "./sourcesbanner.png";
import sourceStem from "./sourceStem.png";
import sourcePower from "./sourcePower.png";
import cordBanner from "./CordBanner.png";
import cordAdvantage from "./CordAdvantage.png";
import cordTissueBannerer from "./CordTissueBanner.png";
import cordTissueAdvantage from "./cordTissueAdvantage.png";
import placentaTissueBanner from "./PlacentaTissue.png";
import cordVesselBanner from "./cordVesselBanner.png";
import amnionTissueBanner from "./AmnionTissueBanner.png";
import amnionAdvantage from "./amnionAdvantage.png";
import stemcellbanking from "./stemcellbanking.png";
import giftRegistryBanner from "./giftRegistryBanner.png";
import blogdemoBanner from "./blogdemoBanner.png";
import blogbanner from "./blogBanner.png";
import blogindexImage from "./blogIndexImage.png";
import blogsinglebanner from "./blogsinglebanner.png";
import featuredMomIndex from "./featuredMomIndex.png";
import momsingle from "./momsingle.png";
import profilepic from "./profilepic.png";
import profilehaelthline from "./profilehaelthline.png";
import emptySection from "./emptySection.png";
import voucher from "./voucher.png";
import ProductImageOne from "./ProductImageOne.png";
import checkoutProduct from "./checkoutimage.png";
import timelineOne from "./timeline.png";
import timelineTwo from "./timeline2.png";
import templateCord from "./templatecordpng.png";
import homeFamilies from "./homeFamlies.png";
import kitimage from "./kitimage.png";
import howitworksbg from "./howitworks.png";
import howitworks from "./howitworks1.png";

export default {
  logo,
  herovideo,
  kit,
  introduce,
  signup,
  Featuredin,
  a1video,
  logofooter,
  popupimg,
  loginpopup,
  line,
  VideoSection,
  whysave,
  TransplantNumbers,
  ReasonOne,
  ReasonOneTwo,
  ReasonTwo,
  medone,
  medtwo,
  medthree,
  regenmed,
  reguses,
  power,
  Regnumbers,
  sourcesBanner,
  sourceStem,
  sourcePower,
  cordBanner,
  cordAdvantage,
  cordTissueBannerer,
  cordTissueAdvantage,
  placentaTissueBanner,
  cordVesselBanner,
  amnionTissueBanner,
  amnionAdvantage,
  stemcellbanking,
  giftRegistryBanner,
  blogdemoBanner,
  blogbanner,
  blogindexImage,
  blogsinglebanner,
  featuredMomIndex,
  momsingle,
  profilepic,
  profilehaelthline,
  emptySection,
  voucher,
  ProductImageOne,
  checkoutProduct,
  timelineTwo,
  timelineOne,
  templateCord,
  homeFamilies,
  kitimage,
  howitworks,
  howitworksbg,
};
