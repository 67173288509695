import "./App.css";
import MainLayout from "./components/MainLayout";
import AboutPage from "./pages/AboutPage";
import RegenerativeMedicine from "./pages/RegenerativeMedicine";
import StemCellsSciencePage from "./pages/StemCellsSciencePage";
import HomePage from "./pages/HomePage";
import SourcesOfStem from "./pages/SourcesOfStem";
import CordBlood from "./pages/CordBlood";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import CordTissue from "./pages/CordTissue";
import PlacentaTissue from "./pages/PlacentaTissue";
import CordVessel from "./pages/CordVessel";
import AmnionTissue from "./pages/AmnionTissue";
import ServicesAndPricingPage from "./pages/ServicesAndPricingPage";
import GiftRegistry from "./pages/GiftRegistry";
import BlogPage from "./pages/BlogPage";
import BlogSingle from "./pages/BlogSingle";
import News from "./pages/News";
import FeaturedMoms from "./pages/FeaturedMoms";
import FeaturedMomsSingle from "./pages/FeaturedMomsSingle";
import FAQs from "./pages/FAQs";
import Contact from "./pages/Contact";
import ProfileHealthHistory from "./pages/ProfileHealthHistory";
import ProfileOrder from "./pages/ProfileOrder";
import ProfileDocs from "./pages/ProfileDocs";
import ProfileReferrals from "./pages/ProfileReferrals";
import ChangePass from "./pages/ChangePass";
import ProductPage from "./pages/ProductPage";
import OrderNow from "./pages/OrderNow";
import ScrollToTop from "./components/ScrollToTop";
import NavigateWithReload from "./components/NavigateWithreoload";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <NavigateWithReload />
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<HomePage />} />
          <Route path="about" element={<AboutPage />} />
          <Route
            path="/stem-cells-sceince"
            element={<StemCellsSciencePage />}
          />
          <Route
            path="regenerative-medicine"
            element={<RegenerativeMedicine />}
          />
          <Route path="/sources-of-stem-cell" element={<SourcesOfStem />} />
          <Route path="/cord-blood" element={<CordBlood />} />
          <Route path="/cord-tissue" element={<CordTissue />} />
          <Route path="/placenta-tissue" element={<PlacentaTissue />} />
          <Route path="/cord-vessel" element={<CordVessel />} />
          <Route path="/amnion-tissue" element={<AmnionTissue />} />
          <Route path="/service-pricing" element={<ServicesAndPricingPage />} />
          <Route path="/gift-registry" element={<GiftRegistry />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blog-single" element={<BlogSingle />} />
          <Route path="/news" element={<News />} />
          <Route path="/featured-moms" element={<FeaturedMoms />} />
          <Route
            path="/featured-moms-single"
            element={<FeaturedMomsSingle />}
          />
          <Route path="/faqs" element={<FAQs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<HomePage />} />

          {/* profile Routes */}

          <Route
            path="/profile/maternal-history"
            element={<ProfileHealthHistory />}
          />
          <Route path="/profile/order-history" element={<ProfileOrder />} />
          <Route path="/profile/documents" element={<ProfileDocs />} />
          <Route path="/profile/refer" element={<ProfileReferrals />} />
          <Route path="/profile/Pass" element={<ChangePass />} />

          {/* Product Page */}
          <Route path="/product/page" element={<ProductPage />} />
          <Route path="/checkout" element={<OrderNow />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
