import React from "react";
import BlogListing from "../components/BlogListing";
import assets from "../assets/assets";
import ParentContainer from "../components/ParentContainer";
import BlogIndex from "./Blog/BlogIndex";
import ResourcesMiniNav from "../components/ResourcesMiniNav";

const BlogPage = () => {
  return (
    <div>
      <ResourcesMiniNav />
      <BlogListing />
      <ParentContainer>
        <div className="w-full flex justify-center">
          <img src={assets.blogbanner} alt="" />
        </div>
      </ParentContainer>
      <BlogIndex />
    </div>
  );
};

export default BlogPage;
