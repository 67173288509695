import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

const NavigateWithReload = ({ pathsToReload }) => {
  const navigate = useNavigate();

  // refresh
  navigate(0);

  return null;
};

export default NavigateWithReload;
