import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import ParentContainer from "../../components/ParentContainer";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Limitless = () => {
  const main = useRef();
  const parentRef = useRef();

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const boxes = gsap.utils.toArray(".box");
      boxes.forEach((box) => {
        gsap.fromTo(
          box,
          { x: -200, opacity: 0 },
          {
            x: 0,
            opacity: 1,

            scrollTrigger: {
              trigger: box,
              start: "bottom 100%",
              end: "top 100%",
              scrub: 1,
              //  markers: true,
            },
          }
        );
      });

      const circles = gsap.utils.toArray(".circle");
      circles.forEach((circle) => {
        gsap.fromTo(
          circle,
          { x: 500, scale: 0 },
          {
            x: 0,
            scale: 1,
            scrollTrigger: {
              trigger: circle,
              start: "bottom 80%",
              end: "top 80%",
              scrub: 1,
              // markers: true,
            },
          }
        );
      });

      const images = gsap.utils.toArray(".imgs");
      gsap.from(images, {
        opacity: 0,
        y: 50,
        stagger: 0.2,
        scrollTrigger: {
          trigger: parentRef.current,
          start: "top 70%",
          end: "bottom 70%",
          scrub: 1,
          toggleActions: "play none none none",
        },
      });
    }, main);

    return () => ctx.revert();
  }, []);
  return (
    <div ref={main}>
      <ParentContainer
        containerStyle={
          "bg-gradient-to-r  from-custom-primary to-custom-secondary   flex flex-wrap items-center"
        }
        className=" "
      >
        <div className="w-5/6 box">
          <h3 className=" text-4xl font-bold text-white pb-3">
            Limitless Possibilities
          </h3>
          <p className="text-base text-white">
            That’s as many as 415 billion cells available to your baby and
            family over a lifetime.
          </p>
        </div>
        <div className="w-1/6 circle">
          <h3 className=" text-4xl  font-bold pb-5 uppercase text-right">
            1,037 <br /> Treatments
          </h3>
        </div>
      </ParentContainer>
    </div>
  );
};

export default Limitless;
