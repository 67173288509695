import React, { useLayoutEffect, useRef } from "react";

import MainLayout from "../components/MainLayout";
import HeroSection from "./HomePage/HeroSection";
import AboutSection from "./HomePage/AboutSection";
import Kit from "./HomePage/Kit";
import TextScroll from "./HomePage/TextScroll";
import Reasons from "./HomePage/Reasons";
import Introduction from "./HomePage/Introduction";
import About from "./HomePage/About";
import Safe from "./HomePage/Safe";
import ThreeImages from "./HomePage/ThreeImages";
import Packages from "./HomePage/Packages";
import Advantage from "./HomePage/Advantage";
import Featured from "./HomePage/Featured";
import Families from "./HomePage/Families";
import Partners from "./HomePage/Partners";
import Package from "../components/Packages";
import NewText from "./HomePage/NewText";

const HomePage = () => {
  return (
    <div>
      <HeroSection />

      <AboutSection />

      <Kit />

      <Reasons />

      <NewText />
      <Introduction />
      <About />
      <Safe />
      <ThreeImages />
      <Packages />
      <Package />
      <Advantage />
      <Featured />
      <Families />
      <Partners />
    </div>
  );
};

export default HomePage;
