import React from "react";
import ParentContainer from "../components/ParentContainer";
import assets from "../assets/assets";
import HeroSection from "./GiftRegistry/HeroSection";
import LifeTimeBanner from "../components/LifeTimeBanner";
import Form from "./GiftRegistry/Form";

const GiftRegistry = () => {
  return (
    <div>
      <HeroSection />
      <LifeTimeBanner
        Heading={"Because you love them, introduce them to me CellSave"}
      />
      <Form />
    </div>
  );
};

export default GiftRegistry;
