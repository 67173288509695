import React from "react";
import assets from "../../assets/assets";
import ParentContainer from "../../components/ParentContainer";

const BlogIndex = () => {
  var blogItem = (
    <div>
      <div id="imageindex">
        <img
          src={assets.blogindexImage}
          alt=""
          className="w-full object-cover object-top h-[250px] rounded-lg"
        />
      </div>
      <div className="py-5">
        <p
          className="text-xs text-gray-500
       "
        >
          PREGNANCY
        </p>
        <h2 className="text-xl font-semibold underline">
          10 Pregnancy Essentials: A Vital Checklist for Expecting Parents
        </h2>
      </div>
    </div>
  );

  return (
    <ParentContainer>
      <div className="border-b border-custom-primary pb-2">
        <h3 className="text-2xl text-custom-primary font-semibold">
          Top Reads Blogs
        </h3>
      </div>
      <div className="custom-parent grid grid-cols-4 gap-5 pt-8 ">
        {blogItem}
        {blogItem}
        {blogItem}
        {blogItem}
        {blogItem}
        {blogItem}
        {blogItem}
        {blogItem}
        {blogItem}
        {blogItem}
      </div>
      <a className="flex w-full gap-5 justify-center pb-5 pt-20">
        <div className=" border-black border  flex rounded-3xl py-1 px-4 font-semibold">
          <p className="text-base self-center">Read More</p>
        </div>
        <svg
          width="38"
          height="38"
          viewBox="0 0 38 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="19"
            cy="19"
            r="19"
            transform="rotate(-90 19 19)"
            fill="url(#paint0_linear_590_7734)"
          />
          <path
            d="M19.9695 16.1187L22.6977 18.847L19.9695 21.5752"
            fill="white"
          />
          <path
            d="M19.9695 16.1187L22.6977 18.847L19.9695 21.5752"
            stroke="white"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16.1804 18.8779L22.4729 18.8779"
            stroke="white"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <defs>
            <linearGradient
              id="paint0_linear_590_7734"
              x1="-3.60345"
              y1="4.22222"
              x2="44.6213"
              y2="7.62688"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#8884D5" />
              <stop offset="1" stop-color="#CF0072" />
            </linearGradient>
          </defs>
        </svg>
      </a>
    </ParentContainer>
  );
};

export default BlogIndex;
