import React, { useState } from "react";

const AccordionSetm = ({ title, children, isOpen, onClick }) => {
  return (
    <div className=" w-full border-b">
      <button
        onClick={onClick}
        className="w-full flex justify-between items-center p-8 text-left"
      >
        <div className="flex gap-2 w-full items-center">
          {" "}
          <span className="text-lg font-medium w-11/12">{title}</span>
          <div className="w-1/12 flex justify-end">
            {isOpen ? (
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.33301 11H14.6663"
                  stroke="url(#paint0_linear_590_5766)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.24967 20.1668H13.7497C18.333 20.1668 20.1663 18.3335 20.1663 13.7502V8.25016C20.1663 3.66683 18.333 1.8335 13.7497 1.8335H8.24967C3.66634 1.8335 1.83301 3.66683 1.83301 8.25016V13.7502C1.83301 18.3335 3.66634 20.1668 8.24967 20.1668Z"
                  stroke="url(#paint1_linear_590_5766)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_590_5766"
                    x1="6.63761"
                    y1="11.1111"
                    x2="14.0135"
                    y2="14.9298"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#8884D5" />
                    <stop offset="1" stop-color="#CF0072" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_590_5766"
                    x1="0.0945021"
                    y1="3.87053"
                    x2="23.3609"
                    y2="5.51313"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#8884D5" />
                    <stop offset="1" stop-color="#CF0072" />
                  </linearGradient>
                </defs>
              </svg>
            ) : (
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.33301 11H14.6663"
                  stroke="url(#paint0_linear_590_5758)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11 14.6666V7.33325"
                  stroke="url(#paint1_linear_590_5758)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.24967 20.1666H13.7497C18.333 20.1666 20.1663 18.3333 20.1663 13.7499V8.24992C20.1663 3.66659 18.333 1.83325 13.7497 1.83325H8.24967C3.66634 1.83325 1.83301 3.66659 1.83301 8.24992V13.7499C1.83301 18.3333 3.66634 20.1666 8.24967 20.1666Z"
                  stroke="url(#paint2_linear_590_5758)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_590_5758"
                    x1="6.63761"
                    y1="11.1111"
                    x2="14.0135"
                    y2="14.9298"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#8884D5" />
                    <stop offset="1" stop-color="#CF0072" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_590_5758"
                    x1="10.9052"
                    y1="8.14807"
                    x2="12.1805"
                    y2="8.16034"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#8884D5" />
                    <stop offset="1" stop-color="#CF0072" />
                  </linearGradient>
                  <linearGradient
                    id="paint2_linear_590_5758"
                    x1="0.0945021"
                    y1="3.87029"
                    x2="23.3609"
                    y2="5.51289"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#8884D5" />
                    <stop offset="1" stop-color="#CF0072" />
                  </linearGradient>
                </defs>
              </svg>
            )}
          </div>
        </div>
      </button>
      {isOpen && <div className="px-8 py-4">{children}</div>}
    </div>
  );
};

const Faqs = () => {
  const [openIndex, setOpenIndex] = useState(0); // Initialize to keep the first item open by default

  const handleToggle = (index) => {
    if (openIndex !== index) {
      setOpenIndex(index);
    }
  };
  return (
    <div className="pt-10 ">
      <p className="text-base">
        Please read the contract below. If you agree, click on the “I Agree”
        checkbox below and continue to the next question.
      </p>
      <h3 className="text-base font-bold pt-10">
        CLIENT CONSENT & SERVICE AGREEMENT: CORD BLOOD, TISSUE AND PLACENTAL
        STEM CELL COLLECTION, PROCESSING & STORAGE
      </h3>
      <p className="text-base">
        Each undersigned Legal Guardian and/or Parent (hereinafter the "Client”
        or “You" or “Your”), on Your behalf and the behalf of Your unborn child
        ("Child"), requests under this Agreement that Alpha Cord, LLC. ("Alpha
        Cord" or “We” or “Our” or “Us”) arrange for the processing, storage and
        testing of blood, tissue, stem cells and other bio-matter from the
        placenta, umbilical cord blood, umbilical cord tissue and other
        bio-matter You have sent to us and We are able to receive, process and
        store (hereinafter referred to as the "Unit". A Unit may denote the
        singular or plural) subsequent to the birth of Your Child. Each of the
        undersigned understands, acknowledges and agrees to the following
        legally-binding terms of this Agreement.
      </p>

      <AccordionSetm
        title="What are stem cells? "
        isOpen={openIndex === 0}
        onClick={() => handleToggle(0)}
      >
        <p>
          Stem cells are the body’s “master cells”. They are the building blocks
          of all organs, tissues, blood, and the immune system. In many tissues
          they serve as an internal repair system, regenerating to replace lost
          or damaged cells for the life of the person. Stem cells are considered
          the building blocks our body uses everyday in tissue repair and normal
          cell replenishment. Doctors were initially using stem cells derived
          from the bone marrow in patients who had received chemotherapy for
          cancer, to regenerate their blood and immune cells. In the late 1980s,
          they started using cord blood stem cells to treat diseases that had
          previously been treated with bone marrow transplantation. Stem cells
          are today successfully being used to save lives. They also are being
          researched in an revolutionary new area of medicine called
          regenerative medicine, where scientists are studying the use of stem
          cells in experimental treatments for conditions like brain injury,
          diabetes and acquired hearing loss.
        </p>
      </AccordionSetm>
      <AccordionSetm
        title="What is cord blood?"
        isOpen={openIndex === 1}
        onClick={() => handleToggle(1)}
      >
        <p>This is the content for section 2.</p>
      </AccordionSetm>
      <AccordionSetm
        title="What is cord tissue?
     "
        isOpen={openIndex === 2}
        onClick={() => handleToggle(2)}
      >
        <p>This is the content for section 3.</p>
      </AccordionSetm>
      <p className="text-base font-bold py-8">
        I have read and understand the above agreements, consents, limitation of
        liability and releases, and know the services described above are
        totally voluntary and elective on my part. I have discussed the services
        with my healthcare provider, and I have signed this Agreement freely and
        voluntarily. By signing this Agreement, I hereby acknowledge that I am
        giving up legal rights I might otherwise have had. <br /> By clicking
        the "I Agree" button, I hereby affix my signature in accordance with
        Georgia's Uniform Electronic Transactions Act, O.C.G.A. § 10-12-1, et.
        seq.,, signifying my consent and fully binding me to this agreement.
      </p>
      <input type="checkbox" name="i-agree" id="i-agree" />
      <label htmlFor="i-agree" className="font-semibold text-base">
        {" "}
        I Agree
      </label>
      <div className="flex gap-5">
        <button
          type="submit"
          className="hover:bg-gradient-to-r from-custom-primary to-custom-secondary hover:scale-110 transition-all hover:text-white text-base px-8 py-2 rounded-3xl mt-6"
        >
          Previous
        </button>
        <button
          type="Next"
          className=" bg-gradient-to-r from-custom-primary to-custom-secondary hover:scale-110 transition-all text-white text-base px-8 py-2 rounded-3xl mt-6"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Faqs;
