import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import ParentContainer from "../../components/ParentContainer";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const AccordionSetm = ({ title, children, isOpen, onClick }) => {
  return (
    <div className=" rounded-lg w-full">
      <button
        onClick={onClick}
        className="w-full flex justify-between items-center p-8 text-left"
      >
        <div className="flex gap-2 w-full items-center">
          {" "}
          <span className="text-lg font-medium w-11/12">{title}</span>
          <div className="w-1/12 flex justify-end">
            {isOpen ? (
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.33301 11H14.6663"
                  stroke="url(#paint0_linear_590_5766)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.24967 20.1668H13.7497C18.333 20.1668 20.1663 18.3335 20.1663 13.7502V8.25016C20.1663 3.66683 18.333 1.8335 13.7497 1.8335H8.24967C3.66634 1.8335 1.83301 3.66683 1.83301 8.25016V13.7502C1.83301 18.3335 3.66634 20.1668 8.24967 20.1668Z"
                  stroke="url(#paint1_linear_590_5766)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_590_5766"
                    x1="6.63761"
                    y1="11.1111"
                    x2="14.0135"
                    y2="14.9298"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#8884D5" />
                    <stop offset="1" stop-color="#CF0072" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_590_5766"
                    x1="0.0945021"
                    y1="3.87053"
                    x2="23.3609"
                    y2="5.51313"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#8884D5" />
                    <stop offset="1" stop-color="#CF0072" />
                  </linearGradient>
                </defs>
              </svg>
            ) : (
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.33301 11H14.6663"
                  stroke="url(#paint0_linear_590_5758)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11 14.6666V7.33325"
                  stroke="url(#paint1_linear_590_5758)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.24967 20.1666H13.7497C18.333 20.1666 20.1663 18.3333 20.1663 13.7499V8.24992C20.1663 3.66659 18.333 1.83325 13.7497 1.83325H8.24967C3.66634 1.83325 1.83301 3.66659 1.83301 8.24992V13.7499C1.83301 18.3333 3.66634 20.1666 8.24967 20.1666Z"
                  stroke="url(#paint2_linear_590_5758)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_590_5758"
                    x1="6.63761"
                    y1="11.1111"
                    x2="14.0135"
                    y2="14.9298"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#8884D5" />
                    <stop offset="1" stop-color="#CF0072" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_590_5758"
                    x1="10.9052"
                    y1="8.14807"
                    x2="12.1805"
                    y2="8.16034"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#8884D5" />
                    <stop offset="1" stop-color="#CF0072" />
                  </linearGradient>
                  <linearGradient
                    id="paint2_linear_590_5758"
                    x1="0.0945021"
                    y1="3.87029"
                    x2="23.3609"
                    y2="5.51289"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#8884D5" />
                    <stop offset="1" stop-color="#CF0072" />
                  </linearGradient>
                </defs>
              </svg>
            )}
          </div>
        </div>
      </button>
      {isOpen && <div className="px-8 py-4">{children}</div>}
    </div>
  );
};

const FAQs = () => {
  const [openIndex, setOpenIndex] = useState(0); // Initialize to keep the first item open by default

  const handleToggle = (index) => {
    if (openIndex !== index) {
      setOpenIndex(index);
    } else setOpenIndex(null);
  };

  const main = useRef();
  const parentRef = useRef();

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const boxes = gsap.utils.toArray(".box");
      boxes.forEach((box) => {
        gsap.fromTo(
          box,
          { x: -400, scale: 0 },
          {
            x: 0,
            scale: 1,

            scrollTrigger: {
              trigger: box,
              start: "bottom 100%",
              end: "top 100%",
              scrub: 2,
              //  markers: true,
            },
          }
        );
      });

      const circles = gsap.utils.toArray(".circle");
      circles.forEach((circle) => {
        gsap.fromTo(
          circle,
          { x: 500, scale: 0 },
          {
            x: 0,
            scale: 1,
            scrollTrigger: {
              trigger: circle,
              start: "bottom 80%",
              end: "top 80%",
              scrub: 1,
              //markers: true,
            },
          }
        );
      });

      const images = gsap.utils.toArray(".imgs");
      gsap.from(images, {
        opacity: 0,
        y: 50,
        stagger: 0.2,
        scrollTrigger: {
          trigger: parentRef.current,
          start: "top center",
          end: "bottom center",
          scrub: 1,
          toggleActions: "play none none none",
        },
      });
    }, main);

    return () => ctx.revert();
  }, []);

  return (
    <div ref={main}>
      <ParentContainer containerStyle={"flex flex-wrap justify-center"}>
        <h3 className=" text-4xl w-1/2  font-semibold  pb-10  capitalize box">
          Frequently Asked Question
        </h3>
        <Button
          Linkto={"/faqs"}
          titleof={"All FAQs"}
          CustomStyle={"!items-center !justify-end !px-0 circle"}
        />
        <div className="flex flex-col w-full border border-[#EAECF0] rounded-lg circle">
          <AccordionSetm
            title="What are stem cells? "
            isOpen={openIndex === 0}
            onClick={() => handleToggle(0)}
          >
            <p>
              Stem cells are the body’s “master cells”. They are the building
              blocks of all organs, tissues, blood, and the immune system. In
              many tissues they serve as an internal repair system, regenerating
              to replace lost or damaged cells for the life of the person. Stem
              cells are considered the building blocks our body uses everyday in
              tissue repair and normal cell replenishment. Doctors were
              initially using stem cells derived from the bone marrow in
              patients who had received chemotherapy for cancer, to regenerate
              their blood and immune cells. In the late 1980s, they started
              using cord blood stem cells to treat diseases that had previously
              been treated with bone marrow transplantation. Stem cells are
              today successfully being used to save lives. They also are being
              researched in an revolutionary new area of medicine called
              regenerative medicine, where scientists are studying the use of
              stem cells in experimental treatments for conditions like brain
              injury, diabetes and acquired hearing loss.
            </p>
          </AccordionSetm>
          <AccordionSetm
            title="What is cord blood?"
            isOpen={openIndex === 1}
            onClick={() => handleToggle(1)}
          >
            <p>This is the content for section 2.</p>
          </AccordionSetm>
          <AccordionSetm
            title="What is cord tissue?
       "
            isOpen={openIndex === 2}
            onClick={() => handleToggle(2)}
          >
            <p>This is the content for section 3.</p>
          </AccordionSetm>
        </div>
      </ParentContainer>
    </div>
  );
};

export default FAQs;
