import React from "react";
import ParentContainer from "../../components/ParentContainer";
import assets from "../../assets/assets";

const HeroSection = () => {
  const bulletIcon = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="8" stroke="#8884D5" stroke-width="4" />
    </svg>
  );
  return (
    <ParentContainer containerStyle={"flex flex-col gap-2"}>
      <h1 className="text-4xl font-bold">
        Gift <span className=" text-custom-primary">Registry</span>
      </h1>
      <p className="text-base ">
        The valuable gift of newborn stem cell preservation <br />
        is immensely a once‑in‑a‑lifetime opportunity and a gift with
        life‑saving possibilities. By preserving your newborn’s stem cells, your
        family will have this invaluable resource long after your baby outgrows
        their favorite blankie or toy.
      </p>
      <div className="pt-8 flex flex-wrap items-center">
        <div className="w-1/2">
          <img src={assets.giftRegistryBanner} alt="" className="w-10/12" />
        </div>
        <div className="w-1/2 flex flex-wrap gap-5">
          <div>
            <div className="flex gap-2 items-center">
              <div id="svg">{bulletIcon}</div>
              <p className="text-base font-bold">Mom to Be</p>
            </div>
            <p className="text-base ">
              Share this page with your friends and family so they can
              contribute any amount toward your preservation fees.
            </p>
          </div>
          <div>
            <div className="flex gap-2 items-center">
              <div id="svg">{bulletIcon}</div>
              <p className="text-base font-bold">Friends & Family</p>
            </div>
            <p className="text-base ">
              Please sign up if you would like to contribute any amount toward
              your family member or friend’s banking, processing, and storage
              fees.
            </p>
          </div>
        </div>
      </div>
    </ParentContainer>
  );
};

export default HeroSection;
