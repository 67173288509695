import React from "react";
import ParentContainer from "../../components/ParentContainer";
import assets from "../../assets/assets";

const RelatedBlog = () => {
  var blogItem = (
    <div>
      <div id="imageindex">
        <img
          src={assets.blogindexImage}
          alt=""
          className="w-full object-cover object-top h-[250px] rounded-lg"
        />
      </div>
      <div className="py-5">
        <p
          className="text-xs text-gray-500
           "
        >
          PREGNANCY
        </p>
        <h2 className="text-xl font-semibold underline">
          10 Pregnancy Essentials: A Vital Checklist for Expecting Parents
        </h2>
      </div>
    </div>
  );

  return (
    <ParentContainer>
      <h3 className="text-3xl font-bold text-center">
        Browse Similar Blogs Now
      </h3>
      <div className="custom-parent grid grid-cols-4 gap-5 pt-8 ">
        {blogItem}
        {blogItem}
        {blogItem}
        {blogItem}
      </div>
    </ParentContainer>
  );
};

export default RelatedBlog;
