import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import ParentContainer from "../../components/ParentContainer";
import { Link } from "react-router-dom";
import Button from "../../components/Button";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Packages = () => {
  const main = useRef();

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const boxes = gsap.utils.toArray(".box");
      boxes.forEach((box) => {
        gsap.fromTo(
          box,
          { y: 300, opacity: 0 },
          {
            y: 0,
            opacity: 1,
            scrollTrigger: {
              trigger: box,
              start: "bottom 85%",
              end: "top 80%",
              scrub: 4,
              // markers: true,
            },
          }
        );
      });
    }, main);

    return () => ctx.revert();
  }, []);

  return (
    <div ref={main}>
      <ParentContainer containerStyle={"flex w-full flex-wrap gap-20"}>
        <div className="flex flex-col w-full gap-5 ">
          <Button
            Linkto={"/service-pricing"}
            titleof={" CellSave Packages"}
            CustomStyle={"!w-full box"}
            white={false}
          />
          <h2 className="text-5xl font-bold text-center w-full box">
            The Power of{" "}
            <span class="bg-gradient-to-r from-custom-primary to-custom-primary text-transparent bg-clip-text ">
              Newborn Stem Cells
            </span>
          </h2>
        </div>
      </ParentContainer>
    </div>
  );
};

export default Packages;
