import React from "react";
import ParentContainer from "../../components/ParentContainer";

const HeroSection = () => {
  return (
    <ParentContainer>
      <h1 className="text-4xl capitalize font-bold pb-5">
        Find The Package That Works{" "}
        <span className=" text-custom-primary ">For Your Family</span>
      </h1>
      <p className="text-base">
        We bring you the region’s next generation of stem cell banking
        solutions. Not just more protection. Better protection
      </p>
    </ParentContainer>
  );
};

export default HeroSection;
