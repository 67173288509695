import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import assets from "../../assets/assets";
import ParentContainer from "../../components/ParentContainer";
import { Link } from "react-router-dom";
import { Waypoint } from "react-waypoint";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import Button from "../../components/Button";
gsap.registerPlugin(ScrollTrigger);

const Medicine = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleEnter = () => {
    setIsVisible(true);
  };

  const main = useRef();
  const parentRef = useRef();

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const boxes = gsap.utils.toArray(".box");
      boxes.forEach((box) => {
        gsap.fromTo(
          box,
          { y: 200, scale: 0 },
          {
            y: 0,
            scale: 1,

            scrollTrigger: {
              trigger: box,
              start: "bottom 80%",
              end: "top 80%",
              scrub: 4,
              //  markers: true,
            },
          }
        );
      });

      const circles = gsap.utils.toArray(".circle");
      circles.forEach((circle) => {
        gsap.fromTo(
          circle,
          { x: 500, scale: 0 },
          {
            x: 0,
            scale: 1,
            scrollTrigger: {
              trigger: circle,
              start: "bottom 80%",
              end: "top 80%",
              scrub: 1,
              markers: true,
            },
          }
        );
      });

      const images = gsap.utils.toArray(".imgs");
      gsap.from(images, {
        opacity: 0,
        y: 50,
        stagger: 0.2,
        scrollTrigger: {
          trigger: parentRef.current,
          start: "top 70%",
          end: "bottom 70%",
          scrub: 1,
          toggleActions: "play none none none",
        },
      });
    }, main);

    return () => ctx.revert();
  }, []);
  return (
    <div ref={main}>
      <ParentContainer containerStyle={"flex flex-wrap justify-center"}>
        <div className="w-full flex flex-wrap justify-between box">
          <h3 className=" text-4xl w-2/3  font-semibold  pb-5  capitalize">
            The impact of{" "}
            <span className=" text-custom-primary">
              transplant medicine today
            </span>
          </h3>
          <Button
            Linkto={"/checkout"}
            titleof={"Order Now"}
            CustomStyle={"!items-center !w-auto !justify-end "}
          />
        </div>
        <div className="flex gap-40 pt-16 items-center" ref={parentRef}>
          <div
            className={`w-1/3 flex flex-wrap justify-center gap-5 pb-16 imgs ${
              isVisible ? "animate__animated animate__fadeInUp " : ""
            } `}
          >
            <img src={assets.medone} alt="" className="w-full" />
            <p className="w-4/5 text-left text-lg  ">
              Stem cells have been used in bone marrow transplants since the
              1970s and today they are approved by the FDA to treat serious
              medical conditions.
            </p>
          </div>
          <div
            className={`w-1/3 flex flex-wrap justify-center gap-5 imgs ${
              isVisible
                ? "animate__animated animate__fadeInUp animate__delay-1s"
                : ""
            }`}
          >
            <img src={assets.medtwo} alt="" className="w-full" />
            <p className="w-4/5 text-left text-lg  ">
              They are currently used to treat over 80 conditions, including
              certain types of cancers, blood disorders, and metabolic
              disorders.
            </p>
          </div>
          <div
            className={`w-1/3 flex flex-wrap justify-center gap-5 pt-16 imgs ${
              isVisible
                ? "animate__animated animate__fadeInUp animate__delay-2s"
                : ""
            }`}
          >
            <img src={assets.medthree} alt="" className="w-full" />
            <p className="w-4/5 text-left text-lg  ">
              Cord blood has been used in more than 50,000 stem cell transplants
              worldwide to help rebuild health blood and immune systems.
            </p>
          </div>
        </div>
      </ParentContainer>
    </div>
  );
};

export default Medicine;
