import React, { useState } from "react";
import ParentContainer from "../../components/ParentContainer";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Form = () => {
  const [phone, setPhone] = useState("");

  return (
    <ParentContainer containerStyle={"flex flex-wrap "}>
      <div className="w-1/3">
        <h3 className="text-4xl font-bold pb-2">
          {" "}
          Have questions? <br /> Send us a message
        </h3>
        <p className="text-base">Representative will contact you.</p>
      </div>
      <form className="w-2/3 ">
        <div className=" border-b pb-8 grid grid-cols-4 gap-4">
          <div className=" col-span-2 w-full">
            <label htmlFor="first-name" className="block mb-1">
              First Name
            </label>
            <input
              type="text"
              name="first-name"
              id="first-name"
              className="border border-gray-300 rounded-md px-3 py-2 w-full"
            />
          </div>

          <div className="col-span-2 ">
            <label htmlFor="last-name" className="block mb-1">
              Last Name
            </label>
            <input
              type="text"
              name="last-name"
              id="last-name"
              className="border border-gray-300 rounded-md px-3 py-2 w-full"
            />
          </div>

          <div className=" col-span-2">
            <label htmlFor="phone-number" className="block mb-1">
              Phone Number
            </label>
            <PhoneInput
              country={"ae"}
              value={phone}
              onChange={(phone) => setPhone(phone)}
              enableSearch={true}
              disableSearchIcon={true}
            />
          </div>

          <div className=" col-span-2">
            <label htmlFor="email" className="block mb-1">
              Email Address
            </label>
            <input
              type="email"
              name="email"
              id="email"
              className="border border-gray-300 rounded-md px-3 py-2 w-full"
            />
          </div>
          <div className=" col-span-4">
            <label htmlFor="topic" className="block mb-1">
              Topic
            </label>
            <input
              type="text"
              name="topic"
              id="topic"
              className="border border-gray-300 rounded-md px-3 py-2 w-full"
            />
          </div>
          <div className=" col-span-4">
            <label htmlFor="message" className="block mb-1">
              Message
            </label>
            <textarea
              rows={4}
              className="border border-gray-300 rounded-md px-3 py-2 w-full"
            ></textarea>
          </div>
        </div>

        <button
          type="submit"
          className=" bg-gradient-to-r from-custom-primary to-custom-secondary hover:scale-110 transition-all text-white text-base px-8 py-2 rounded-3xl mt-6"
        >
          Submit
        </button>
      </form>
    </ParentContainer>
  );
};

export default Form;
