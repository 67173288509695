import React from "react";
import ParentContainer from "../components/ParentContainer";
import ProfileSideBar from "../components/ProfileSideBar";
import HeroSection from "./ProfileHealthHistory/HeroSection";
import Attention from "./ProfileHealthHistory/Attention";
import ProfileEmpty from "../components/ProfleEmpty";
import Order from "./ProfileOrder/Order";

const ProfileHealthHistory = () => {
  return (
    <ParentContainer
      containerHeight={"py-0"}
      containerStyle="bg-[#FBF7F5] flex justify-stretch "
    >
      <div className="w-1/5 bg-white border-r">
        <ProfileSideBar />
      </div>
      <div className="w-4/5">
        <HeroSection />
        <ProfileEmpty />
        <Order />
      </div>
    </ParentContainer>
  );
};

export default ProfileHealthHistory;
