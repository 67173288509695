import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const FirstStep = ({ handleClickfrom }) => {
  const [phone, setPhone] = useState("");

  const handleChange = (item) => {
    handleClickfrom(item);
  };
  return (
    <div className="bg-gradient-to-r from-custom-primary to-custom-secondary rounded-lg p-[1px]">
      <div className="p-8 bg-white rounded-lg">
        <div className="flex items-center gap-2">
          <div>
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="18" cy="18" r="18" fill="#8884D5" />
              <path
                d="M14.48 12.1L18.5 10.9H20.6V25H17.56V14.02L15.12 14.62L14.48 12.1Z"
                fill="white"
              />
            </svg>
          </div>
          <p className="text-lg font-semibold">Let’s Get Started</p>
        </div>
        <p className="text-base pt-2">
          To get started, please complete the form below.
        </p>

        <div className="pt-4">
          {" "}
          <div className="pt-2 pb-8 grid grid-cols-4 gap-4">
            <div className=" col-span-4 grid grid-cols-2 gap-4">
              <div className=" ">
                <label htmlFor="first-Name" className="block mb-1">
                  First Name
                </label>
                <input
                  type="text"
                  name="first-Name"
                  id="first-Name"
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                />
              </div>

              <div className="">
                <label htmlFor="last-Name" className="block mb-1">
                  Last-Name
                </label>
                <input
                  type="text"
                  name="last-Name"
                  id="last-Name"
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                />
              </div>
            </div>

            <div className=" col-span-2">
              <label htmlFor="phone-number" className="block mb-1">
                Phone Number
              </label>
              <PhoneInput
                country={"ae"}
                value={phone}
                onChange={(phone) => setPhone(phone)}
                enableSearch={true}
                disableSearchIcon={true}
              />
            </div>
            <div className=" col-span-2">
              <label htmlFor="email" className="block mb-1">
                Email Address
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className="border border-gray-300 rounded-md px-3 py-2 w-full"
              />
            </div>
          </div>
        </div>
        <div onClick={() => handleChange("secondStep")} className="flex gap-5">
          <div className=" bg-gradient-to-r from-custom-primary to-custom-secondary transition-all text-white text-base font-semibold px-8 py-2 rounded-3xl">
            Next
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstStep;
