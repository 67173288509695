import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  useLocation,
} from "react-router-dom";

const MobileNav = ({ onMessageUpdate }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
  const [isDropdownOpen3, setIsDropdownOpen3] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const location = useLocation();

  const handleClickMobile = () => {
    onMessageUpdate(false);
  };

  useEffect(() => {
    // Reset state when the location changes
    setIsDropdownOpen(false);
    setIsDropdownOpen2(false);
    setIsDropdownOpen3(false);
  }, [location]);
  return (
    <div className=" min-h-screen overflow-y-auto w-full bg-white z-[999999] flex items-center justify-center fixed top-0">
      <div className="flex  ">
        <div
          id="closeIcon"
          className=" absolute top-5 right-5"
          onClick={handleClickMobile}
        >
          <svg
            enable-background="new 0 0 32 32"
            height="32px"
            id="Слой_1"
            version="1.1"
            viewBox="0 0 32 32"
            width="32px"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.459,16.014l8.239-8.194c0.395-0.391,0.395-1.024,0-1.414c-0.394-0.391-1.034-0.391-1.428,0  l-8.232,8.187L7.73,6.284c-0.394-0.395-1.034-0.395-1.428,0c-0.394,0.396-0.394,1.037,0,1.432l8.302,8.303l-8.332,8.286  c-0.394,0.391-0.394,1.024,0,1.414c0.394,0.391,1.034,0.391,1.428,0l8.325-8.279l8.275,8.276c0.394,0.395,1.034,0.395,1.428,0  c0.394-0.396,0.394-1.037,0-1.432L17.459,16.014z"
              fill="black"
              id="Close"
            />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
          </svg>
        </div>
        <ul className="flex flex-col w-full items-center justify-between content-center gap-5  text-2xl">
          <li>
            <Link to="/about" className="font-semibold">
              About CellSave
            </Link>
          </li>
          <li className="relative flex max-md:flex-wrap content-center justify-center items-center gap-2 	">
            <div className="flex items-center gap-2 justify-center">
              <button
                onClick={() => setIsDropdownOpen((prev) => !prev)}
                className={`flex items-center justify-center gap-2 w-full font-semibold ${
                  isDropdownOpen ? "underline" : ""
                } `}
              >
                Discover
              </button>
              <svg
                width="10"
                height="6"
                viewBox="0 0 10 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                {isDropdownOpen ? (
                  <path
                    d="M8.50586 4.75977L4.97586 1.23977L1.44586 4.75977"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                ) : (
                  <path
                    d="M1.43945 1.24023L4.96945 4.76023L8.49945 1.24023"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                )}
              </svg>
            </div>
            {isDropdownOpen && (
              <div className=" top-full -left-1/2  py-2 text-center   rounded-lg z-10 w-full p-4">
                <Link
                  to="/stem-cells-sceince"
                  className={`   block px-4 py-2  hover:bg-gray-200`}
                >
                  Stem Cells Science
                </Link>
                <Link
                  to="/regenerative-medicine"
                  className="block px-4 py-2  hover:bg-gray-200"
                >
                  Regenartive Medicine
                </Link>
                <div className="flex items-center justify-center  gap-2">
                  <button className=" flex items-center justify-center relative   hover:bg-gray-200">
                    <Link
                      to="/sources-of-stem-cell"
                      className={`  ${
                        isDropdownOpen3 ? "underline" : ""
                      } block px-4 py-2  hover:bg-gray-200`}
                    >
                      Sources of Stem Cell
                    </Link>
                  </button>
                  <svg
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => setIsDropdownOpen3((prev) => !prev)}
                  >
                    {isDropdownOpen3 ? (
                      <path
                        d="M8.50586 4.75977L4.97586 1.23977L1.44586 4.75977"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    ) : (
                      <path
                        d="M1.43945 1.24023L4.96945 4.76023L8.49945 1.24023"
                        stroke="black"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    )}
                  </svg>
                </div>
                {isDropdownOpen3 && (
                  <div className=" bg-white py-2 w-full rounded-lg z-10">
                    <Link
                      to="/cord-blood"
                      className="block px-4 py-2  hover:bg-gray-200"
                    >
                      Cord Blood{" "}
                    </Link>
                    <Link
                      to="/cord-tissue"
                      className="block px-4 py-2  hover:bg-gray-200"
                    >
                      Cord Tissue
                    </Link>
                    <Link
                      to="/placenta-tissue"
                      className="block px-4 py-2  hover:bg-gray-200"
                    >
                      Placenta Tissue
                    </Link>
                    <Link
                      to="/cord-vessel"
                      className="block px-4 py-2  hover:bg-gray-200"
                    >
                      Cord Vessel
                    </Link>
                    <Link
                      to="/amnion-tissue"
                      className="block px-4 py-2  hover:bg-gray-200"
                    >
                      Amnion Tissue
                    </Link>
                  </div>
                )}
              </div>
            )}
          </li>
          <li className="relative flex flex-wrap justify-center content-center items-center gap-2">
            <button
              onClick={() => setIsDropdownOpen2((prev) => !prev)}
              className={`flex items-center gap-2 font-semibold	${
                isDropdownOpen2 ? "underline" : ""
              }`}
            >
              Services & Pricing
              <svg
                width="10"
                height="6"
                viewBox="0 0 10 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                {isDropdownOpen2 ? (
                  <path
                    d="M8.50586 4.75977L4.97586 1.23977L1.44586 4.75977"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                ) : (
                  <path
                    d="M1.43945 1.24023L4.96945 4.76023L8.49945 1.24023"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                )}
              </svg>
            </button>

            {isDropdownOpen2 && (
              <div className=" bg-white py-2 w-full justify-center text-center rounded-lg z-10">
                <Link
                  to="/service-pricing"
                  className="block px-4 py-2  hover:bg-gray-200"
                >
                  Service & Pricing
                </Link>
                <Link
                  to="/gift-registry"
                  className="block px-4 py-2 hover:bg-gray-200"
                >
                  Gift Registry
                </Link>
                <Link to="/faqs" className="block px-4 py-2 hover:bg-gray-200">
                  FAQs
                </Link>
                <Link
                  to="/contact"
                  className="block px-4 py-2 hover:bg-gray-200"
                >
                  Contact
                </Link>
              </div>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MobileNav;
