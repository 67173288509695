import React from "react";
import MainHeroSection from "../components/MainHeroSection";
import assets from "../assets/assets";
import MainAdvantagesSection from "../components/MainAdvantagesSection";
import MainValuable from "../components/MainValuable";
import TranslplantNumbers from "../components/TranslplantNumbers";
import LifeTimeBanner from "../components/LifeTimeBanner";

const AmnionTissue = () => {
  const lineBreak = false;
  const AdvantagePara = true;
  return (
    <div>
      <MainHeroSection
        HeadingBlack={"What is"}
        HeadingBreak={lineBreak}
        HeadingBlue={"Amnion Tissue?"}
        HeadingPara={
          "An integral part of the human placenta, amnion tissue is the thin elastic membrane lining the inside of the placental sac. This unique tissue has long been used as an effective tool in topical healing to treat wounds, burns, ulcers, and eye conditions."
        }
        IconOne={
          "you can maximize the number and types of cells that your family has access to."
        }
        IconTwo={
          "ensuring your family can take advantage of as many of the latest regenerative therapies as possible, "
        }
        IconThree={
          "Lorem ipsum dolor sit amet consectetur. Laoreet mauris ac enim amet. Ac curabitur."
        }
        BannerImage={assets.amnionTissueBanner}
        BannerText={"More Cells, More Treatments"}
      />
      <MainAdvantagesSection
        HeadingBlack={"Amnion Tissue"}
        HeadingBlue={"Advantages"}
        TwoImages={false}
        Image={assets.amnionAdvantage}
        isPara={AdvantagePara}
        Para={
          "Amnion placental tissue stem cells are currently being used in regenerative medicine clinical trials worldwide. These studies include treatment for conditions, such as:"
        }
        Advantages={[
          "Ulcers",
          "Brain injury",
          "Amnion skin graft",
          "Type 2 diabetes",
          "Cardiovascular conditions",
          "3D printing body parts",
          "Dry eye",
          "Burns",
          "Lung and liver fibrosis",
        ]}
      />
      <MainValuable
        HeadingBlack={"Why Is It So "}
        HeadingBlue={"Valuable?"}
        HeadingPara={
          "Lorem ipsum dolor sit amet consectetur. Convallis ut elementum magna amet risus commodo. Mauris mauris velit ac quis sem nec libero facilisi elementum. Tortor cursus auctor vitae urna diam. "
        }
        isButton={true}
        ValueList={[
          "Amnion placental tissue contains a powerful trio of healing properties: collagen, fibronectin, and hyaluronic acid. ",
          "As well as being another source of mesenchymal stem cells.",
          "The amniotic membrane itself has a combination of growth factors, cytokines, and anti-inflammatory proteins that can help cells communicate with each other to fight disease.",
        ]}
      />
      <TranslplantNumbers backgroundImage={assets.TransplantNumbers} />
      <LifeTimeBanner />
    </div>
  );
};
export default AmnionTissue;
