import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import assets from "../../assets/assets";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import { Navigation } from "swiper/modules";
import ParentContainer from "../../components/ParentContainer";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
// Import custom navigation icons or use Swiper's default icons

const HowWorks = () => {
  const main = useRef();
  const parentRef = useRef();

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const boxes = gsap.utils.toArray(".box");
      boxes.forEach((box) => {
        gsap.fromTo(
          box,
          { y: 200, scale: 0 },
          {
            y: 0,
            scale: 1,

            scrollTrigger: {
              trigger: box,
              start: "bottom 100%",
              end: "top 100%",
              scrub: 2,
              //  markers: true,
            },
          }
        );
      });

      const circles = gsap.utils.toArray(".circle");
      circles.forEach((circle) => {
        gsap.fromTo(
          circle,
          { x: 500, scale: 0 },
          {
            x: 0,
            scale: 1,
            scrollTrigger: {
              trigger: circle,
              start: "bottom 80%",
              end: "top 80%",
              scrub: 1,
              //markers: true,
            },
          }
        );
      });

      const images = gsap.utils.toArray(".imgs");
      gsap.from(images, {
        opacity: 0,
        y: 50,
        stagger: 0.2,
        scrollTrigger: {
          trigger: parentRef.current,
          start: "top center",
          end: "bottom center",
          scrub: 1,
          toggleActions: "play none none none",
        },
      });
    }, main);

    return () => ctx.revert();
  }, []);

  return (
    <div ref={main}>
      <ParentContainer containerStyle={"relative"}>
        <div className="flex flex-col box">
          <h2 className="text-5xl font-bold text-center w-full mb-16">
            How it{" "}
            <span className="text-custom-primary">
              Works <br />
            </span>
          </h2>
        </div>
        <Swiper
          slidesPerView={3}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          navigation={{
            prevEl: ".swiper-button-prev",
            nextEl: ".swiper-button-next",
          }}
          modules={[Navigation]}
          className="mySwiper w-5/6 circle"
        >
          <SwiperSlide>
            <div className="w-full flex flex-wrap justify-center gap-5 group">
              <div className="relative">
                <img
                  src={assets.howitworksbg}
                  alt=""
                  className="w-full rotateIcon"
                />
                <img
                  src={assets.howitworks}
                  alt=""
                  className="w-full absolute top-0 p-1 group-hover:p-2  transition-all"
                />
              </div>
              <h4 className="w-full leading-10 text-center text-3xl font-bold bg-gradient-to-br from-custom-primary to-custom-secondary text-transparent bg-clip-text">
                Sign Up
              </h4>
              <p className="w-4/5 text-center text-lg  ">
                Sign up online or dial 800 2796 to receive a convenient
                collection kit promptly and start your journey with us today.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-full flex flex-wrap justify-center gap-5 group">
              <div className="relative">
                <img
                  src={assets.howitworksbg}
                  alt=""
                  className="w-full rotateIcon"
                />
                <img
                  src={assets.howitworks}
                  alt=""
                  className="w-full absolute top-0 p-1 group-hover:p-2  transition-all"
                />
              </div>
              <h4 className="w-full leading-10 text-center text-3xl font-bold bg-gradient-to-br from-custom-primary to-custom-secondary text-transparent bg-clip-text">
                Sign Up
              </h4>
              <p className="w-4/5 text-center text-lg  ">
                Sign up online or dial 800 2796 to receive a convenient
                collection kit promptly and start your journey with us today.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-full flex flex-wrap justify-center gap-5 group">
              <div className="relative">
                <img
                  src={assets.howitworksbg}
                  alt=""
                  className="w-full rotateIcon"
                />
                <img
                  src={assets.howitworks}
                  alt=""
                  className="w-full absolute top-0 p-1 group-hover:p-2  transition-all"
                />
              </div>
              <h4 className="w-full leading-10 text-center text-3xl font-bold bg-gradient-to-br from-custom-primary to-custom-secondary text-transparent bg-clip-text">
                Sign Up
              </h4>
              <p className="w-4/5 text-center text-lg  ">
                Sign up online or dial 800 2796 to receive a convenient
                collection kit promptly and start your journey with us today.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-full flex flex-wrap justify-center gap-5 group">
              <div className="relative">
                <img
                  src={assets.howitworksbg}
                  alt=""
                  className="w-full rotateIcon"
                />
                <img
                  src={assets.howitworks}
                  alt=""
                  className="w-full absolute top-0 p-1 group-hover:p-2  transition-all"
                />
              </div>
              <h4 className="w-full leading-10 text-center text-3xl font-bold bg-gradient-to-br from-custom-primary to-custom-secondary text-transparent bg-clip-text">
                Sign Up
              </h4>
              <p className="w-4/5 text-center text-lg  ">
                Sign up online or dial 800 2796 to receive a convenient
                collection kit promptly and start your journey with us today.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-full flex flex-wrap justify-center gap-5 group">
              <div className="relative">
                <img
                  src={assets.howitworksbg}
                  alt=""
                  className="w-full rotateIcon"
                />
                <img
                  src={assets.howitworks}
                  alt=""
                  className="w-full absolute top-0 p-1 group-hover:p-2  transition-all"
                />
              </div>
              <h4 className="w-full leading-10 text-center text-3xl font-bold bg-gradient-to-br from-custom-primary to-custom-secondary text-transparent bg-clip-text">
                Sign Up
              </h4>
              <p className="w-4/5 text-center text-lg  ">
                Sign up online or dial 800 2796 to receive a convenient
                collection kit promptly and start your journey with us today.
              </p>
            </div>
          </SwiperSlide>
        </Swiper>
        {/* Custom navigation buttons */}
        <div className="swiper-button-prev absolute top-1/2 left-36 circle">
          <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="25"
              cy="25"
              r="25"
              transform="matrix(0 -1 -1 0 50 50)"
              fill="#37424A"
            />
            <path
              d="M23.7246 21.4235L20.1348 24.9627L23.7246 28.502"
              fill="white"
            />
            <path
              d="M23.7246 21.4235L20.1348 24.9627L23.7246 28.502"
              stroke="white"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M28.71 25.0034L20.4304 25.0034"
              stroke="white"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div className="swiper-button-next absolute top-1/2 right-36 circle">
          <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="25"
              cy="25"
              r="25"
              transform="rotate(-90 25 25)"
              fill="#37424A"
            />
            <path
              d="M26.2754 20.6282L29.8652 24.3605L26.2754 28.0928"
              fill="white"
            />
            <path
              d="M26.2754 20.6282L29.8652 24.3605L26.2754 28.0928"
              stroke="white"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M21.29 24.4038L29.5696 24.4038"
              stroke="white"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <defs>
              <linearGradient
                id="paint0_linear_590_5719"
                x1="-4.74138"
                y1="5.55556"
                x2="58.7123"
                y2="10.0354"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#8884D5" />
                <stop offset="1" stop-color="#CF0072" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </ParentContainer>
    </div>
  );
};

export default HowWorks;
