import React from "react";
import ResourcesMini from "../components/ResourcesMiniNav";
import HeroSection from "./FeaturedMoms/HeroSection";

const FeaturedMoms = () => {
  return (
    <div className="bg-white">
      <ResourcesMini />
      <HeroSection />
    </div>
  );
};

export default FeaturedMoms;
