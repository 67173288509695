import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import assets from "../../assets/assets";
import AccordionItem from "./AccordionItem";
import ParentContainer from "../../components/ParentContainer";
import { Link } from "react-router-dom";
import { Waypoint } from "react-waypoint";
import "animate.css";
import Button from "../../components/Button";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const WhySelect = () => {
  const [openIndex, setOpenIndex] = useState(0); // Initialize to keep the first item open by default

  const handleToggle = (index) => {
    if (openIndex !== index) {
      setOpenIndex(index);
    } else setOpenIndex(null);
  };
  const [isVisible, setIsVisible] = useState(false);

  const handleEnter = () => {
    setIsVisible(true);
  };

  const main = useRef();

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const boxes = gsap.utils.toArray(".box");
      boxes.forEach((box) => {
        gsap.fromTo(
          box,
          { x: 1500, scale: 0 },
          {
            x: 0,
            scale: 1,

            scrollTrigger: {
              trigger: box,
              start: "bottom center",
              end: "top center",
              scrub: 2,
              // markers: true,
            },
          }
        );
      });

      const circles = gsap.utils.toArray(".circle");
      circles.forEach((circle) => {
        gsap.fromTo(
          circle,
          { x: 500, scale: 0 },
          {
            x: 0,
            scale: 1,
            scrollTrigger: {
              trigger: circle,
              start: "bottom 85%",
              end: "top 50%",
              scrub: 4,
              //  markers: true,
            },
          }
        );
      });

      const images = gsap.utils.toArray(".imgs");
      images.forEach((imgs) => {
        gsap.fromTo(
          imgs,
          { scale: 0.5, opacity: 0, x: -300 },
          {
            scale: 1,
            opacity: 1,
            x: 0,
            scrollTrigger: {
              trigger: imgs,
              start: "bottom 85%",
              end: "top 50%",
              scrub: 4,
              //    markers: true,
            },
          }
        );
      });

      const bgssss = gsap.utils.toArray(".bgs");
      bgssss.forEach((bgs) => {
        gsap.fromTo(
          bgs,
          { background: "linear-gradient(to right, white, white)" },
          {
            background: "linear-gradient(to right, #37424A, black)",
            scrollTrigger: {
              trigger: bgs,
              start: "bottom 50%",
              end: "top 0%",
              scrub: 1,
              // markers: true,
            },
          }
        );
      });
    }, main);

    return () => ctx.revert();
  }, []);

  return (
    <div ref={main}>
      <ParentContainer containerStyle={"bg-white"}>
        <div className="flex justify-center flex-wrap py-10 items-center ">
          <h2 className="text-5xl font-bold text-left w-1/2 imgs">
            Why to select{" "}
            <span className="text-custom-primary">
              CellSave <br />
            </span>
          </h2>
          <Button
            Linkto={"/checkout"}
            titleof={"Order Now"}
            CustomStyle={"!justify-end box"}
          />
        </div>
        <div className="flex flex-wrap items-center">
          <div
            className={`w-2/5 imgs ${
              isVisible ? "animate__animated animate__fadeInLeft" : ""
            }`}
          >
            <img src={assets.whysave} alt="" className=" w-4/5" />
          </div>
          <div
            className={`w-3/5 flex flex-col gap-5 ${
              isVisible ? "animate__animated animate__fadeInRight" : ""
            }`}
          >
            <AccordionItem
              title="Cutting-Edge Technology
              Applied with Deep Expertise"
              isOpen={openIndex === 0}
              onClick={() => handleToggle(0)}
              customStyle={"box"}
            >
              <p>
                In the rapidly evolving field of stem cell therapies, CellSave
                is always using the latest, most advanced technologies to ensure
                the highest quality and recovery rates in the industry. From our
                superior processing systems, to our multiple-chamber storage
                bag, that ensures that your newborn’s stem cells can be used for
                several treatments, we lead the industry with the newest
                possibilities for today and the future. CellSave Arabia is the
                #1 choice of OB/GYNs and expectant parents in the region, making
                us the most trusted and recommended facility.
              </p>
            </AccordionItem>
            <AccordionItem
              title="Unparalleled Quality
              That Offer Peace of Mind"
              isOpen={openIndex === 1}
              onClick={() => handleToggle(1)}
              customStyle={"box"}
            >
              <p>This is the content for section 2.</p>
            </AccordionItem>
            <AccordionItem
              title="Personal Care to Serve
              Your Family Better"
              isOpen={openIndex === 2}
              customStyle={"box"}
              onClick={() => handleToggle(2)}
            >
              <p>This is the content for section 3.</p>
            </AccordionItem>
          </div>
        </div>
      </ParentContainer>
    </div>
  );
};

export default WhySelect;
