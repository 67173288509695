import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import ParentContainer from "./ParentContainer";
import { Waypoint } from "react-waypoint";
import "animate.css";
import { Link } from "react-router-dom";
import Button from "./Button";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const MainValuable = ({
  HeadingBlack,
  HeadingBlue,
  HeadingPara,
  isButton,
  ValueList,
}) => {
  const BulletIcon = (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="30"
        height="30"
        rx="15"
        fill="url(#paint0_linear_590_6015)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_590_6015"
          x1="-2.84483"
          y1="3.33333"
          x2="35.2274"
          y2="6.02122"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8884D5" />
          <stop offset="1" stop-color="#CF0072" />
        </linearGradient>
      </defs>
    </svg>
  );

  const [isVisible, setIsVisible] = useState(false);

  const handleEnter = () => {
    setIsVisible(true);
  };

  const main = useRef();

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const boxes = gsap.utils.toArray(".box");
      boxes.forEach((box) => {
        gsap.fromTo(
          box,
          { x: 200, opacity: 0 },
          {
            opacity: 1,
            x: 0,
            scrollTrigger: {
              trigger: box,
              start: "bottom 60%",
              end: "top 60%",
              scrub: 1,
              // markers: true,
            },
          }
        );
      });

      const circles = gsap.utils.toArray(".circle2");
      circles.forEach((circle) => {
        gsap.fromTo(
          circle,
          { x: 150, y: 200, opacity: 0 },
          {
            x: 0,
            y: 0,
            opacity: 1,
            scrollTrigger: {
              trigger: circle,
              start: "bottom 100%",
              end: "top 70%",
              scrub: 4,
              //  markers: true,
            },
          }
        );
      });

      const box2s = gsap.utils.toArray(".box2");
      box2s.forEach((boxs2) => {
        gsap.fromTo(
          boxs2,
          { x: 200, opacity: 0 },
          {
            x: 0,
            opacity: 1,
            scrollTrigger: {
              trigger: boxs2,
              start: "bottom 60%",
              end: "top 47.5%",
              scrub: 1,
              markers: true,
            },
          }
        );
      });
    }, main);

    return () => ctx.revert();
  }, []);

  return (
    <div ref={main}>
      <ParentContainer containerStyle={"flex flex-wrap"}>
        <div
          className={`w-1/2 sticky top-0 pr-10  ${
            isVisible ? "animate__animated animate__fadeInLeft" : ""
          }`}
        >
          <div className="sticky top-2/4">
            <h2 className="text-5xl font-bold text-left pb-4">
              {HeadingBlack}{" "}
              <span className="text-custom-primary">{HeadingBlue}</span>
            </h2>
            <p className=" text-base">{HeadingPara}</p>
            {isButton ? (
              <Button
                Linkto={"/stem-cells-sceince#medTabs"}
                titleof={"Read More"}
                CustomStyle={"!items-center !px-0  !justify-start !pt-5"}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <div
          ref={main}
          className={`w-1/2 ${
            isVisible ? "animate__animated animate__fadeInRight" : ""
          }`}
        >
          {ValueList.map((value, index) => (
            <div
              key={index}
              className="p-5 pb-6 flex items-center border-b box2 border-[#EAECF0]"
            >
              <div id="icon" className="w-1/12">
                {BulletIcon}
              </div>
              <p
                className="text-lg font-semibold w-11/12 "
                dangerouslySetInnerHTML={{ __html: value }}
              ></p>
            </div>
          ))}
        </div>
      </ParentContainer>
    </div>
  );
};

export default MainValuable;
