import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import ParentContainer from "../../components/ParentContainer";
import { Waypoint } from "react-waypoint";
import "animate.css";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const ThreeBox = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const handleEnter = () => {
    setIsVisible(true);
  };

  const main = useRef();
  const parentRef = useRef();

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const boxes = gsap.utils.toArray(".box");
      boxes.forEach((box) => {
        gsap.fromTo(
          box,
          { y: 200, scale: 0 },
          {
            y: 0,
            scale: 1,

            scrollTrigger: {
              trigger: box,
              start: "bottom 100%",
              end: "top 100%",
              scrub: 4,
              //  markers: true,
            },
          }
        );
      });

      const circles = gsap.utils.toArray(".circle");
      circles.forEach((circle) => {
        gsap.fromTo(
          circle,
          { x: 500, scale: 0 },
          {
            x: 0,
            scale: 1,
            scrollTrigger: {
              trigger: circle,
              start: "bottom 85%",
              end: "top 50%",
              scrub: 4,
              //  markers: true,
            },
          }
        );
      });

      const images = gsap.utils.toArray(".imgs");
      gsap.from(images, {
        opacity: 0,
        y: 50,
        stagger: 0.2,
        scrollTrigger: {
          trigger: parentRef.current,
          start: "top center",
          end: "bottom center",
          scrub: 1,
          toggleActions: "play none none none",
        },
      });
    }, main);

    return () => ctx.revert();
  }, []);
  return (
    <div ref={main}>
      <ParentContainer containerStyle={"flex-col flex gap-12 bg-white"}>
        <div className="flex justify-center flex-wrap box">
          <h2 className="text-5xl font-bold text-left  w-1/2 ">
            Newborn Stem Cell <br />
            <span className="text-custom-primary">Banking</span>
          </h2>
          <p className="w-1/2 flex items-center">
            During your pregnancy, your umbilical cord serves as a lifeline that
            helps your baby grow and develop. It’s also a precious resource of
            newborn stem cells that come from the cord blood and perinatal
            tissues.
          </p>
        </div>
        <div className="grid grid-cols-3 gap-5" ref={parentRef}>
          <div
            id="iconboxesrotate"
            className={`w-full imgs bg-[#F7F6FF] p-5 text-center rounded-xl flex flex-col gap-5 hover:border-custom-primary  ${
              isVisible ? "animate__animated animate__fadeInUp " : ""
            }`}
          >
            <div
              id="icon"
              className="flex justify-center
              "
            >
              <svg
                width="91"
                height="90"
                viewBox="0 0 91 90"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M55.826 3.46191C38.2975 3.46191 24.0879 17.6715 24.0879 35.2001C24.0879 36.7747 24.2027 38.3232 24.4245 39.8376C13.137 41.548 4.4873 51.2908 4.4873 63.0551C4.4873 76.0248 15.0013 86.5388 27.971 86.5388C39.7354 86.5388 49.4781 77.8891 51.1885 66.6016C52.7029 66.8234 54.2515 66.9382 55.826 66.9382C73.3546 66.9382 87.5642 52.7286 87.5642 35.2001C87.5642 17.6715 73.3546 3.46191 55.826 3.46191Z"
                  fill="#8884D5"
                />
                <path
                  d="M28.3655 51.459C21.9613 51.459 16.7695 56.6507 16.7695 63.055C16.7695 69.4593 21.9613 74.651 28.3655 74.651C34.7698 74.651 39.9616 69.4593 39.9616 63.055C39.9616 56.6507 34.7698 51.459 28.3655 51.459Z"
                  fill="white"
                />
                <path
                  d="M55.8264 12.6514C43.6776 12.6514 33.8291 22.4998 33.8291 34.6486C33.8291 46.7973 43.6776 56.6458 55.8264 56.6458C67.9751 56.6458 77.8236 46.7973 77.8236 34.6486C77.8236 22.4998 67.9751 12.6514 55.8264 12.6514Z"
                  fill="white"
                />
                <path
                  d="M55.8257 22.5605C53.1131 22.5605 50.9141 24.7596 50.9141 27.4722C50.9141 30.1848 53.1131 32.3838 55.8257 32.3838C58.5383 32.3838 60.7374 30.1848 60.7374 27.4722C60.7374 24.7596 58.5383 22.5605 55.8257 22.5605Z"
                  fill="white"
                />
                <path
                  d="M29.11 56.3408C26.8736 56.3408 25.0605 58.1538 25.0605 60.3903C25.0605 62.6267 26.8736 64.4397 29.11 64.4397C31.3464 64.4397 33.1594 62.6267 33.1594 60.3903C33.1594 58.1538 31.3464 56.3408 29.11 56.3408Z"
                  fill="white"
                />
                <path
                  d="M55.8258 36.25C51.8753 36.25 48.6729 39.4525 48.6729 43.4029C48.6729 47.3534 51.8753 50.5557 55.8258 50.5557C59.7762 50.5557 62.9786 47.3534 62.9786 43.4029C62.9786 39.4525 59.7762 36.25 55.8258 36.25Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M55.8262 22.5508C52.8668 22.5508 50.4678 24.9498 50.4678 27.9092C50.4678 30.8686 52.8668 33.2677 55.8262 33.2677C58.7857 33.2677 61.1847 30.8686 61.1847 27.9092C61.1847 24.9498 58.7857 22.5508 55.8262 22.5508ZM53.237 27.9092C53.237 26.4792 54.3962 25.32 55.8262 25.32C57.2563 25.32 58.4155 26.4792 58.4155 27.9092C58.4155 29.3393 57.2563 30.4985 55.8262 30.4985C54.3962 30.4985 53.237 29.3393 53.237 27.9092Z"
                  fill="#8884D5"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M55.8257 35.3096C51.482 35.3096 47.9609 38.8308 47.9609 43.1743C47.9609 47.518 51.482 51.0391 55.8257 51.0391C60.1692 51.0391 63.6904 47.518 63.6904 43.1743C63.6904 38.8308 60.1692 35.3096 55.8257 35.3096ZM50.7302 43.1743C50.7302 40.3601 53.0115 38.0788 55.8257 38.0788C58.6399 38.0788 60.9212 40.3601 60.9212 43.1743C60.9212 45.9886 58.6399 48.2698 55.8257 48.2698C53.0115 48.2698 50.7302 45.9886 50.7302 43.1743Z"
                  fill="#8884D5"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M55.826 12.5254C43.3031 12.5254 33.1514 22.6772 33.1514 35.2001C33.1514 47.7229 43.3031 57.8747 55.826 57.8747C68.3488 57.8747 78.5007 47.7229 78.5007 35.2001C78.5007 22.6772 68.3488 12.5254 55.826 12.5254ZM35.9206 35.2001C35.9206 24.2066 44.8325 15.2946 55.826 15.2946C66.8195 15.2946 75.7315 24.2066 75.7315 35.2001C75.7315 46.1935 66.8195 55.1054 55.826 55.1054C44.8325 55.1054 35.9206 46.1935 35.9206 35.2001Z"
                  fill="#8884D5"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M25.0605 60.3903C25.0605 58.1538 26.8736 56.3408 29.11 56.3408C31.3464 56.3408 33.1594 58.1538 33.1594 60.3903C33.1594 62.6267 31.3464 64.4397 29.11 64.4397C26.8736 64.4397 25.0605 62.6267 25.0605 60.3903ZM29.11 59.1101C28.4029 59.1101 27.8298 59.6833 27.8298 60.3903C27.8298 61.0972 28.4029 61.6705 29.11 61.6705C29.817 61.6705 30.3902 61.0972 30.3902 60.3903C30.3902 59.6833 29.817 59.1101 29.11 59.1101Z"
                  fill="#8884D5"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M27.9709 50.5752C21.0785 50.5752 15.4912 56.1625 15.4912 63.0549C15.4912 69.9472 21.0785 75.5345 27.9709 75.5345C34.8632 75.5345 40.4506 69.9472 40.4506 63.0549C40.4506 56.1625 34.8632 50.5752 27.9709 50.5752ZM18.2604 63.0549C18.2604 57.692 22.608 53.3444 27.9709 53.3444C33.3339 53.3444 37.6813 57.692 37.6813 63.0549C37.6813 68.4179 33.3339 72.7653 27.9709 72.7653C22.608 72.7653 18.2604 68.4179 18.2604 63.0549Z"
                  fill="#8884D5"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M24.0879 35.2001C24.0879 17.6715 38.2975 3.46191 55.826 3.46191C73.3546 3.46191 87.5642 17.6715 87.5642 35.2001C87.5642 52.7286 73.3546 66.9382 55.826 66.9382C54.2515 66.9382 52.7029 66.8234 51.1885 66.6016C49.4781 77.8891 39.7354 86.5388 27.971 86.5388C15.0013 86.5388 4.4873 76.0248 4.4873 63.0551C4.4873 51.2908 13.137 41.548 24.4245 39.8376C24.2027 38.3232 24.0879 36.7747 24.0879 35.2001ZM55.826 6.23114C39.8269 6.23114 26.8571 19.201 26.8571 35.2001C26.8571 37.1082 27.0414 38.9719 27.3928 40.7749C27.4679 41.1603 27.3758 41.5595 27.1394 41.8731C26.9031 42.1867 26.5447 42.3853 26.1536 42.4192C15.5652 43.3388 7.25654 52.2274 7.25654 63.0551C7.25654 74.4955 16.5308 83.7696 27.971 83.7696C38.7987 83.7696 47.6874 75.4611 48.6069 64.8727C48.641 64.4814 48.8394 64.123 49.153 63.8867C49.4666 63.6503 49.8658 63.5582 50.2513 63.6334C52.0542 63.9847 53.9179 64.169 55.826 64.169C71.8251 64.169 84.795 51.1992 84.795 35.2001C84.795 19.201 71.8251 6.23114 55.826 6.23114Z"
                  fill="#8884D5"
                />
              </svg>
            </div>
            <p className=" font-semibold text-xl">
              The stem cells in your baby’s cord blood and tissues have
              extraordinary healing capabilities.
            </p>
          </div>
          <div
            id="iconboxesrotate"
            className={`w-full imgs bg-[#F7F6FF] p-5 text-center rounded-xl flex flex-col gap-5 hover:border-custom-primary  ${
              isVisible
                ? "animate__animated animate__fadeInUp animate__delay-1s "
                : ""
            }`}
          >
            <div id="icon" className="flex justify-center">
              <svg
                width="91"
                height="90"
                viewBox="0 0 91 90"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_590_4844)">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M45.9753 89.9581C21.1853 89.9581 1.01855 69.7892 1.01855 45.0003C1.01855 20.2113 21.1858 0.0429688 45.9753 0.0429688C70.7648 0.0429688 90.9326 20.2108 90.9326 45.0003C90.9326 69.7902 70.7648 89.9581 45.9753 89.9581ZM45.9753 2.15024C22.3476 2.15024 3.1253 21.3731 3.1253 45.0003C3.1253 68.628 22.3481 87.8508 45.9753 87.8508C69.603 87.8508 88.8253 68.628 88.8253 45.0003C88.8253 21.3725 69.6025 2.15024 45.9753 2.15024Z"
                    fill="#8884D5"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M45.9755 81.9315C25.611 81.9315 9.04492 65.3633 9.04492 44.9999C9.04492 24.6359 25.612 8.06934 45.9755 8.06934C66.3389 8.06934 82.9066 24.6364 82.9066 44.9999C82.9066 65.3633 66.3384 81.9315 45.9755 81.9315ZM45.9755 10.1771C26.7743 10.1771 11.1522 25.7992 11.1522 45.0004C11.1522 64.2016 26.7738 79.8237 45.9755 79.8237C65.1767 79.8237 80.7982 64.2021 80.7982 45.0004C80.7982 25.7992 65.1767 10.1771 45.9755 10.1771Z"
                    fill="#8884D5"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M43.3037 72.9599C40.4149 72.9599 37.2587 71.9374 34.4169 70.0812C23.4887 62.9435 24.112 49.4287 35.1741 49.4287C38.0629 49.4287 41.2191 50.4512 44.061 52.3075C54.9892 59.4451 54.3685 72.9599 43.3037 72.9599ZM35.1741 51.5381C26.1323 51.5381 26.5763 62.4447 35.5691 68.3177C39.9313 71.1664 46.791 72.373 49.2058 67.8194C51.2983 63.8743 48.4733 57.708 42.9082 54.0736C40.4054 52.4377 37.6579 51.5381 35.1741 51.5381Z"
                    fill="#8884D5"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M38.3803 59.1819C34.2618 59.1819 34.2607 52.9229 38.3803 52.9229C42.4984 52.9229 42.4984 59.1819 38.3803 59.1819ZM38.3803 55.0296C37.0346 55.0296 37.0346 57.0731 38.3803 57.0731C39.7251 57.0731 39.7251 55.0296 38.3803 55.0296Z"
                    fill="#8884D5"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M38.8768 68.0825C32.0086 68.0825 30.8964 60.0215 34.1565 60.0215C35.1743 60.0215 38.207 62.3692 39.9525 61.0946C41.6464 59.858 44.9449 59.375 44.9449 62.0138C44.9449 65.3598 42.2233 68.0825 38.8768 68.0825ZM34.5325 62.3787C34.9607 67.2836 42.6241 67.1217 42.834 62.1847C40.6281 62.3661 40.3591 65.1077 34.5325 62.3787Z"
                    fill="#8884D5"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M33.6672 46.1965C27.3612 46.1965 22.5835 43.141 21.4966 38.4108C20.001 31.9044 26.0196 24.2394 34.9123 21.3242C42.6769 18.7787 52.2551 20.4662 53.9785 27.9646C55.4735 34.472 49.4549 42.137 40.5628 45.0516C38.244 45.8115 35.9237 46.197 33.6672 46.197V46.1965ZM41.8094 22.2856C39.776 22.2856 37.6766 22.6357 35.5699 23.326C27.6845 25.9111 22.2934 32.466 23.5511 37.9382C24.9702 44.1092 33.4099 45.1777 39.9068 43.0488C47.7922 40.4642 53.1832 33.9099 51.9255 28.4365C51.0659 24.7003 47.095 22.2861 41.8094 22.2861V22.2856Z"
                    fill="#8884D5"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M39.0755 30.3703C33.9423 30.3703 33.9423 22.5703 39.0755 22.5703C44.2081 22.5703 44.2081 30.3703 39.0755 30.3703ZM39.0755 24.6776C36.7156 24.6776 36.7161 28.263 39.0755 28.263C41.4353 28.263 41.4343 24.6776 39.0755 24.6776Z"
                    fill="#8884D5"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M39.0403 41.4546C30.1234 41.4546 28.708 31.1855 32.5682 31.1855C33.8038 31.1855 38.1359 34.4841 40.7468 32.5809C42.6326 31.2061 46.9711 30.1788 46.9705 33.5249C46.97 37.8981 43.4131 41.4546 39.0403 41.4546ZM32.68 33.3999C32.3646 34.184 33.2342 39.3483 39.0403 39.3483C43.1694 39.3483 45.2751 35.2029 44.849 33.3767C40.5158 33.6693 41.3237 37.5506 32.6805 33.3999H32.68Z"
                    fill="#8884D5"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M59.4842 61.9916C51.372 61.9916 48.9937 52.5817 54.3262 44.4163C60.6654 34.7104 72.4141 33.7892 74.6469 42.4487C76.8117 50.8457 68.2339 61.9916 59.4836 61.9916H59.4842ZM66.9435 38.5938C59.6735 38.5938 51.9806 48.3603 53.8205 55.5026C55.6166 62.4742 65.2222 60.7371 70.3348 52.9097C74.5631 46.436 73.4731 38.5938 66.9435 38.5938Z"
                    fill="#8884D5"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M58.0727 53.2278C53.9536 53.2278 53.9536 46.9688 58.0727 46.9688C62.1913 46.9688 62.1918 53.2278 58.0727 53.2278ZM58.0727 49.076C56.7275 49.076 56.7285 51.119 58.0727 51.119C59.4169 51.119 59.418 49.076 58.0727 49.076Z"
                    fill="#8884D5"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M64.0345 56.0836C62.7699 56.0836 61.6161 54.8675 62.1909 53.6768C63.1733 51.6412 63.9833 49.7143 63.1158 48.5246C61.9388 46.9104 61.354 43.5322 64.035 43.5322C67.5123 43.5322 70.1042 46.2096 70.1042 50.0154C70.1042 53.3609 67.3816 56.0836 64.035 56.0836H64.0345ZM64.1995 45.6432C64.3936 47.8712 67.1268 48.1354 64.391 53.96C69.2974 53.5202 69.154 45.8483 64.1995 45.6432Z"
                    fill="#8884D5"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M45.975 8.06985C66.3385 8.06985 82.9061 24.6369 82.9061 45.0004C82.9061 65.3639 66.3379 81.932 45.975 81.932C25.6105 81.932 9.04446 65.3639 9.04446 45.0004C9.04446 24.6364 25.6116 8.06985 45.975 8.06985ZM3.125 45.0004C3.125 68.6281 22.3478 87.851 45.975 87.851C69.6027 87.851 88.825 68.6281 88.825 45.0004C88.825 21.3727 69.6022 2.15039 45.975 2.15039C22.3473 2.15039 3.125 21.3732 3.125 45.0004Z"
                    fill="#8884D5"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M39.0758 30.3709C33.9426 30.3709 33.9426 22.5709 39.0758 22.5709C44.2084 22.5709 44.2084 30.3709 39.0758 30.3709ZM51.9256 28.4366C51.0661 24.7003 47.0951 22.2861 41.8096 22.2861C39.7761 22.2861 37.6767 22.6363 35.57 23.3266C27.6846 25.9116 22.2935 32.4665 23.5513 37.9388C24.9703 44.1098 33.41 45.1782 39.9069 43.0493C47.7923 40.4648 53.1833 33.9104 51.9256 28.4371V28.4366ZM39.041 41.4541C30.1241 41.4541 28.7087 31.1851 32.5689 31.1851C33.8044 31.1851 38.1366 34.4836 40.7475 32.5804C42.6333 31.2057 46.9717 30.1784 46.9712 33.5244C46.9707 37.8977 43.4137 41.4541 39.041 41.4541Z"
                    fill="#8884D5"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M35.1746 51.5381C26.1328 51.5381 26.5768 62.4447 35.5696 68.3177C39.9318 71.1664 46.7915 72.373 49.2063 67.8194C51.2988 63.8743 48.4738 57.708 42.9087 54.0736C40.4059 52.4377 37.6584 51.5381 35.1746 51.5381ZM38.3804 59.1825C34.2618 59.1825 34.2608 52.9234 38.3804 52.9234C42.4984 52.9234 42.4984 59.1825 38.3804 59.1825ZM38.8777 68.083C32.0095 68.083 30.8973 60.022 34.1574 60.022C35.1752 60.022 38.2079 62.3698 39.9535 61.0952C41.6473 59.8586 44.9458 59.3755 44.9458 62.0143C44.9458 65.3603 42.2242 68.083 38.8777 68.083Z"
                    fill="#8884D5"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M66.944 38.5928C59.674 38.5928 51.9811 48.3592 53.821 55.5016C55.6171 62.4731 65.2227 60.736 70.3353 52.9086C74.5636 46.4349 73.4736 38.5928 66.944 38.5928ZM64.0346 56.0827C62.77 56.0827 61.6162 54.8667 62.191 53.6759C63.1734 51.6404 63.9834 49.7134 63.116 48.5238C61.9389 46.9095 61.3541 43.5314 64.0351 43.5314C67.5124 43.5314 70.1043 46.2087 70.1043 50.0146C70.1043 53.36 67.3817 56.0827 64.0351 56.0827H64.0346ZM58.0724 53.2271C53.9533 53.2271 53.9533 46.9681 58.0724 46.9681C62.191 46.9681 62.1915 53.2271 58.0724 53.2271Z"
                    fill="#8884D5"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_590_4844">
                    <rect
                      width="90"
                      height="90"
                      fill="white"
                      transform="translate(0.975586)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <p className=" font-semibold text-xl">
              Stem cells work like a “bio-repair kit”, helping to heal and
              restore tissues, and replenish other cells.
            </p>
          </div>
          <div
            id="iconboxesrotate"
            className={`w-full imgs bg-[#F7F6FF] p-5 text-center rounded-xl flex flex-col gap-5 hover:border-custom-primary  ${
              isVisible
                ? "animate__animated animate__fadeInUp animate__delay-2s "
                : ""
            }`}
          >
            <div id="icon" className="flex justify-center">
              <svg
                width="91"
                height="90"
                viewBox="0 0 91 90"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M46.0526 82.9504C66.9102 84.9142 85.4939 65.8331 83.9722 44.9264C82.4833 24.497 66.9513 6.91016 45.956 6.91016C44.9761 6.91016 44.0074 6.95141 43.0498 7.03392L46.0526 82.9504Z"
                  fill="white"
                />
                <path
                  d="M78.049 44.9266C76.6324 25.489 62.5024 8.62565 43.0501 7.03418C23.4959 8.70419 8.88357 27.0142 7.94012 44.9266C6.8327 65.8921 25.237 80.9284 45.9563 82.9428C45.9886 82.9459 46.0206 82.9476 46.0529 82.9507C64.3669 81.2356 79.4247 63.8278 78.049 44.9266Z"
                  fill="#F7F6FF"
                />
                <path
                  d="M46.2305 44.1579C47.0156 43.6004 47.7514 42.9765 48.4297 42.293C49.8447 40.8779 51.1701 39.3451 52.0789 37.691C52.8348 36.3164 53.3059 34.8609 53.3059 33.3193C53.3059 29.925 51.3723 26.5834 48.7812 23.9941C47.5632 22.7567 46.1119 21.7731 44.5112 21.1003C42.9106 20.4274 41.1924 20.0787 39.4561 20.0742C36.0618 20.0742 32.743 21.426 30.152 24.0153C27.5609 26.6062 26.7998 29.925 26.7998 33.3194C26.7998 36.9292 27.4856 40.3192 30.0743 42.8576L46.2305 44.1579Z"
                  fill="#8884D5"
                />
                <path
                  d="M48.2529 45.9461L50.7648 65.2016C52.5281 65.7689 54.3717 66.0469 56.2239 66.0249C60.3442 66.0249 64.5384 65.4659 67.2383 62.766C69.9384 60.0659 71.0563 55.8718 71.0563 51.7515C71.0806 49.7191 70.6812 47.7039 69.8833 45.8345C69.0854 43.9651 67.9067 42.2825 66.4223 40.894C64.9379 39.5056 63.1804 38.4417 61.262 37.7703C59.3435 37.0989 57.3062 36.8347 55.2799 36.9945L48.2529 45.9461Z"
                  fill="#8884D5"
                />
                <path
                  d="M59.2611 48.6809C59.7746 48.6755 60.2839 48.7722 60.7597 48.9654C61.2354 49.1585 61.6681 49.4443 62.0325 49.806C62.3968 50.1678 62.6857 50.5983 62.8823 51.0727C63.0788 51.547 63.1792 52.0557 63.1775 52.5692C63.1775 54.7436 61.4373 56.6807 59.2611 56.6807C57.0867 56.6807 55.4941 54.7436 55.4941 52.5692C55.4941 50.393 57.0867 48.6809 59.2611 48.6809Z"
                  fill="#F7F6FF"
                />
                <path
                  d="M41.9189 30.8567C43.2865 32.2243 43.4096 34.5376 42.042 35.9052C40.6744 37.2728 38.4543 37.0566 37.0867 35.689C35.7191 34.3214 35.6436 32.2437 37.0111 30.8743C37.3307 30.5483 37.7119 30.2889 38.1325 30.1114C38.5531 29.9338 39.0048 29.8415 39.4614 29.8399C39.9179 29.8382 40.3703 29.9273 40.7921 30.1018C41.214 30.2764 41.597 30.533 41.9189 30.8567Z"
                  fill="#F7F6FF"
                />
                <path
                  d="M48.3576 64.1236C49.1214 64.5636 49.9279 64.925 50.7647 65.2022C51.9402 62.8477 52.5622 59.9755 52.5622 56.8832C52.5897 52.8184 51.0458 48.9001 48.2528 45.9467C48.9732 45.3976 49.6553 44.7998 50.2942 44.1577C52.1725 42.2792 53.4739 40.6279 54.3892 38.9618C54.7378 38.3309 55.0356 37.6733 55.2798 36.9951C54.1858 37.0784 53.1086 37.3127 52.0788 37.6913C51.1701 39.3455 49.8447 40.8783 48.4296 42.2933C47.7524 42.9766 47.0178 43.6006 46.234 44.1583L46.215 44.1689C46.2201 44.1653 46.2253 44.1619 46.2304 44.1583C45.8389 43.8688 45.435 43.5985 45.0185 43.3476C42.5142 41.8567 39.6512 41.076 36.7367 41.0892C34.7992 41.0893 32.8848 41.5105 31.1262 42.3237C30.7717 42.4861 30.421 42.6642 30.0742 42.8579C30.7748 43.5474 31.56 44.1453 32.4111 44.6372L48.3576 64.1236Z"
                  fill="#8884D5"
                />
                <path
                  d="M46.5314 66.6773C44.1303 69.0785 40.4002 69.5742 36.7369 69.5742C33.0736 69.5742 29.8779 68.5441 27.4785 66.143C25.0773 63.7418 23.3564 60.5461 23.3564 56.8828C23.3564 53.2195 25.3885 50.335 27.7879 47.9355C30.3039 45.4195 33.1925 43.7256 36.7369 43.7256C44.2247 43.7256 49.9258 49.5477 49.9258 56.8828C49.9258 60.399 48.996 64.2128 46.5314 66.6773Z"
                  fill="#8884D5"
                />
                <path
                  d="M36.7361 53.427C37.1926 53.4221 37.6454 53.508 38.0683 53.6796C38.4913 53.8512 38.8759 54.1052 39.1999 54.4267C39.5239 54.7482 39.7808 55.1309 39.9556 55.5526C40.1304 55.9742 40.2197 56.4264 40.2184 56.8828C40.2184 58.8182 38.6715 60.5408 36.7361 60.5408C34.8025 60.5408 33.3857 58.8182 33.3857 56.8828C33.3857 54.9492 34.8025 53.427 36.7361 53.427Z"
                  fill="#F7F6FF"
                />
                <path
                  d="M36.7367 52.5473C34.3649 52.5473 32.5068 54.452 32.5068 56.8837C32.5068 59.3848 34.4044 61.4197 36.7367 61.4197C39.1009 61.4197 41.0981 59.3425 41.0981 56.8837C41.1027 56.3109 40.9928 55.7429 40.7749 55.2131C40.557 54.6833 40.2355 54.2023 39.8293 53.7984C39.4231 53.3945 38.9403 53.0757 38.4092 52.8609C37.8782 52.6461 37.3096 52.5394 36.7367 52.5473ZM36.7367 59.6619C35.3736 59.6619 34.2646 58.4156 34.2646 56.8837C34.2646 55.4137 35.3274 54.3051 36.7367 54.3051C37.078 54.2998 37.4169 54.3626 37.7336 54.49C38.0502 54.6174 38.3383 54.8067 38.5808 55.0469C38.8233 55.2871 39.0154 55.5733 39.1458 55.8887C39.2762 56.2041 39.3424 56.5424 39.3403 56.8837C39.3403 58.3896 38.148 59.6619 36.7367 59.6619Z"
                  fill="#8884D5"
                />
                <path
                  d="M43.8839 33.3518C43.8039 30.9611 41.9111 28.9544 39.4598 28.961C37.0992 28.9727 35.0915 30.9225 35.1436 33.3011C35.1944 35.6227 37.1786 37.5598 39.5106 37.7005C42.0261 37.852 43.9679 35.8482 43.8839 33.3518ZM36.901 33.2626C36.8709 31.8945 38.0642 30.7258 39.4686 30.7188H39.4815C40.9455 30.7188 42.0784 31.9525 42.1271 33.4107C42.1765 34.8845 41.0824 36.0341 39.6166 35.9459C38.2116 35.8611 36.9317 34.6773 36.901 33.2626Z"
                  fill="#8884D5"
                />
                <path
                  d="M53.6537 36.3364C55.28 31.6794 52.9033 26.8727 49.4024 23.3717C43.9381 17.9071 35.2331 17.6901 29.5295 23.3939C27.1012 25.8223 25.9207 29.069 25.9207 33.3194C25.9207 37.9178 26.9983 41.7542 30.6833 44.5374C29.3975 45.3092 28.2155 46.242 27.1659 47.3131C25.1523 49.3266 22.4756 52.5092 22.4756 56.8833C22.4756 60.4306 24.031 63.9397 26.8554 66.764C29.3034 69.212 32.6276 70.4532 36.736 70.4532C41.7947 70.4532 46.0998 69.5859 48.7069 65.3041C50.8586 66.3648 53.3792 66.9036 56.2235 66.9036C60.0039 66.9036 64.7429 66.5039 67.8589 63.3879C70.4874 60.7595 71.935 56.6272 71.935 51.7524C71.935 41.5252 62.6623 34.5098 53.6537 36.3364ZM27.6785 33.3194C27.6785 29.5589 28.6906 26.7188 30.7723 24.6369C35.7559 19.6535 43.3459 19.801 48.1596 24.6147C50.9109 27.3663 52.4264 30.4576 52.4264 33.3194C52.4264 35.9013 51.0018 38.4772 47.8073 41.6717C46.6214 42.8926 45.2241 43.8884 43.6829 44.6107C40.4418 42.8162 36.1709 42.2053 32.4898 43.6546C28.7603 41.3016 27.6785 37.7534 27.6785 33.3194ZM45.9082 66.0555C44.0824 67.8812 41.2536 68.6954 36.736 68.6954C33.1109 68.6954 30.2046 67.6274 28.0982 65.521C26.335 63.7579 24.2334 60.8281 24.2334 56.8833C24.2334 54.0508 25.5602 51.4048 28.4087 48.5561C31.0678 45.8971 33.7915 44.6045 36.736 44.6045C43.788 44.6045 49.0462 50.0815 49.0462 56.8833C49.0462 60.7746 47.9318 64.0319 45.9082 66.0555ZM66.6161 62.1448C64.5407 64.2202 61.3354 65.1457 56.2235 65.1457C53.6634 65.1457 51.4113 64.6719 49.5075 63.739C50.3536 61.7729 50.804 59.4438 50.804 56.8833C50.804 52.4031 48.7989 48.3486 45.384 45.7258C48.2328 44.1481 51.2604 40.8681 52.69 38.4078C60.3199 35.8628 70.1772 41.6126 70.1772 51.7524C70.1772 56.1577 68.9124 59.8484 66.6161 62.1448Z"
                  fill="#8884D5"
                />
                <path
                  d="M59.2617 47.8012C56.6122 47.8012 54.6143 49.8507 54.6143 52.5684C54.6143 55.3209 56.6991 57.5602 59.2617 57.5602C61.8609 57.5602 64.057 55.2742 64.057 52.5684C64.0619 51.9386 63.9411 51.3142 63.7015 50.7317C63.4619 50.1493 63.1084 49.6205 62.6617 49.1765C62.2151 48.7325 61.6843 48.3821 61.1004 48.1459C60.5165 47.9098 59.8914 47.7926 59.2617 47.8012ZM59.2617 55.8024C57.6683 55.8024 56.3721 54.3517 56.3721 52.5684C56.3721 50.8528 57.6144 49.559 59.2617 49.559C59.6599 49.5529 60.0553 49.6263 60.4248 49.775C60.7942 49.9237 61.1303 50.1447 61.4132 50.425C61.6961 50.7053 61.9202 51.0393 62.0724 51.4074C62.2245 51.7754 62.3016 52.1702 62.2992 52.5684C62.2992 54.3214 60.9082 55.8024 59.2617 55.8024Z"
                  fill="#8884D5"
                />
                <path
                  d="M85.3466 44.8253C83.7841 23.3641 67.3999 5.53027 45.9551 5.53027C24.5241 5.53027 7.60947 24.9932 6.56196 44.8528C5.42104 66.4761 24.2055 82.2126 45.8217 84.3142C56.2849 85.3315 66.4392 81.2484 73.9005 73.9658C81.5418 66.5072 86.1535 55.9029 85.3466 44.8253ZM71.9741 71.9922C64.8044 78.9904 55.3665 82.4715 46.0885 81.5693C36.7344 80.6599 27.2464 76.8319 20.2474 70.57C13.2572 64.316 8.75438 55.6422 9.31598 44.9981C10.2524 27.2455 25.2361 8.28809 45.9551 8.28809C65.2885 8.28809 81.0683 24.0444 82.5962 45.0257C83.3127 54.8656 79.4487 64.6966 71.9741 71.9922Z"
                  fill="#8884D5"
                  stroke="#8884D5"
                />
              </svg>
            </div>
            <p className=" font-semibold text-xl">
              A single cell can replicate and become many cell types.
            </p>
          </div>
        </div>
      </ParentContainer>
    </div>
  );
};

export default ThreeBox;
