import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import assets from "../../assets/assets";
import ParentContainer from "../../components/ParentContainer";
import { Link } from "react-router-dom";
import { Waypoint } from "react-waypoint";
import "animate.css";
import CountUp from "react-countup";
import Button from "../../components/Button";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const About = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isCounting, setIsCounting] = useState(false);
  const [isNumbersss, setIsNumbersss] = useState(false);

  const handleEnter = () => {
    setIsVisible(true);
  };

  if (isVisible) {
    setTimeout(() => {
      setIsCounting(true);
    }, 1500);
  }

  const main = useRef();
  const main2 = useRef();
  const main3 = useRef();

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const boxes = gsap.utils.toArray(".box");
      boxes.forEach((box) => {
        gsap.fromTo(
          box,
          { y: 200, scale: 0 },
          {
            y: 0,
            scale: 1,
            scrollTrigger: {
              trigger: box,
              start: "bottom 95%",
              end: "top 90%",
              scrub: 4,
              //  markers: true,
            },
          }
        );
      });

      const circles = gsap.utils.toArray(".circle");
      circles.forEach((circle) => {
        gsap.fromTo(
          circle,
          { x: 500, scale: 0 },
          {
            x: 0,
            scale: 1,
            scrollTrigger: {
              trigger: circle,
              start: "bottom 85%",
              end: "top 50%",
              scrub: 4,
              //  markers: true,
            },
          }
        );
      });

      const images = gsap.utils.toArray(".imgs");
      images.forEach((imgs) => {
        gsap.fromTo(
          imgs,
          { scale: 0.5, opacity: 0 },
          {
            scale: 1,
            opacity: 1,
            scrollTrigger: {
              trigger: imgs,
              start: "bottom 85%",
              end: "top 50%",
              scrub: 4,
              //markers: true,
            },
          }
        );
      });
    }, main);

    const ctx2 = gsap.context(() => {
      const boxes = gsap.utils.toArray(".box");
      boxes.forEach((box) => {
        gsap.fromTo(
          box,
          { y: 200, scale: 0 },
          {
            y: 0,
            scale: 1,
            scrollTrigger: {
              trigger: box,
              start: "bottom 95%",
              end: "top 90%",
              scrub: 4,
              // markers: true,
            },
          }
        );
      });

      const images = gsap.utils.toArray(".imgs");
      images.forEach((imgs) => {
        gsap.fromTo(
          imgs,
          { scale: 0.5, opacity: 0 },
          {
            scale: 1,
            opacity: 1,
            scrollTrigger: {
              trigger: imgs,
              start: "bottom 85%",
              end: "top 50%",
              scrub: 4,
              //markers: true,
            },
          }
        );
      });
    }, main2);

    const ctx3 = gsap.context(() => {
      const boxes = gsap.utils.toArray(".boxs");
      boxes.forEach((box) => {
        gsap.fromTo(
          box,
          { y: -200, x: 300, scale: 0 },
          {
            y: 0,
            x: 0,
            scale: 1,
            scrollTrigger: {
              trigger: box,
              start: "bottom 65%",
              end: "top 60%",
              scrub: 4,
              onEnter: () => {
                setIsCounting(true);
              },
              // markers: true,
            },
          }
        );
      });

      const images = gsap.utils.toArray(".boxs2");
      images.forEach((imgs) => {
        gsap.fromTo(
          imgs,
          { y: -200, scale: 0 },
          {
            y: 0,

            scale: 1,
            scrollTrigger: {
              trigger: imgs,
              start: "bottom 65%",
              end: "top 60%",
              scrub: 4,
              //markers: true,
            },
          }
        );
      });

      const circles = gsap.utils.toArray(".circle2");
      circles.forEach((circle) => {
        gsap.fromTo(
          circle,
          { y: -200, x: -300, scale: 0 },
          {
            y: 0,
            x: 0,
            scale: 1,
            scrollTrigger: {
              trigger: circle,
              start: "bottom 65%",
              end: "top 60%",
              scrub: 4,
              //  markers: true,
            },
          }
        );
      });
    }, main3);

    return () => ctx.revert();
  }, []);

  return (
    <ParentContainer
      containerStyle={
        "bg-gradient-to-r from-custom-gray to-black flex flex-wrap col justify-center"
      }
      className=""
    >
      <div
        className={`flex justify-center flex-wrap gap-5 py-20 ${
          isVisible ? "animate__animated animate__fadeInUp" : ""
        }`}
        ref={main}
      >
        <Button
          Linkto={"/about"}
          titleof={"About Us"}
          CustomStyle={"box"}
          white={true}
        />

        <h2 className="text-5xl font-bold text-center text-white w-1/2 max-md:w-full mx-5 box">
          The Power of{" "}
          <span class="bg-gradient-to-br from-custom-primary to-custom-secondary text-transparent bg-clip-text ">
            Newborn Stem Cells
          </span>
        </h2>
        <p className="w-1/2 text-center text-xl opacity-80 font-bold text-white max-md:w-full box">
          Preserve your newborn's future by saving stem cells from cord blood,
          cord tissue, and placental tissue—a crucial choice for potential
          family treatments in the future.
        </p>
      </div>
      <div
        className={`w-2/6 pb-10 max-md:w-5/6 ${
          isVisible
            ? "animate__animated animate__fadeInUp animate__delay-1s"
            : ""
        }`}
        ref={main2}
      >
        <img src={assets.introduce} className="w-full imgs" alt="" />
      </div>
      <div
        className={`w-full flex py-20 max-md:flex-wrap  max-md:gap-10 max-md:py-0 ${
          isVisible
            ? "animate__animated animate__fadeInUp animate__delay-1s"
            : ""
        }`}
        ref={main3}
      >
        <div
          className={`w-1/3 flex justify-start flex-wrap col items-center max-md:w-full boxs ${
            isNumbersss ? "animate__animated animate__fadeInTopRight" : ""
          }  `}
        >
          <h3 className="w-full text-center  text-5xl font-bold bg-gradient-to-br from-custom-primary to-custom-secondary text-transparent bg-clip-text ">
            {isCounting && (
              <CountUp start={0} end={3.1} duration={7} decimals={1} />
            )}
            M
          </h3>
          <p className="w-full text-center text-lg font-semibold text-white">
            Advance Treatment
          </p>
        </div>
        <div
          className={`w-1/3 flex justify-start flex-wrap col max-md:w-full boxs2 ${
            isNumbersss ? "animate__animated animate__fadeInDown" : ""
          }`}
        >
          <h3 className="w-full text-center text-5xl font-bold bg-gradient-to-br from-custom-primary to-custom-secondary text-transparent bg-clip-text">
            {isCounting && (
              <CountUp start={0} end={3.1} duration={7} decimals={1} />
            )}
            M
          </h3>
          <p className="w-full text-center text-lg font-semibold text-white">
            Advance Treatment
          </p>
        </div>
        <div
          className={`w-1/3 flex justify-start flex-wrap col max-md:w-full circle2${
            isNumbersss ? "animate__animated animate__fadeInTopLeft" : ""
          }`}
        >
          <h3 className="w-full text-center text-5xl font-bold bg-gradient-to-br from-custom-primary to-custom-secondary text-transparent bg-clip-text">
            {isCounting && (
              <CountUp start={0} end={3.1} duration={7} decimals={1} />
            )}
            M
          </h3>
          <p className="w-full text-center text-lg font-semibold text-white">
            Advance Treatment
          </p>
        </div>
      </div>
    </ParentContainer>
  );
};

export default About;
