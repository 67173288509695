import React from "react";
import ParentContainer from "../../components/ParentContainer";

const Support = () => {
  return (
    <ParentContainer containerStyle={"grid grid-cols-2 gap-5"}>
      <div className=" flex flex-col gap-2">
        <div id="icon">
          <svg
            width="70"
            height="70"
            viewBox="0 0 70 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.9397 52.6822C16.3177 52.6825 14.7158 52.322 13.2503 51.6269C11.7847 50.9318 10.4921 49.9194 9.46606 48.663C8.44004 47.4067 7.70631 45.9378 7.31801 44.3629C6.92971 42.788 6.89657 41.1465 7.22098 39.5572C7.78423 37.0431 9.19705 34.8 11.2213 33.2061C13.2455 31.6122 15.7574 30.765 18.3335 30.8072H21.7679V52.6822H17.9397Z"
              stroke="url(#paint0_linear_590_10542)"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M51.8457 52.6822C53.4678 52.6825 55.0696 52.322 56.5352 51.6269C58.0008 50.9318 59.2934 49.9194 60.3194 48.663C61.3454 47.4067 62.0791 45.9378 62.4674 44.3629C62.8557 42.788 62.8889 41.1465 62.5645 39.5572C62.0012 37.0431 60.5884 34.8 58.5641 33.2061C56.5399 31.6122 54.028 30.765 51.452 30.8072H48.0176V52.6822H51.8457Z"
              stroke="url(#paint1_linear_590_10542)"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M15.2062 30.807V28.2257C15.1273 23.7182 16.5792 19.3172 19.3246 15.7413C22.0701 12.1655 25.9469 9.62625 30.3218 8.53824C33.2212 7.84599 36.2399 7.82116 39.1503 8.46564C42.0607 9.11011 44.7867 10.4071 47.1228 12.2587C49.4589 14.1102 51.344 16.4681 52.636 19.1545C53.928 21.8408 54.5931 24.7855 54.5812 27.7664V30.807"
              stroke="url(#paint2_linear_590_10542)"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M21.7676 52.6816C21.7676 54.4221 22.459 56.0913 23.6897 57.322C24.9204 58.5527 26.5896 59.2441 28.3301 59.2441"
              stroke="url(#paint3_linear_590_10542)"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M34.8926 62.4316C36.1007 62.4316 37.0801 61.4523 37.0801 60.2441C37.0801 59.036 36.1007 58.0566 34.8926 58.0566C33.6845 58.0566 32.7051 59.036 32.7051 60.2441C32.7051 61.4523 33.6845 62.4316 34.8926 62.4316Z"
              fill="url(#paint4_linear_590_10542)"
            />
            <path
              d="M16.2988 31.9004V51.5879"
              stroke="url(#paint5_linear_590_10542)"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M52.3926 30.8066H48.0176V52.6816H52.3926V30.8066Z"
              fill="url(#paint6_linear_590_10542)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_590_10542"
                x1="5.5996"
                y1="33.2364"
                x2="24.3918"
                y2="34.132"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#8884D5" />
                <stop offset="1" stop-color="#CF0072" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_590_10542"
                x1="46.6172"
                y1="33.2364"
                x2="65.4094"
                y2="34.132"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#8884D5" />
                <stop offset="1" stop-color="#CF0072" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_590_10542"
                x1="11.469"
                y1="10.5341"
                x2="60.9566"
                y2="16.5665"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#8884D5" />
                <stop offset="1" stop-color="#CF0072" />
              </linearGradient>
              <linearGradient
                id="paint3_linear_590_10542"
                x1="21.1453"
                y1="53.4108"
                x2="29.4736"
                y2="53.9988"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#8884D5" />
                <stop offset="1" stop-color="#CF0072" />
              </linearGradient>
              <linearGradient
                id="paint4_linear_590_10542"
                x1="32.2902"
                y1="58.5428"
                x2="37.8424"
                y2="58.9347"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#8884D5" />
                <stop offset="1" stop-color="#CF0072" />
              </linearGradient>
              <linearGradient
                id="paint5_linear_590_10542"
                x1="16.204"
                y1="34.0879"
                x2="17.4794"
                y2="34.0925"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#8884D5" />
                <stop offset="1" stop-color="#CF0072" />
              </linearGradient>
              <linearGradient
                id="paint6_linear_590_10542"
                x1="47.6027"
                y1="33.2372"
                x2="53.1815"
                y2="33.316"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#8884D5" />
                <stop offset="1" stop-color="#CF0072" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <h3 className="text-2xl font-bold ">24/7 Phone Support</h3>
        <p className="text-base">
          {" "}
          If you are an expectant parent and wish to speak with one of our
          newborn stem cell educators, press the phone button above. We are
          always available.
        </p>
      </div>
      <div className=" flex flex-col gap-2">
        {" "}
        <div id="icon">
          <svg
            width="60"
            height="60"
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M51.0131 8.71936C45.3996 3.09951 37.9347 0.00325858 29.9812 0C13.5936 0 0.255738 13.3368 0.249341 29.7295C0.247048 34.9698 1.61614 40.0845 4.21805 44.5933L0 60L15.7613 55.8656C20.1039 58.2342 24.9933 59.4827 29.9694 59.4844H29.9815C29.9805 59.4844 29.9824 59.4844 29.9815 59.4844C46.3674 59.4844 59.7064 46.1463 59.7133 29.7533C59.7162 21.8088 56.6264 14.339 51.0131 8.71936ZM29.9814 54.4632H29.9713C25.5373 54.4615 21.188 53.2701 17.3938 51.0186L16.4916 50.4831L7.13858 52.9365L9.63514 43.8175L9.04751 42.8824C6.57389 38.948 5.26744 34.4005 5.26937 29.7312C5.27468 16.1062 16.3606 5.02147 29.9914 5.02147C36.5918 5.02352 42.7965 7.59744 47.4622 12.2684C52.1278 16.9394 54.6958 23.1482 54.6931 29.7512C54.6876 43.3773 43.602 54.4632 29.9814 54.4632Z"
              fill="url(#paint0_linear_590_10551)"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M43.5354 35.9548C42.7926 35.583 39.1401 33.786 38.4591 33.5379C37.7782 33.29 37.2829 33.1661 36.7877 33.9097C36.2925 34.6533 34.8688 36.3268 34.4354 36.8227C34.0021 37.3182 33.5686 37.3806 32.8259 37.0084C32.0831 36.6366 29.6893 35.8522 26.8518 33.3213C24.6432 31.3513 23.1524 28.9187 22.7189 28.1748C22.2856 27.4312 22.6728 27.0292 23.0447 26.6587C23.3789 26.3257 23.7877 25.7911 24.1592 25.3572C24.5305 24.9236 24.6543 24.6134 24.902 24.118C25.1496 23.622 25.0259 23.1882 24.8401 22.8165C24.6543 22.4446 23.1687 18.788 22.5497 17.3003C21.9465 15.8519 21.3341 16.0481 20.878 16.0251C20.4453 16.0035 19.9494 15.999 19.4542 15.999C18.9591 15.999 18.1541 16.185 17.4733 16.9286C16.7923 17.6724 14.873 19.4698 14.873 23.126C14.873 26.7828 17.5351 30.3153 17.9065 30.811C18.2779 31.307 23.145 38.8104 30.5973 42.0283C32.3697 42.7938 33.7535 43.2509 34.8323 43.5932C36.612 44.1587 38.2315 44.0788 39.5115 43.8875C40.9389 43.6743 43.9069 42.0905 44.5259 40.3553C45.145 38.6198 45.145 37.1322 44.9592 36.8226C44.7735 36.5128 44.2782 36.3268 43.5354 35.9548Z"
              fill="url(#paint1_linear_590_10551)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_590_10551"
                x1="3.5"
                y1="30"
                x2="60"
                y2="30"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#8884D5" />
                <stop offset="1" stop-color="#CF0072" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_590_10551"
                x1="14.9991"
                y1="29.9995"
                x2="44.9991"
                y2="29.9995"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#8884D5" />
                <stop offset="1" stop-color="#CF0072" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <h3 className="text-2xl font-bold ">Phone Support</h3>
        <p className="text-base">
          {" "}
          Lorem ipsum dolor sit amet consectetur. Risus vitae arcu amet amet
          auctor nulla nibh. Neque posuere quam id aliquet elementum tellus
          vehicula tellus Consectetur.
        </p>
      </div>
    </ParentContainer>
  );
};

export default Support;
