import React from "react";
import Packages from "../components/Packages";
import HeroSection from "./ServiceAndPricing/HeroSection";
import CellSaveKit from "./ServiceAndPricing/CellSaveKit";
import StemCellBanking from "./ServiceAndPricing/StemCellBanking";
import Sources from "./SourceOfStem/Sources";
import LifeTimeBanner from "../components/LifeTimeBanner";

const ServicesAndPricingPage = () => {
  return (
    <div>
      <HeroSection />
      <Packages />
      <CellSaveKit />
      <StemCellBanking />
      <Sources />
      <LifeTimeBanner Heading={"A gift with life‑saving possibilities"} />
    </div>
  );
};

export default ServicesAndPricingPage;
