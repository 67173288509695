import React from "react";
import ParentContainer from "../components/ParentContainer";
import assets from "../assets/assets";

const FeaturedMomsSingle = () => {
  return (
    <ParentContainer containerStyle={"flex "}>
      <div className="w-1/3">
        <img src={assets.momsingle} alt="" />
      </div>
      <div className="w-2/3 pl-5 flex flex-col gap-4">
        <h3 className="text-3xl font-bold text-custom-primary">
          Mariana Miyaki
        </h3>
        <p className="text-base">
          As a loving mother, Mariana Miyaki cherishes her two children, Peitro
          and Matteo. Her passion for fashion is evident in her impeccable sense
          of style, as she embraces trends and explores her unique fashion
          identity. Mariana is also a fitness enthusiast, finding solace and
          strength in regular gym workouts. Her dedication to both fashion and
          fitness showcases her multifaceted persona and commitment to leading a
          fulfilling life.
        </p>
        <p className="text-base font-bold">
          Have you always been interested in fashion and travelling?    
        </p>
        <p className="text-base">
          As a loving mother, Mariana Miyaki cherishes her two children, Peitro
          and Matteo. Her passion for fashion is evident in her impeccable sense
          of style, as she embraces trends and explores her unique fashion
          identity. Mariana is also a fitness enthusiast, finding solace and
          strength in regular gym workouts. Her dedication to both fashion and
          fitness showcases her multifaceted persona and commitment to leading a
          fulfilling life.
        </p>
      </div>
    </ParentContainer>
  );
};

export default FeaturedMomsSingle;
