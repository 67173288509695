import React from "react";
import ParentContainer from "./ParentContainer";
import { Link } from "react-router-dom";
import Button from "./Button";
const LifeTimeBanner = ({ Heading }) => {
  return (
    <ParentContainer
      containerStyle={
        "bg-gradient-to-r from-custom-primary to-custom-secondary flex items-center"
      }
    >
      <h3 className="text-4xl capitalize text-white font-bold text-left  w-1/2 ">
        {Heading}{" "}
      </h3>
      <Button
        Linkto={"/checkout"}
        titleof={"Order Now"}
        CustomStyle={"!items-center  !justify-end !px-0"}
        white={true}
      />
    </ParentContainer>
  );
};

LifeTimeBanner.defaultProps = {
  Heading: "A Once In A Lifetime Opportunity",
};

export default LifeTimeBanner;
