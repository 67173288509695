import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import assets from "../../assets/assets";
import ParentContainer from "../../components/ParentContainer";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const CellSaveNumbers = () => {
  const parentRef = useRef(null);
  const main = useRef(null);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const boxes = gsap.utils.toArray(".box");
      boxes.forEach((box) => {
        gsap.fromTo(
          box,
          { x: 200, scale: 0 },
          {
            x: 0,
            scale: 1,

            scrollTrigger: {
              trigger: box,
              start: "bottom 100%",
              end: "top 100%",
              scrub: 4,
              //  markers: true,
            },
          }
        );
      });

      const circles = gsap.utils.toArray(".circle");
      circles.forEach((circle) => {
        gsap.fromTo(
          circle,
          { x: 500, scale: 0 },
          {
            x: 0,
            scale: 1,
            scrollTrigger: {
              trigger: circle,
              start: "bottom 85%",
              end: "top 50%",
              scrub: 4,
              //  markers: true,
            },
          }
        );
      });

      const images = gsap.utils.toArray(".imgs");
      gsap.from(images, {
        opacity: 0,
        y: 50,
        stagger: 0.2,
        scrollTrigger: {
          trigger: parentRef.current,
          start: "top center",
          end: "bottom center",
          scrub: 1,
          toggleActions: "play none none none",
        },
      });
    }, main);

    return () => ctx.revert();
  }, []);

  return (
    <div ref={main}>
      <ParentContainer
        containerStyle="flex flex-wrap items-start bg-gradient-to-r from-custom-primary to-custom-secondary justify-center"
        containerHeight="py-36 "
      >
        <h2 className="box text-5xl font-bold text-left text-white  w-full box">
          CellSave in Numbers
        </h2>
        <div ref={parentRef} className="w-full grid grid-cols-5 gap-5 pt-20">
          {[...Array(5)].map((_, index) => (
            <div
              key={index}
              className="imgs w-full p-16 flex justify-center rounded-xl border-white border"
            >
              <img src={assets.Featuredin} alt={`Featured ${index}`} />
            </div>
          ))}
        </div>
      </ParentContainer>
    </div>
  );
};

export default CellSaveNumbers;
