import React from "react";

const Attention = () => {
  return (
    <div className="bg-white p-8 text-[#8884D5]">
      <div className="bg-[#F7F6FF] p-8 rounded-lg">
        <h3 className="text-xl font-bold ">Attenion:</h3>
        <p className="text-base pt-2">
          The United States Food and Drug Administration requires the Maternal
          Health History below to be completed before we can ship your
          collection kit to you. After submitting it, your account will be
          activated, and you will receive your kit within one to three days,
          depending on your due date.
        </p>
      </div>
    </div>
  );
};

export default Attention;
