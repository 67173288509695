import React from "react";
import ResourcesMiniNav from "../components/ResourcesMiniNav";
import BlogIndex from "./Blog/BlogIndex";
import BlogListing from "../components/BlogListing";

const News = () => {
  return (
    <div>
      <ResourcesMiniNav />
      <BlogListing />
    </div>
  );
};

export default News;
