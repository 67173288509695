import React from "react";
import assets from "../assets/assets";
import { Link } from "react-router-dom";
import ParentContainer from "./ParentContainer";

const Footer = () => {
  return (
    <ParentContainer
      containerStyle={
        "bg-gradient-to-r from-custom-gray to-black px-36 pt-36 flex flex-col"
      }
      containerHeight={"py-0"}
    >
      <div className=" ">
        <div className="flex w-full justify-between">
          <div className="flex flex-col gap-5 w-1/5">
            <img src={assets.logofooter} alt="" />
            <p className="py-1 px-2 font-semibold text-white">
              CellSave leads in stem cell services, enabling parents to store
              and use their newborn's cord blood and tissue for medical purposes
            </p>
            <div>
              <ul className="flex gap-5">
                <li>
                  <Link to="https://x.com/i/flow/login?redirect_after_login=%2FArabiaSave">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M23 3.00005C22.0424 3.67552 20.9821 4.19216 19.86 4.53005C19.2577 3.83756 18.4573 3.34674 17.567 3.12397C16.6767 2.90121 15.7395 2.95724 14.8821 3.2845C14.0247 3.61176 13.2884 4.19445 12.773 4.95376C12.2575 5.71308 11.9877 6.61238 12 7.53005V8.53005C10.2426 8.57561 8.50127 8.18586 6.93101 7.39549C5.36074 6.60513 4.01032 5.43868 3 4.00005C3 4.00005 -1 13 8 17C5.94053 18.398 3.48716 19.099 1 19C10 24 21 19 21 7.50005C20.9991 7.2215 20.9723 6.94364 20.92 6.67005C21.9406 5.66354 22.6608 4.39276 23 3.00005Z"
                        stroke="url(#paint0_linear_590_4193)"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_590_4193"
                          x1="-1.08621"
                          y1="4.98373"
                          x2="26.7639"
                          y2="7.39442"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#8884D5" />
                          <stop offset="1" stop-color="#CF0072" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </Link>
                </li>
                <li>
                  <Link to="https://www.instagram.com/cellsavearabia/">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17 2H7C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2Z"
                        stroke="url(#paint0_linear_590_4195)"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M15.9997 11.3701C16.1231 12.2023 15.981 13.0523 15.5935 13.7991C15.206 14.5459 14.5929 15.1515 13.8413 15.5297C13.0898 15.908 12.2382 16.0397 11.4075 15.906C10.5768 15.7723 9.80947 15.3801 9.21455 14.7852C8.61962 14.1903 8.22744 13.4229 8.09377 12.5923C7.96011 11.7616 8.09177 10.91 8.47003 10.1584C8.84829 9.40691 9.45389 8.7938 10.2007 8.4063C10.9475 8.0188 11.7975 7.87665 12.6297 8.00006C13.4786 8.12594 14.2646 8.52152 14.8714 9.12836C15.4782 9.73521 15.8738 10.5211 15.9997 11.3701Z"
                        stroke="url(#paint1_linear_590_4195)"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M17.5 6.5H17.51"
                        stroke="url(#paint2_linear_590_4195)"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_590_4195"
                          x1="0.103448"
                          y1="4.22222"
                          x2="25.4849"
                          y2="6.01415"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#8884D5" />
                          <stop offset="1" stop-color="#CF0072" />
                        </linearGradient>
                        <linearGradient
                          id="paint1_linear_590_4195"
                          x1="7.28435"
                          y1="8.84568"
                          x2="17.4369"
                          y2="9.56245"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#8884D5" />
                          <stop offset="1" stop-color="#CF0072" />
                        </linearGradient>
                        <linearGradient
                          id="paint2_linear_590_4195"
                          x1="17.4991"
                          y1="6.61111"
                          x2="17.5118"
                          y2="6.61112"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#8884D5" />
                          <stop offset="1" stop-color="#CF0072" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </Link>
                </li>
                <li>
                  <Link to="https://www.facebook.com/cellsavearabia/" href="">
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14 26.25C20.7655 26.25 26.25 20.7655 26.25 14C26.25 7.23451 20.7655 1.75 14 1.75C7.23451 1.75 1.75 7.23451 1.75 14C1.75 20.0377 6.11806 25.0553 11.8665 26.0649V17.7464H8.75V14.2888H11.8665V11.6536C11.8665 8.65632 13.698 7 16.5027 7C17.8452 7 19.25 7.23362 19.25 7.23362V10.1772H17.7014C16.1767 10.1772 15.7021 11.1 15.7021 12.0461V14.2888H19.1061L18.562 17.7464H15.7021V26.1052C15.1113 26.1956 14.5076 26.2444 13.8931 26.2495C13.9287 26.2498 13.9643 26.25 14 26.25Z"
                        fill="url(#paint0_linear_590_4199)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_590_4199"
                          x1="-0.573276"
                          y1="4.47222"
                          x2="30.519"
                          y2="6.66733"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#8884D5" />
                          <stop offset="1" stop-color="#CF0072" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="w-2/5"></div>
          <div className="w-1/5">
            <h5 className="text-white text-base font-semibold pb-5">
              Contact Us
            </h5>
            <ul className="flex flex-col gap-2 pt-5">
              <li className="text-[#EAECF0] text-lg">
                <a href="tel:009718002796">
                  24/7 Customer Support <br /> +971 (800) 2796
                </a>
              </li>
              <li className="text-[#EAECF0] text-lg">
                <a href="tel:009718002796">
                  Order By Call <br /> +971 (800) 2700
                </a>
              </li>
            </ul>
          </div>
          <div className="w-auto">
            <h5 className="text-white text-base font-semibold pb-5">
              Contact Us
            </h5>
            <ul className="flex flex-col gap-2 pt-5">
              <li className="text-[#EAECF0] text-lg">
                <Link to="/about">About CellSave</Link>
              </li>
              <li className="text-[#EAECF0] text-lg">
                <Link to="/service-pricing">Services & Pricing</Link>
              </li>
              <li className="text-[#EAECF0] text-lg">
                <Link to="/blog">Resources</Link>
              </li>
              <li className="text-[#EAECF0] text-lg">
                <Link to="/faqs">FAQs</Link>
              </li>
              <li className="text-[#EAECF0] text-lg">
                <Link to="/contact">Contact Us</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className=" py-5 mt-10 flex w-full flex-wrap  text-white border-t border-white">
          <div className="flex divide-x w-1/2  ">
            <h3 className="w-auto pr-5">1999-2023 CellSave Arabia FZ-LLC</h3>
            <h3 className="pl-5">Get Support: +971 (800) 2796</h3>
          </div>
          <div className="flex w-1/2 justify-end content-center">
            <ul className="grid grid-cols-3 divide-x  ">
              <li>
                <Link to="/blog">Resources</Link>
              </li>
              <li className="pl-5">
                <Link to="/faqs">FAQS</Link>
              </li>
              <li className="pl-5">
                <Link to="/contact">Contact Us</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </ParentContainer>
  );
};

export default Footer;
