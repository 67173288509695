import React from "react";
import BlogMainSection from "./BlogSingle/BlogMainSection";
import Preservation from "./BlogSingle/Preservation";
import RelatedBlog from "./BlogSingle/RelatedBlog";

const BlogSingle = () => {
  return (
    <div>
      <BlogMainSection />
      <Preservation />
      <RelatedBlog />
    </div>
  );
};

export default BlogSingle;
