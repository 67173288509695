import React from "react";
import MainHeroSection from "../components/MainHeroSection";
import assets from "../assets/assets";
import MainAdvantagesSection from "../components/MainAdvantagesSection";
import MainValuable from "../components/MainValuable";
import TranslplantNumbers from "../components/TranslplantNumbers";
import LifeTimeBanner from "../components/LifeTimeBanner";

const CordVessel = () => {
  const lineBreak = false;
  const AdvantagePara = true;
  return (
    <div>
      <MainHeroSection
        HeadingBlack={"What is"}
        HeadingBreak={lineBreak}
        HeadingBlue={"Cord Vessel?"}
        HeadingPara={
          "CellSave collects and stores the blood vessels of the umbilical cord, adding an extra layer of protection to our cord blood, cord tissue, placental tissue and amnion placental tissue storage solution."
        }
        IconOne={
          "The study of mesenchymal stem cells falls under the category of regenerative medicine."
        }
        IconTwo={
          "As these cord tissue stem cells assist the body in repairing itself. "
        }
        IconThree={
          "As science and modern medicine continue to advance, so do the possibilities for future treatment possibilities."
        }
        BannerImage={assets.cordVesselBanner}
        BannerText={"More Possibilities"}
      />
      <MainAdvantagesSection
        HeadingBlack={"Cord Vessel"}
        HeadingBlue={"Advantages"}
        TwoImages={false}
        Image={assets.cordTissueAdvantage}
        isPara={AdvantagePara}
        Para={
          "Endothelial progenitor cells (EPCs) have immense clinical value for cardiovascular therapies. With many clinical trials underway around the world, EPCs could soon provide potential treatments for a wide range of very serious diseases, including:"
        }
        Advantages={["Advanced liver cirrhosis", "Ischemic stroke", "IPAH"]}
      />
      <MainValuable
        HeadingBlack={"Why Is It So "}
        HeadingBlue={"Valuable?"}
        HeadingPara={
          "Lorem ipsum dolor sit amet consectetur. Convallis ut elementum magna amet risus commodo. Mauris mauris velit ac quis sem nec libero facilisi elementum. Tortor cursus auctor vitae urna diam. "
        }
        isButton={false}
        ValueList={[
          " Why Is It Valuable <br> Cord vessels are potential life-savers because they contain an abundance of endothelial progenitor cells (EPCs). ",
          "These special stem cells can turn into endothelial cells, which line our blood vessel walls and help in the formation of new blood vessels.",
        ]}
      />
      <TranslplantNumbers backgroundImage={assets.TransplantNumbers} />
      <LifeTimeBanner />
    </div>
  );
};

export default CordVessel;
