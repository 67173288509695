import React, { useEffect } from "react";
import HeroSection from "./StemCellsScience/HeroSection";
import ThreeBox from "./StemCellsScience/ThreeBox";
import Reason from "./StemCellsScience/Reasons";
import Medicine from "./StemCellsScience/Medicine";
import MedTabs from "./StemCellsScience/MedTabs";
import RegMed from "./StemCellsScience/RegMed";
import HowWorks from "./StemCellsScience/HowWorks";
import FAQs from "./StemCellsScience/FAQs";
import MainHeroSection from "../components/MainHeroSection";
import assets from "../assets/assets";
import { useLocation } from "react-router-dom";

const StemCellsSciencePage = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  return (
    <div className="bg-[#FBF7F5]">
      <HeroSection />
      <ThreeBox />
      <Reason />
      <Medicine />
      <MedTabs />
      <RegMed />
      <HowWorks />
      <FAQs />
    </div>
  );
};

export default StemCellsSciencePage;
