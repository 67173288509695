import React from "react";

const History = () => {
  return (
    <div className="pt-10 ">
      <div className="inputContainer">
        <p className="text-base font-bold pb-2">
          1. Have you read and understand the website education materials and
          the Client Service Agreement?*
        </p>
        <div class=" text-center flex flex-wrap justify-start gap-5">
          <div class="flex items-center ">
            <input
              id="radio1"
              type="radio"
              name="Address"
              class="hidden"
              checked
            />
            <label for="radio1" class="flex items-center cursor-pointer">
              <span class="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
              Excellent
            </label>
          </div>
          <div class="flex items-center ">
            <input id="radio2" type="radio" name="Address" class="hidden" />
            <label for="radio2" class="flex items-center cursor-pointer">
              <span class="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
              Good
            </label>
          </div>
        </div>
      </div>
      <div className="inputContainer pt-5">
        <p className="text-base font-bold pb-2">
          1. Have you read and understand the website education materials and
          the Client Service Agreement?*
        </p>
        <div class=" text-center flex flex-wrap justify-start gap-5">
          <div class="flex items-center ">
            <input
              id="radio1"
              type="radio"
              name="Address"
              class="hidden"
              checked
            />
            <label for="radio1" class="flex items-center cursor-pointer">
              <span class="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
              Excellent
            </label>
          </div>
          <div class="flex items-center ">
            <input id="radio2" type="radio" name="Address" class="hidden" />
            <label for="radio2" class="flex items-center cursor-pointer">
              <span class="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
              Good
            </label>
          </div>
        </div>
      </div>
      <div className="inputContainer pt-5">
        <p className="text-base font-bold pb-2">
          1. Have you read and understand the website education materials and
          the Client Service Agreement?*
        </p>
        <div class=" text-center flex flex-wrap justify-start gap-5">
          <div class="flex items-center ">
            <input
              id="radio1"
              type="radio"
              name="Address"
              class="hidden"
              checked
            />
            <label for="radio1" class="flex items-center cursor-pointer">
              <span class="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
              Excellent
            </label>
          </div>
          <div class="flex items-center ">
            <input id="radio2" type="radio" name="Address" class="hidden" />
            <label for="radio2" class="flex items-center cursor-pointer">
              <span class="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
              Good
            </label>
          </div>
        </div>
      </div>
      <div className="inputContainer pt-5">
        <p className="text-base font-bold pb-2">
          1. Have you read and understand the website education materials and
          the Client Service Agreement?*
        </p>
        <div class=" text-center flex flex-wrap justify-start gap-5">
          <div class="flex items-center ">
            <input
              id="radio1"
              type="radio"
              name="Address"
              class="hidden"
              checked
            />
            <label for="radio1" class="flex items-center cursor-pointer">
              <span class="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
              Excellent
            </label>
          </div>
          <div class="flex items-center ">
            <input id="radio2" type="radio" name="Address" class="hidden" />
            <label for="radio2" class="flex items-center cursor-pointer">
              <span class="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
              Good
            </label>
          </div>
        </div>
      </div>
      <div className="inputContainer pt-5">
        <p className="text-base font-bold pb-2">
          1. Have you read and understand the website education materials and
          the Client Service Agreement?*
        </p>
        <div class=" text-center flex flex-wrap justify-start gap-5">
          <div class="flex items-center ">
            <input
              id="radio1"
              type="radio"
              name="Address"
              class="hidden"
              checked
            />
            <label for="radio1" class="flex items-center cursor-pointer">
              <span class="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
              Excellent
            </label>
          </div>
          <div class="flex items-center ">
            <input id="radio2" type="radio" name="Address" class="hidden" />
            <label for="radio2" class="flex items-center cursor-pointer">
              <span class="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
              Good
            </label>
          </div>
        </div>
      </div>
      <div className="inputContainer pt-5">
        <p className="text-base font-bold pb-2">
          6. Have you taken any of the following medications? Select all that
          apply.*
        </p>
        <div class=" text-center flex flex-wrap justify-start border rounded-lg">
          <div class="flex items-center gap-2 p-4 border-b w-full">
            <input type="checkbox" name="" id="insulin" />
            <label for="insulin" class="flex items-center cursor-pointer">
              Insulin from cows (bovine, or beef, insulin)
            </label>
          </div>
          <div class="flex items-center gap-2 p-4 border-b w-full">
            <input type="checkbox" name="" id="insulin" />
            <label for="insulin" class="flex items-center cursor-pointer">
              Insulin from cows (bovine, or beef, insulin)
            </label>
          </div>
          <div class="flex items-center gap-2 p-4 border-b w-full">
            <input type="checkbox" name="" id="insulin" />
            <label for="insulin" class="flex items-center cursor-pointer">
              Insulin from cows (bovine, or beef, insulin)
            </label>
          </div>
          <div class="flex items-center gap-2 p-4  w-full">
            <input type="checkbox" name="" id="insulin" />
            <label for="insulin" class="flex items-center cursor-pointer">
              Insulin from cows (bovine, or beef, insulin)
            </label>
          </div>
        </div>
      </div>
      <div className="inputContainer pt-5">
        <p className="text-base font-bold pb-2">
          In accordance with Chapter 12: Title 10: Official Code of Georgia
          Annotated, "Georgia Electronic Records and Signature Act", clicking
          the "I Agree" button, I hereby affix my signature, signifying my
          consent and fully binding this agreement.
        </p>
        <div class=" text-center flex flex-wrap justify-start gap-5">
          <div class="flex items-center ">
            <input
              id="radio1"
              type="radio"
              name="Address"
              class="hidden"
              checked
            />
            <label for="radio1" class="flex items-center cursor-pointer">
              <span class="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
              I Agree
            </label>
          </div>
          <div class="flex items-center ">
            <input id="radio2" type="radio" name="Address" class="hidden" />
            <label for="radio2" class="flex items-center cursor-pointer">
              <span class="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
              I Disagree
            </label>
          </div>
        </div>
      </div>

      <div className="inputContainer pt-5">
        <p className="text-base font-bold pb-2">
          I have reviewed the information above and determined the following:
        </p>
        <p className="text-base  pb-2">TO BE COMPLETED BY ALPHACORD LLC</p>
        <div class=" text-center flex flex-wrap justify-start gap-5">
          <div class="flex items-center gap-2">
            <input id="radio1" type="checkbox" name="Address" />
            <label for="radio1" class="flex items-center cursor-pointer">
              SUITABLE - this cord blood and/or tissue collection has met Alpha
              Cord LLC SUITABILITY criteria for private storage
            </label>
          </div>
          <div class="flex items-start gap-2">
            <input
              id="radio1"
              type="checkbox"
              name="Address"
              className="pt-5 mt-1"
            />
            <label for="radio1" class="flex text-left cursor-pointer">
              NOT SUITABLE - this cord blood and/or tissue collection did NOT
              meet Alpha Cord LLC SUITABILITY criteria for private storage and
              requires further follow-up prior to release for therapeutic
              purposes. Alpha Cord LLC will be notified of any/all reactive or
              positive test results. Products/files will be labeled and
              identified, as applicable per regulatory standards and
              requirements.
            </label>
          </div>
          <div class="flex items-center gap-2">
            <input id="radio1" type="checkbox" name="Address" />
            <label for="radio1" class="flex items-start cursor-pointer">
              3rd Party Processing Laboratory notified of potential risk
            </label>
          </div>
        </div>
      </div>
      <div className=" col-span-4 grid grid-cols-2 gap-4 pt-5">
        <div className=" ">
          <label htmlFor="first-Name" className="block mb-1 font-bold">
            First Name
          </label>
          <input
            type="text"
            name="first-Name"
            id="first-Name"
            className="border border-gray-300 rounded-md px-3 py-2 w-full"
          />
        </div>

        <div className="">
          <label htmlFor="accepted-date" className="block mb-1 font-bold">
            Reviewed Date
          </label>
          <input
            type="date"
            name="accepted-date"
            id="accepted-date"
            className="border border-gray-300 rounded-md px-3 py-2 w-full"
          />
        </div>
      </div>
      <div className="flex gap-5">
        <button
          type="submit"
          className="hover:bg-gradient-to-r from-custom-primary to-custom-secondary hover:scale-110 transition-all hover:text-white text-base px-8 py-2 rounded-3xl mt-6"
        >
          Previous
        </button>
        <button
          type="Next"
          className=" bg-gradient-to-r from-custom-primary to-custom-secondary hover:scale-110 transition-all text-white text-base px-8 py-2 rounded-3xl mt-6"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default History;
