import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import assets from "../../assets/assets";
import ParentContainer from "../../components/ParentContainer";
import { Link } from "react-router-dom";
import { Waypoint } from "react-waypoint";
import "animate.css";
import Button from "../../components/Button";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Introduction = () => {
  const [isVisible1, setIsVisible1] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);

  const main = useRef();

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const boxes = gsap.utils.toArray(".box");
      boxes.forEach((box) => {
        gsap.fromTo(
          box,
          { x: 1500, scale: 0 },
          {
            x: 0,
            scale: 1,

            scrollTrigger: {
              trigger: box,
              start: "bottom 85%",
              end: "top 50%",
              scrub: 4,
              //  markers: true,
            },
          }
        );
      });

      const circles = gsap.utils.toArray(".circle");
      circles.forEach((circle) => {
        gsap.fromTo(
          circle,
          { x: 500, scale: 0 },
          {
            x: 0,
            scale: 1,
            scrollTrigger: {
              trigger: circle,
              start: "bottom 85%",
              end: "top 50%",
              scrub: 4,
              //  markers: true,
            },
          }
        );
      });

      const images = gsap.utils.toArray(".imgs");
      images.forEach((imgs) => {
        gsap.fromTo(
          imgs,
          { scale: 0.5, opacity: 0, x: -300 },
          {
            scale: 1,
            opacity: 1,
            x: 0,
            scrollTrigger: {
              trigger: imgs,
              start: "bottom 85%",
              end: "top 50%",
              scrub: 4,
              //    markers: true,
            },
          }
        );
      });

      const bgssss = gsap.utils.toArray(".bgs");
      bgssss.forEach((bgs) => {
        gsap.fromTo(
          bgs,
          { background: "linear-gradient(to right, white, white)" },
          {
            background: "linear-gradient(to right, #37424A, black)",
            scrollTrigger: {
              trigger: bgs,
              start: "bottom 50%",
              end: "top 0%",
              scrub: 1,
              // markers: true,
            },
          }
        );
      });
    }, main);

    return () => ctx.revert();
  }, []);

  return (
    <div ref={main}>
      <div className="bgs">
        <ParentContainer
          containerStyle={"flex max-md:flex-wrap"}
          containerHeight={"py-40"}
        >
          <div className="w-1/3 max-md:w-full">
            <img
              src={assets.introduce}
              className={`w-full imgs ${
                isVisible1 ? "animate__animated animate__slideInLeft" : ""
              }  `}
              alt=""
            />
          </div>
          <div
            className={`flex w-2/3 justify-start flex-wrap pl-20 gap-5 py-20 content-center max-md:w-full max-md:pl-0 ${
              isVisible1 ? "animate__animated animate__slideInRight" : ""
            } `}
          >
            <Button
              Linkto={"stem-cells-sceince"}
              titleof={" Science Introducing"}
              CustomStyle={"!justify-start !px-0 box"}
            />
            <h2 className="text-5xl font-bold text-left  w-full  max-md:text-3xl box">
              The Power of{" "}
              <span class="bg-gradient-to-r from-custom-primary to-custom-primary text-transparent bg-clip-text ">
                Newborn Stem Cells
              </span>
            </h2>
            <p className="w-full text-left text-xl opacity-80 max-md:text-base box">
              Preserve your newborn's future by saving stem cells from cord
              blood, cord tissue, and placental tissue—a crucial choice for
              potential family treatments in the future.
            </p>
          </div>
        </ParentContainer>
      </div>
    </div>
  );
};

export default Introduction;
