import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { ThemeProvider } from "@material-tailwind/react";
import { Outlet } from "react-router-dom";

const MainLayout = ({ children }) => {
  return (
    <ThemeProvider>
      <div>
        <Header />
        <main>
          <Outlet />
        </main>
        <Footer />
      </div>
    </ThemeProvider>
  );
};

export default MainLayout;
