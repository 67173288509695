import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import assets from "../../assets/assets";
import ParentContainer from "../../components/ParentContainer";
import { Waypoint } from "react-waypoint";
import "animate.css";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const ThreeImages = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleEnter = () => {
    setIsVisible(true);
  };

  const main = useRef();

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const boxes = gsap.utils.toArray(".box");
      boxes.forEach((box) => {
        gsap.fromTo(
          box,
          { y: 200, opacity: 0 },
          {
            y: 0,
            opacity: 1,
            scrollTrigger: {
              trigger: box,
              start: "bottom 100%",
              end: "top 70%",
              scrub: 4,
              // markers: true,
            },
          }
        );
      });

      const circles = gsap.utils.toArray(".circle2");
      circles.forEach((circle) => {
        gsap.fromTo(
          circle,
          { y: 250, opacity: 0 },
          {
            y: 0,
            opacity: 1,
            scrollTrigger: {
              trigger: circle,
              start: "bottom 100%",
              end: "top 70%",
              scrub: 4,
              //  markers: true,
            },
          }
        );
      });

      const box2s = gsap.utils.toArray(".box2");
      box2s.forEach((boxs2) => {
        gsap.fromTo(
          boxs2,
          { y: 300, opacity: 0 },
          {
            y: 0,
            opacity: 1,
            scrollTrigger: {
              trigger: boxs2,
              start: "bottom 100%",
              end: "top 70%",
              scrub: 4,
              //  markers: true,
            },
          }
        );
      });
    }, main);

    return () => ctx.revert();
  }, []);
  return (
    <ParentContainer containerStyle={"flex"} containerHeight={"pb-20"}>
      <div className="flex gap-5 max-md:flex-wrap max-md:gap-5" ref={main}>
        <div
          className={`w-1/3 flex flex-wrap justify-center gap-5 max-md:w-full box ${
            isVisible ? "animate__animated animate__fadeInUp" : ""
          }`}
        >
          <img src={assets.signup} alt="" />
          <h4 className="w-full leading-10 text-center text-3xl font-bold bg-gradient-to-br from-custom-primary to-custom-secondary text-transparent bg-clip-text">
            Sign Up
          </h4>
          <p className="w-4/5 text-center text-lg">
            Sign up online or dial 800 2796 to receive a convenient collection
            kit promptly and start your journey with us today.
          </p>
        </div>
        <div
          className={`w-1/3 flex flex-wrap justify-center gap-5 max-md:w-full circle2${
            isVisible
              ? "animate__animated animate__fadeInUp animate__delay-1s"
              : ""
          }`}
        >
          <img src={assets.signup} alt="" />
          <h4 className="w-full leading-10 text-center text-3xl font-bold bg-gradient-to-br from-custom-primary to-custom-secondary text-transparent bg-clip-text">
            Sign Up
          </h4>
          <p className="w-4/5 text-center text-lg  ">
            Sign up online or dial 800 2796 to receive a convenient collection
            kit promptly and start your journey with us today.
          </p>
        </div>
        <div
          className={`w-1/3 flex flex-wrap justify-center gap-5 max-md:w-full box2 ${
            isVisible
              ? "animate__animated animate__fadeInUp animate__delay-2s"
              : ""
          }`}
        >
          <img src={assets.signup} alt="" />
          <h4 className="w-full leading-10 text-center text-3xl font-bold bg-gradient-to-br from-custom-primary to-custom-secondary text-transparent bg-clip-text">
            Sign Up
          </h4>
          <p className="w-4/5 text-center text-lg  ">
            Sign up online or dial 800 2796 to receive a convenient collection
            kit promptly and start your journey with us today.
          </p>
        </div>
      </div>
    </ParentContainer>
  );
};

export default ThreeImages;
