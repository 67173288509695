import React, { useState, useEffect } from "react";
import assets from "../assets/assets";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  useLocation,
} from "react-router-dom";
import MobileNav from "./MobileNav";
import ParentContainer from "./ParentContainer";

const Header = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
  const [isDropdownOpen3, setIsDropdownOpen3] = useState(false);
  const [isMobileNav, setIsMobileNav] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const location = useLocation();

  // Function to handle mouse enter event
  const handleMouseEnter = () => {
    clearTimeout(timeoutId);
    setIsDropdownOpen(true);
  };
  const handleMouseEnter2 = () => {
    clearTimeout(timeoutId);
    setIsDropdownOpen2(true);
  };
  const handleMouseEnter3 = () => {
    clearTimeout(timeoutId);
    setIsDropdownOpen3(true);
  };

  // Function to handle mouse leave event
  const handleMouseLeave = () => {
    const id = setTimeout(() => {
      setIsDropdownOpen(false);
    }, 10);
    setTimeoutId(id);
  };
  // Function to handle mouse leave event
  const handleMouseLeave2 = () => {
    const id = setTimeout(() => {
      setIsDropdownOpen2(false);
    }, 10);
    setTimeoutId(id);
  };
  const handleMouseLeave3 = () => {
    const id = setTimeout(() => {
      setIsDropdownOpen3(false);
    }, 10);
    setTimeoutId(id);
  };

  const updateMobileNav = (newState) => {
    setIsMobileNav(newState);
  };
  // Function to clear timeout when unmounting component
  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    // Reset state when the location changes
    setIsMobileNav(false);
  }, [location]);

  return (
    <header className="sticky top-0 bg-white shadow-md z-[99]">
      <ParentContainer
        containerHeight={"py-0"}
        containerStyle={
          "md:flex hidden  py-2  w-full flex-wrap bg-custom-primary text-white"
        }
      >
        <div className="flex w-2/3 gap-10 items-center  ">
          <h3 className="pr-40">
            Want to know more? Download the Free Info Guide
          </h3>
          <h3 className="">
            <a href="tel:009718002796">Get Support: +971 (800) 2796</a>
          </h3>
        </div>
        <div className="flex w-1/3 justify-end content-center">
          <ul className="flex justify-around w-atuo gap-4 px-8 ">
            <li>
              <Link to="/blog">Resources</Link>
            </li>
            <li>
              <Link to="/faqs">FAQS</Link>
            </li>
            <li>
              <Link to="/contact">Contact Us</Link>
            </li>
          </ul>
        </div>
      </ParentContainer>

      <ParentContainer
        containerStyle={"flex w-full justify-end bg-white px-20"}
        containerHeight={"py-0"}
      >
        <div className=" md:w-1/3 w-1/2 ">
          <Link to="/">
            <img src={assets.logo} alt="Company Logo" />
          </Link>
        </div>
        <div className="w-1/3 justify-center content-center items-center md:flex hidden">
          <ul className="flex justify-between content-center gap-10">
            <li>
              <Link to="/about" className="font-semibold">
                About CellSave
              </Link>
            </li>
            <li className="relative flex content-center items-center gap-2 font-semibold	">
              <button
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className="flex items-center gap-2 w-full"
              >
                Discover
                <svg
                  width="10"
                  height="6"
                  viewBox="0 0 10 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.43945 1.24023L4.96945 4.76023L8.49945 1.24023"
                    stroke="#37424A"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {isDropdownOpen && (
                  <div
                    className="absolute top-full -left-1/2  bg-white shadow-lg py-2 w-56  rounded-lg z-10"
                    onMouseLeave={handleMouseLeave}
                  >
                    <Link
                      to="/stem-cells-sceince"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                    >
                      Stem Cells Science
                    </Link>
                    <Link
                      to="/regenerative-medicine"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                    >
                      Regenartive Medicine
                    </Link>
                    <button
                      onMouseEnter={handleMouseEnter3}
                      onMouseLeave={handleMouseLeave3}
                      className=" flex items-center justify-center relative w-full text-gray-800 hover:bg-gray-200"
                    >
                      <Link
                        to="/sources-of-stem-cell"
                        className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                      >
                        Sources of Stem Cell
                      </Link>
                      <svg
                        width="6"
                        height="10"
                        viewBox="0 0 6 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.11719 8.48242L4.72902 5.04644L1.3033 1.42488"
                          stroke="#37424A"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>

                      {isDropdownOpen3 && (
                        <div
                          className="absolute left-full -top-0  bg-white shadow-lg py-2 w-56  rounded-lg z-10"
                          onMouseLeave={handleMouseLeave3}
                        >
                          <Link
                            to="/cord-blood"
                            className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                          >
                            Cord Blood{" "}
                          </Link>
                          <Link
                            to="/cord-tissue"
                            className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                          >
                            Cord Tissue
                          </Link>
                          <Link
                            to="/placenta-tissue"
                            className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                          >
                            Placenta Tissue
                          </Link>
                          <Link
                            to="/cord-vessel"
                            className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                          >
                            Cord Vessel
                          </Link>
                          <Link
                            to="/amnion-tissue"
                            className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                          >
                            Amnion Tissue
                          </Link>
                        </div>
                      )}
                    </button>
                    <Link
                      to="/faqs"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                    >
                      FAQs
                    </Link>
                    <Link
                      to="/blog"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                    >
                      Resources
                    </Link>
                  </div>
                )}
              </button>
            </li>
            <li className="relative flex content-center items-center gap-2">
              <button
                onMouseEnter={handleMouseEnter2}
                onMouseLeave={handleMouseLeave2}
                className="flex items-center gap-2 font-semibold	"
              >
                Services & Pricing
                <svg
                  width="10"
                  height="6"
                  viewBox="0 0 10 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.43945 1.24023L4.96945 4.76023L8.49945 1.24023"
                    stroke="#37424A"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {isDropdownOpen2 && (
                  <div
                    className="absolute top-full bg-white shadow-lg py-2 w-56  rounded-lg z-10"
                    onMouseLeave={handleMouseLeave2}
                  >
                    <Link
                      to="/service-pricing"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                    >
                      Service & Pricing
                    </Link>
                    <Link
                      to="/gift-registry"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                    >
                      Gift Registry
                    </Link>
                  </div>
                )}
              </button>
            </li>
          </ul>
        </div>
        <div className="md:w-1/3 w-1/2 md:px-10 flex items-center justify-end">
          <div className=" w-1/2 flex  items-center gap-5 max-md:hidden  ">
            <a href="#">
              <svg
                width="35"
                height="36"
                viewBox="0 0 35 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.9749 26.9497C22.4838 26.9497 26.9497 22.4838 26.9497 16.9749C26.9497 11.4659 22.4838 7 16.9749 7C11.4659 7 7 11.4659 7 16.9749C7 22.4838 11.4659 26.9497 16.9749 26.9497Z"
                  stroke="url(#paint0_linear_590_3705)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M28.0004 28.0004L25.9004 25.9004"
                  stroke="url(#paint1_linear_590_3705)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_590_3705"
                    x1="5.10822"
                    y1="9.21664"
                    x2="30.4259"
                    y2="11.0041"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#8884D5" />
                    <stop offset="1" stop-color="#CF0072" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_590_3705"
                    x1="25.7013"
                    y1="26.1337"
                    x2="28.3663"
                    y2="26.3219"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#8884D5" />
                    <stop offset="1" stop-color="#CF0072" />
                  </linearGradient>
                </defs>
              </svg>
            </a>
            <Link to="/profile/maternal-history">
              <svg
                width="35"
                height="36"
                viewBox="0 0 35 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.6306 18.8449C17.5548 18.834 17.4573 18.834 17.3706 18.8449C15.4639 18.7799 13.9473 17.2199 13.9473 15.3024C13.9473 13.3415 15.5289 11.749 17.5006 11.749C19.4614 11.749 21.0539 13.3415 21.0539 15.3024C21.0431 17.2199 19.5373 18.7799 17.6306 18.8449Z"
                  stroke="url(#paint0_linear_590_3706)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M24.8026 25.9954C22.8742 27.7613 20.3176 28.8338 17.5009 28.8338C14.6842 28.8338 12.1276 27.7613 10.1992 25.9954C10.3076 24.9771 10.9576 23.9804 12.1167 23.2004C15.0851 21.2288 19.9384 21.2288 22.8851 23.2004C24.0442 23.9804 24.6942 24.9771 24.8026 25.9954Z"
                  stroke="url(#paint1_linear_590_3706)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M17.4993 28.8337C23.4824 28.8337 28.3327 23.9834 28.3327 18.0003C28.3327 12.0172 23.4824 7.16699 17.4993 7.16699C11.5163 7.16699 6.66602 12.0172 6.66602 18.0003C6.66602 23.9834 11.5163 28.8337 17.4993 28.8337Z"
                  stroke="url(#paint2_linear_590_3706)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_590_3706"
                    x1="13.2734"
                    y1="12.5374"
                    x2="22.2921"
                    y2="13.1751"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#8884D5" />
                    <stop offset="1" stop-color="#CF0072" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_590_3706"
                    x1="8.81442"
                    y1="22.5119"
                    x2="27.0562"
                    y2="25.1563"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#8884D5" />
                    <stop offset="1" stop-color="#CF0072" />
                  </linearGradient>
                  <linearGradient
                    id="paint2_linear_590_3706"
                    x1="4.61142"
                    y1="9.5744"
                    x2="32.108"
                    y2="11.5157"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#8884D5" />
                    <stop offset="1" stop-color="#CF0072" />
                  </linearGradient>
                </defs>
              </svg>
            </Link>
          </div>
          <Link
            to="/checkout"
            className="max-md:hidden bg-gradient-to-br from-custom-primary to-custom-secondary text-white px-5 py-2 rounded-3xl font-semibold"
          >
            Order Now
          </Link>
          <div
            onClick={() => setIsMobileNav(true)}
            className="md:hidden cursor-pointer bg-gradient-to-br from-custom-primary to-custom-secondary text-white px-2 py-2 rounded-lg font-semibold"
          >
            <svg
              class="feather feather-menu"
              fill="none"
              height="24"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              viewBox="0 0 24 24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line x1="3" x2="21" y1="12" y2="12" />
              <line x1="3" x2="21" y1="6" y2="6" />
              <line x1="3" x2="21" y1="18" y2="18" />
            </svg>
          </div>
        </div>
      </ParentContainer>

      {isMobileNav ? <MobileNav onMessageUpdate={updateMobileNav} /> : ""}
    </header>
  );
};

export default Header;
