import React from "react";

const AccordionSetm = ({ title, children, isOpen, onClick }) => {
  return (
    <div className=" rounded-lg">
      <button
        onClick={onClick}
        className="w-full flex justify-between items-center p-4 text-left"
      >
        <div className="flex gap-2 w-full items-center">
          {" "}
          <div className=" w-1/12">
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.896484"
                width="20"
                height="20"
                rx="10"
                fill="url(#paint0_linear_590_5594)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_590_5594"
                  x1="-1.00007"
                  y1="2.22222"
                  x2="24.3814"
                  y2="4.01415"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#8884D5" />
                  <stop offset="1" stop-color="#CF0072" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <span className="text-lg font-medium w-10/12">{title}</span>
          <div className="w-1/12">
            {isOpen ? (
              <svg
                width="14"
                height="8"
                viewBox="0 0 14 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 6.8667L6.88333 1.00003L12.7667 6.8667"
                  stroke="#37424A"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            ) : (
              <svg
                width="14"
                height="8"
                viewBox="0 0 14 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L6.88333 6.86667L12.7667 1"
                  stroke="#37424A"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            )}
          </div>
        </div>
      </button>
      {isOpen && <div className="p-4">{children}</div>}
    </div>
  );
};

export default AccordionSetm;
